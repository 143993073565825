import { createStore } from "vuex";
import { Toast } from "tw-elements";

export const data = createStore({
  state: {
    toastOptions: {
      title: "",
      body: "",
      type: "error",
      customClass: "",
    },
    contactModal: {
      id: null,
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      description: "",
      userSelectedDate: "",
      createdDate: "",
      hasRead: false,
    },
  },
  mutations: {
    showToast: function (state, options = null) {
      if (!options) {
        return
      }
      
      if (options?.title) {
        state.toastOptions.title = options.title;
      }

      if (options?.body) {
        state.toastOptions.body = options.body;
      }

      if (options?.type) {
        state.toastOptions.type = options.type;
      }

      if (options?.customClass) {
        state.toastOptions.customClass = options.customClass;
      }

      Toast.getInstance(document.getElementById(options.id)).show();

      setTimeout(() => {
        Toast.getInstance(document.getElementById(options.id)).hide();
      }, 5000);
    },
    addContactInfo: function (state, contact) {
      state.contactModal = contact
    },
  },
});
