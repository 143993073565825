<template>
  <div class="h-auto p-3 pb-10">
    <nav class="flex flex-col" aria-label="Breadcrumb">
      <ol class="inline-flex flex-wrap items-center">
        <li class="inline-flex items-center">
          <router-link
            to="/"
            class="inline-flex items-center sm:text-sm text-xs font-medium text-zinc-700 hover:text-zinc-950 dark:text-zinc-400 dark:hover:text-white"
          >
            <svg
              class="me-2 w-4 h-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
              ></path>
            </svg>
            Home
          </router-link>
        </li>
        <li>
          <div class="flex items-center">
            <svg
              class="w-6 h-6 text-zinc-400 rtl:rotate-180"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <router-link
              to="/categories"
              class="sm:text-sm text-xs font-medium text-zinc-700 hover:text-zinc-950 dark:text-zinc-400 dark:hover:text-white"
              >Categories List</router-link
            >
          </div>
        </li>
        <li v-if="type == 'overalPackageBox'">
          <div class="flex items-center">
            <svg
              class="w-6 h-6 text-zinc-400 rtl:rotate-180"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <router-link
              :to="`/packages/overall?category-id=${category.id}&is-category-exist=true&category-title=${category.title}`"
              class="sm:text-sm text-xs font-medium text-zinc-700 hover:text-zinc-950 dark:text-zinc-400 dark:hover:text-white"
              >Overall Package</router-link
            >
          </div>
        </li>
        <li>
          <div class="flex items-center">
            <svg
              class="w-6 h-6 text-zinc-400 rtl:rotate-180"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <router-link
              :to="
                type == 'category'
                  ? `/packages/packages-price?category-id=${category.id}&is-category-exist=true&category-title=${category.title}`
                  : `/packages/packages-price?type=overalPackageBox&&is-overal-package-box-exist=true&overal-package-box-id=${overalPackageBox.id}&overal-package-box-title=${overalPackageBox.title}&category-id=${category.id}&category-title=${category.title}`
              "
              class="sm:text-sm text-xs font-medium text-zinc-700 hover:text-zinc-950 dark:text-zinc-400 dark:hover:text-white"
              >Packages Price</router-link
            >
          </div>
        </li>
        <li>
          <div class="flex items-center">
            <svg
              class="w-6 h-6 text-zinc-400 rtl:rotate-180"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span
              class="sm:text-sm text-xs font-medium text-zinc-700 dark:text-zinc-400"
              >Edit Package Price</span
            >
          </div>
        </li>
      </ol>

      <div class="mt-5 w-full">
        <h1
          v-if="type == 'category'"
          class="md:text-lg text-base text-zinc-950 font-medium dark:text-zinc-200"
        >
          Edit Package Price | Category: {{ category.title }}
        </h1>
        <h1
          v-else
          class="md:text-lg text-base text-zinc-950 font-medium dark:text-zinc-200"
        >
          Edit Package Price | Overall Package Box: {{ overalPackageBox.title }}
        </h1>
      </div>
    </nav>

    <div
      class="bg-white dark:bg-zinc-900 rounded-md p-5 w-full box-border border dark:border-zinc-700 mt-5"
    >
      <form
        @submit.prevent="updatePackagePrice"
        v-show="!isLoading"
        class="informations flex flex-col"
      >
        <div class="flex flex-col gap-7 mb-5">
          <div class="flex flex-col gap-2">
            <div class="flex flex-col gap-2 mb-2">
              <h3
                class="md:text-lg text-base text-secondary dark:text-white font-medium"
              >
                Header Informations
              </h3>
              <hr
                class="border-none w-full h-[2px] bg-zinc-200 dark:bg-zinc-600"
              />
            </div>

            <Input
              class="w-full required"
              type="text"
              placeholder="Header Title"
              v-model="packagePrice.title"
              :isRequired="true"
            ></Input>

            <Input
              type="textarea"
              textareaRows="3"
              placeholder="Header Description"
              v-model="packagePrice.description"
              :isRequired="true"
              class="required"
            ></Input>

            <div class="flex flex-col gap-2 w-full required">
              <h6 class="font-medium text-sm text-zinc-600 dark:text-zinc-400">
                Header Image
              </h6>
              <FileUploader
                ref="headerImageFileUploader"
                @updatePendingFiles="
                  updatePendingFiles($event, 'headerImageUrl')
                "
              ></FileUploader>
            </div>
          </div>

          <div class="flex flex-col gap-2">
            <div class="flex flex-col gap-2 mb-2">
              <h3
                class="md:text-lg text-base text-secondary dark:text-white font-medium"
              >
                Sub Header Informations
              </h3>
              <hr
                class="border-none w-full h-[2px] bg-zinc-200 dark:bg-zinc-600"
              />
            </div>

            <Input
              class="w-full"
              type="text"
              placeholder="Sub Title"
              v-model="packagePrice.subTitle"
              :isRequired="false"
            ></Input>

            <Input
              type="textarea"
              textareaRows="3"
              placeholder="Sub Description"
              v-model="packagePrice.subDescription"
              :isRequired="false"
            ></Input>
          </div>

          <div class="flex flex-col gap-2">
            <div class="flex flex-col gap-2 mb-2">
              <div class="required">
                <h6
                  class="md:text-lg text-base text-secondary dark:text-white font-medium"
                >
                  3 Main Package Boxes
                </h6>
              </div>
              <hr
                class="border-none w-full h-[2px] bg-zinc-200 dark:bg-zinc-600"
              />
            </div>

            <div class="w-full grid grid-cols-1 gap-5">
              <template
                v-if="
                  packagePrice?.packagePriceBoxes ||
                  packagePrice?.packagePriceBoxes?.length > 0
                "
                v-for="(box, boxIndex) in packagePrice?.packagePriceBoxes"
              >
                <PackageBox mode="editable">
                  <template v-slot:informations>
                    <div
                      class="flex flex-col justify-center items-center sm:gap-6 gap-4"
                    >
                      <div class="absolute top-2 end-2">
                        <button
                          v-if="
                            box.isInitialized &&
                            packagePrice?.packagePriceBoxes.filter(
                              (box) => box.isInitialized == true
                            )
                          "
                          type="button"
                          class="text-red-500 w-6 h-6 inline-flex items-center justify-center p-1 border border-red-500 rounded-full"
                          @click="
                            () =>
                              (deleteInfo = {
                                type: 'packagePriceBox',
                                id: box?.id,
                              })
                          "
                          data-te-target="#deleteModal"
                          data-te-toggle="modal"
                        >
                          <Icon icon="entypo:trash" />
                        </button>
                        <button
                          v-else-if="!box.isInitialized"
                          type="button"
                          class="text-red-500 w-6 h-6 inline-flex items-center justify-center p-1 border border-red-500 rounded-full"
                          @click="
                            removeItemObjectWithId(
                              packagePrice.packagePriceBoxes,
                              box.id
                            )
                          "
                        >
                          <Icon icon="entypo:trash" />
                        </button>
                      </div>

                      <Input
                        :isTitleShow="false"
                        class="max-w-max required"
                        type="text"
                        placeholder="Title"
                        v-model="box.title"
                        :isRequired="true"
                        outer-input-class="!rounded-xl sm:!text-lg !text-base"
                      ></Input>

                      <Input
                        :isTitleShow="false"
                        class="max-w-[200px] required"
                        type="number"
                        placeholder="price"
                        v-model="box.price"
                        :isRequired="true"
                        outer-input-class="!rounded-xl text-xs"
                      ></Input>

                      <div class="flex items-center gap-2">
                        <input
                          :id="`isBestValue${boxIndex}`"
                          v-model="box.isBestValue"
                          class="custom-checkbox"
                          type="checkbox"
                          @change="mostPopularChange($event, box)"
                        />

                        <label
                          class="inline-block text-sm text-black dark:text-white hover:cursor-pointer"
                          :for="`isBestValue${boxIndex}`"
                        >
                          Is Most Popular
                        </label>
                      </div>
                    </div>
                  </template>
                  <template v-slot:more>
                    <InputNewValue
                      class="mb-3 pe-2"
                      placeholder="Add Attribute"
                      v-model="newPackageAttributesValue"
                      @addValueClick="
                        addPackageAttribute(newPackageAttributesValue)
                      "
                    />

                    <div
                      id="packageAttributes"
                      class="package-attributes w-full h-[calc(100%-54px)] pe-2 overflow-y-auto"
                    >
                      <div
                        v-if="
                          !packageAttributesIsLoading &&
                          packageAttributes?.length > 0
                        "
                        v-for="(item, index) in packageAttributes"
                        :key="item.id"
                        class="flex justify-between items-center text-black dark:text-white"
                        :class="
                          index !== packageAttributes.length - 1 ? 'mb-3' : ''
                        "
                      >
                        <div class="flex w-full items-center gap-2">
                          <input
                            v-model="box.rawAttributes"
                            :id="`attributeCheck${item.id}${boxIndex}`"
                            class="custom-checkbox"
                            :value="item.id"
                            type="checkbox"
                            name="attributeCheckbox"
                          />

                          <input
                            type="text"
                            v-model="item.value"
                            :disabled="!item.editableFilterItems"
                            :class="
                              item.editableFilterItems
                                ? 'border border-zinc-300 dark:border-zinc-600 bg-white dark:bg-zinc-800'
                                : 'bg-zinc-100 dark:bg-zinc-900'
                            "
                            class="text-black min-w-[60px] cursor-text rounded-md p-2 py-[10px] w-full text-xs dark:text-white placeholder:text-zinc-400 dark:placeholder:text-zinc-500 outline-none"
                          />
                        </div>

                        <div class="flex relative h-full items-center ms-2">
                          <div
                            class="text-lg max-xs:text-base"
                            :class="
                              !item.editableFilterItems
                                ? 'flex items-center gap-2'
                                : 'hidden'
                            "
                          >
                            <button
                              type="button"
                              class="text-zinc-600 dark:text-zinc-400"
                              @click="
                                () =>
                                  (item.editableFilterItems =
                                    !item.editableFilterItems)
                              "
                            >
                              <Icon icon="emojione-monotone:fountain-pen" />
                            </button>

                            <button
                              type="button"
                              class="text-red-500"
                              @click="
                                () =>
                                  (deleteInfo = {
                                    type: 'packageAttribute',
                                    id: item.id,
                                  })
                              "
                              data-te-target="#deleteModal"
                              data-te-toggle="modal"
                            >
                              <Icon icon="entypo:trash" />
                            </button>
                          </div>

                          <!-- Confirm change buttons -->
                          <div
                            :class="
                              item.editableFilterItems
                                ? 'flex items-center gap-2'
                                : 'hidden'
                            "
                          >
                            <button
                              @click="() => editPackageAttribute(item)"
                              type="button"
                              class="text-zinc-600 dark:text-zinc-400 text-lg"
                            >
                              <Icon icon="material-symbols:check" />
                            </button>
                            <button
                              @click="() => (item.editableFilterItems = false)"
                              class="text-zinc-600 dark:text-zinc-400 text-lg"
                              type="button"
                            >
                              <Icon icon="heroicons:x-mark-20-solid" />
                            </button>
                          </div>
                        </div>
                      </div>

                      <div
                        v-if="
                          !packageAttributesIsLoading &&
                          (!packageAttributes || packageAttributes?.length == 0)
                        "
                        class="w-full h-full flex justify-center items-center"
                      >
                        <span class="dark:text-white text-black"
                          >There is no attributes to display</span
                        >
                      </div>

                      <div
                        class="flex justify-center items-center w-full h-full"
                        v-if="packageAttributesIsLoading"
                      >
                        <LoadingSpinner
                          class="text-primary dark:text-primary"
                        />
                      </div>
                    </div>
                  </template>
                </PackageBox>
              </template>

              <div
                v-if="packagePrice?.packagePriceBoxes?.length + 1 < 4"
                class="w-full h-full p-1 rounded-2xl border-2 border-zinc-300/60 dark:border-zinc-800/60 dark:text-white/60 bg-zinc-200/60 dark:bg-zinc-950/60"
              >
                <div
                  class="w-full h-full relative bg-white/60 dark:bg-zinc-800/60 px-4 py-16 rounded-xl flex justify-center items-center"
                >
                  <button
                    @click="addNewPackageBox"
                    type="button"
                    class="text-white h-fit text-4xl bg-primary rounded-md hover:bg-primary/80 transition-all duration-300 focus:outline-none py-2 px-4 w-fit disabled:opacity-60 disabled:pointer-events-none"
                  >
                    <Icon icon="mdi:package-variant-closed-add" />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-col gap-2">
            <div class="flex flex-col gap-2 mb-2">
              <h6
                for="moreServicesCheck"
                class="md:text-lg text-base text-secondary dark:text-white font-medium"
              >
                More Services
              </h6>

              <hr
                class="border-none w-full h-[2px] bg-zinc-200 dark:bg-zinc-600"
              />
            </div>

            <div
              class="flex flex-col gap-2 w-full"
              :class="packagePrice.moreServices?.length > 0 ? 'required' : ''"
            >
              <h6 class="font-medium text-sm text-zinc-600 dark:text-zinc-400">
                Services Header Image
              </h6>
              <FileUploader
                ref="serviceHeaderImageFileUploader"
                @updatePendingFiles="
                  updatePendingFiles($event, 'moreServiceHeaderImage')
                "
              ></FileUploader>
            </div>

            <Input
              class="w-full"
              :class="packagePrice.moreServices?.length > 0 ? 'required' : ''"
              type="text"
              placeholder="Services Header Title"
              v-model="moreServicesInfo.title"
              :isRequired="packagePrice.moreServices?.length > 0"
            ></Input>

            <Input
              type="textarea"
              textareaRows="3"
              placeholder="Services Header Description"
              v-model="moreServicesInfo.description"
              :isRequired="packagePrice.moreServices?.length > 0"
              :class="packagePrice.moreServices?.length > 0 ? 'required' : ''"
            ></Input>

            <Input
              class="w-full"
              :class="packagePrice.moreServices?.length > 0 ? 'required' : ''"
              type="text"
              placeholder="Services Sub Title"
              v-model="moreServicesInfo.subTitle"
              :isRequired="packagePrice.moreServices?.length > 0"
            ></Input>

            <div class="w-full grid grid-cols-2 gap-5 mt-3">
              <div
                class="col-span-2 w-full h-[250px] p-2 rounded-xl border-2 border-zinc-300 dark:border-zinc-800 bg-zinc-200 dark:bg-zinc-950"
              >
                <InputNewValue
                  placeholder="Add Servie Category"
                  v-model="newMoreServiceCategory"
                  @addValueClick="
                    addMoreServiceCategory(newMoreServiceCategory)
                  "
                />
                <div
                  class="w-full h-[calc(100%-48px)] mt-2 pe-2 overflow-y-auto"
                >
                  <div
                    v-if="
                      !moreServiceCategoriesIsLoading &&
                      moreServiceCategories?.length > 0
                    "
                    v-for="(item, index) in moreServiceCategories"
                    :key="item.id"
                    class="flex justify-between items-center text-black dark:text-white"
                    :class="
                      index !== moreServiceCategories?.length - 1 ? 'mb-3' : ''
                    "
                  >
                    <div class="flex w-full items-center gap-2">
                      <input
                        type="text"
                        v-model="item.title"
                        :disabled="!item.editableFilterItems"
                        :class="
                          item.editableFilterItems
                            ? 'border border-zinc-300 dark:border-zinc-600 bg-white dark:bg-zinc-800'
                            : 'bg-zinc-100 dark:bg-zinc-900'
                        "
                        class="text-black min-w-[60px] cursor-text rounded-md p-2 py-[10px] w-full text-xs dark:text-white placeholder:text-zinc-400 dark:placeholder:text-zinc-500 outline-none"
                      />
                    </div>

                    <div class="flex relative h-full items-center ms-2">
                      <div
                        class="text-lg max-xs:text-base"
                        :class="
                          !item.editableFilterItems
                            ? 'flex items-center gap-2'
                            : 'hidden'
                        "
                      >
                        <button
                          type="button"
                          class="text-zinc-600 dark:text-zinc-400"
                          @click="
                            () =>
                              (item.editableFilterItems =
                                !item.editableFilterItems)
                          "
                        >
                          <Icon icon="emojione-monotone:fountain-pen" />
                        </button>

                        <button
                          type="button"
                          class="text-red-500"
                          @click="
                            () =>
                              (deleteInfo = {
                                type: 'serviceCategory',
                                id: item.id,
                              })
                          "
                          data-te-target="#deleteModal"
                          data-te-toggle="modal"
                        >
                          <Icon icon="entypo:trash" />
                        </button>
                      </div>

                      <!-- Confirm change buttons -->
                      <div
                        :class="
                          item.editableFilterItems
                            ? 'flex items-center gap-2'
                            : 'hidden'
                        "
                      >
                        <button
                          @click="() => editServiceCategory(item)"
                          type="button"
                          class="text-zinc-600 dark:text-zinc-400 text-lg"
                        >
                          <Icon icon="material-symbols:check" />
                        </button>
                        <button
                          @click="() => (item.editableFilterItems = false)"
                          class="text-zinc-600 dark:text-zinc-400 text-lg"
                          type="button"
                        >
                          <Icon icon="heroicons:x-mark-20-solid" />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="
                      !moreServiceCategoriesIsLoading &&
                      (!moreServiceCategories ||
                        moreServiceCategories?.length == 0)
                    "
                    class="w-full h-full flex justify-center items-center"
                  >
                    <span class="dark:text-white text-black"
                      >There is no service category to display</span
                    >
                  </div>

                  <div
                    class="flex justify-center items-center w-full h-full"
                    v-if="moreServiceCategoriesIsLoading"
                  >
                    <LoadingSpinner class="text-primary dark:text-primary" />
                  </div>
                </div>
              </div>

              <div
                v-if="
                  packagePrice?.moreServices ||
                  packagePrice?.moreServices?.length > 0
                "
                v-for="(service, serviceIndex) in packagePrice.moreServices"
                :key="serviceIndex"
                class="max-[1110px]:col-span-2 max-lg:col-span-1 max-md:col-span-2 w-full sm:h-[350px] relative p-1 border-2 border-zinc-300 dark:border-zinc-800 text-black dark:text-white rounded-xl bg-zinc-200 dark:bg-zinc-950 font-[inter]"
              >
                <div
                  class="relative w-full gap-8 max-sm:gap-6 bg-white dark:bg-zinc-800 p-3 rounded-lg flex items-center justify-center"
                >
                  <div class="absolute top-2 end-2">
                    <button
                      v-if="
                        service.isInitialized &&
                        packagePrice?.moreServices.filter(
                          (service) => service.isInitialized == true
                        )
                      "
                      type="button"
                      class="text-red-500 w-6 h-6 inline-flex items-center justify-center p-1 border border-red-500 rounded-full"
                      @click="
                        () =>
                          (deleteInfo = {
                            type: 'packagePriceService',
                            id: service?.id,
                          })
                      "
                      data-te-target="#deleteModal"
                      data-te-toggle="modal"
                    >
                      <Icon icon="entypo:trash" />
                    </button>
                    <button
                      v-else-if="!service.isInitialized"
                      type="button"
                      class="text-red-500 w-6 h-6 inline-flex items-center justify-center p-1 border border-red-500 rounded-full"
                      @click="
                        removeItemObjectWithId(
                          packagePrice.moreServices,
                          service.id
                        )
                      "
                    >
                      <Icon icon="entypo:trash" />
                    </button>
                  </div>

                  <Combobox
                    v-if="!moreServiceCategoriesIsLoading"
                    :items="
                      moreServiceCategories?.map((current) => {
                        return { value: current.id, title: current.title };
                      })
                    "
                    :category="service.category"
                    selectedTitle="Category"
                    v-model="service.categoryId"
                  ></Combobox>

                  <div
                    v-if="moreServiceCategoriesIsLoading"
                    class="flex justify-center items-center w-full h-full"
                  >
                    <LoadingSpinner class="text-primary dark:text-primary" />
                  </div>
                </div>

                <div class="px-1">
                  <fieldset>
                    <div
                      class="mt-2 flex max-xs:flex-col xs:-space-x-px max-xs:-space-y-px rounded-lg bg-white dark:bg-zinc-900 shadow-sm"
                    >
                      <input
                        type="text"
                        class="grow relative block w-full rounded-none xs:rounded-l-lg max-xs:rounded-t-lg border-0 bg-transparent py-2 px-2.5 ring-1 ring-inset outline-none text-black dark:text-white ring-zinc-300 placeholder:text-zinc-400 dark:placeholder:text-zinc-500 dark:ring-zinc-700 text-sm"
                        placeholder="New Service Name"
                        v-model="service.newServiceInfo.title"
                      />

                      <div class="flex -space-x-px">
                        <div class="xs:w-[120px] w-full relative">
                          <div
                            class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2"
                          >
                            <span class="text-zinc-500 text-sm">$</span>
                          </div>
                          <input
                            type="number"
                            class="py-2 pl-6 pr-2.5 relative block w-full border-0 max-xs:rounded-bl-lg bg-transparent ring-1 ring-inset outline-none text-black dark:text-white ring-zinc-300 placeholder:text-zinc-400 dark:placeholder:text-zinc-500 dark:ring-zinc-700 text-sm"
                            placeholder="Price"
                            aria-describedby="price-currency"
                            v-model="service.newServiceInfo.price"
                          />
                        </div>

                        <button
                          type="button"
                          @click="addNewServiceInfo(service.id)"
                          class="relative inline-flex items-center xs:rounded-r-lg rounded-br-lg px-2.5 py-2 font-semibold text-zinc-900 dark:text-white ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 bg-zinc-100 dark:bg-zinc-800 text-lg"
                        >
                          <Icon icon="ic:baseline-plus" />
                        </button>
                      </div>
                    </div>
                  </fieldset>

                  <hr class="block my-3 border-zinc-400 dark:border-zinc-700" />

                  <div
                    class="w-full sm:h-[170px] h-[120px] pe-1 overflow-y-auto space-y-1"
                  >
                    <fieldset
                      v-if="
                        service.informations ||
                        service?.informations?.length > 0
                      "
                      v-for="(
                        serviceItem, serviceItemIndex
                      ) in service.informations"
                      :key="`serviceInfo${serviceItemIndex}`"
                    >
                      <div
                        class="flex -space-x-px rounded-lg bg-white dark:bg-zinc-900 shadow-sm"
                      >
                        <input
                          type="text"
                          class="grow relative block w-full rounded-none rounded-l-lg border-0 bg-transparent py-2 px-2.5 ring-1 ring-inset outline-none text-black dark:text-white ring-zinc-300 placeholder:text-zinc-400 dark:placeholder:text-zinc-500 dark:ring-zinc-700 text-sm"
                          placeholder="Service Name"
                          v-model="serviceItem.title"
                        />

                        <div class="w-[170px] relative">
                          <div
                            class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2"
                          >
                            <span class="text-zinc-500 text-sm">$</span>
                          </div>
                          <input
                            type="number"
                            class="py-2 pl-6 pr-2.5 relative block w-full border-0 bg-transparent ring-1 ring-inset outline-none text-black dark:text-white ring-zinc-300 placeholder:text-zinc-400 dark:placeholder:text-zinc-500 dark:ring-zinc-700 text-sm"
                            placeholder="Price"
                            aria-describedby="price-currency"
                            v-model="serviceItem.price"
                          />
                        </div>

                        <button
                          type="button"
                          class="relative inline-flex items-center rounded-r-lg px-2.5 py-2 font-semibold text-red-500 ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 bg-zinc-100 dark:bg-zinc-800 text-lg"
                          @click="deleteMoreServiceItem(service, serviceItem)"
                        >
                          <Icon icon="entypo:trash" />
                        </button>
                      </div>
                    </fieldset>

                    <div
                      v-if="
                        !service.informations ||
                        service.informations?.length === 0
                      "
                      class="w-full h-full flex justify-center items-center"
                    >
                      <span class="dark:text-white text-black text-sm"
                        >Add New Service...</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="max-[1110px]:col-span-2 max-lg:col-span-1 max-md:col-span-2 w-full h-full p-1 rounded-2xl border-2 border-zinc-300/60 dark:border-zinc-800/60 dark:text-white/60 bg-zinc-200/60 dark:bg-zinc-950/60"
                :class="
                  !packagePrice.moreServices ||
                  packagePrice.moreServices?.length === 0
                    ? 'col-span-2'
                    : ''
                "
              >
                <div
                  class="w-full h-full relative bg-white/60 dark:bg-zinc-800/60 px-4 py-16 rounded-xl flex justify-center items-center"
                >
                  <button
                    @click="addNewMoreService"
                    type="button"
                    class="text-white h-fit text-4xl bg-primary rounded-md hover:bg-primary/80 transition-all duration-300 focus:outline-none py-2 px-4 w-fit disabled:opacity-60 disabled:pointer-events-none"
                  >
                    <Icon icon="ic:baseline-plus" />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <template v-if="type === 'overalPackageBox'">
            <div class="flex flex-col gap-2">
              <div class="flex flex-col gap-2 mb-2">
                <h6
                  class="md:text-lg text-base text-secondary dark:text-white font-medium"
                >
                  Gallery Section
                </h6>

                <hr
                  class="border-none w-full h-[2px] bg-zinc-200 dark:bg-zinc-600"
                />
              </div>

              <div class="flex flex-col gap-2 w-full">
                <h6
                  class="font-medium text-sm text-zinc-600 dark:text-zinc-400"
                >
                  Gallery Files
                </h6>
                <FileUploader
                  :allow-multiple="true"
                  ref="galleryFilesUploader"
                  acceptedFileTypes="image/jpeg, image/png, image/webp, video/mp4, video/webm"
                  @updatePendingFiles="
                    updatePendingFiles($event, 'galleryFiles')
                  "
                ></FileUploader>
                <span class="text-xs text-black dark:text-white"
                  >* Notice: You can select multiple pictures for the gallery
                  section.</span
                >
                <span class="text-xs text-black dark:text-white"
                  >* Notice: You can reorder pictures. The pictures are
                  displayed on the site in the order they are placed.</span
                >
              </div>
            </div>

            <div class="flex flex-col gap-2">
              <div class="flex flex-col gap-2 mb-2">
                <h6
                  class="md:text-lg text-base text-secondary dark:text-white font-medium"
                >
                  Reels Section
                </h6>

                <hr
                  class="border-none w-full h-[2px] bg-zinc-200 dark:bg-zinc-600"
                />
              </div>

              <div class="w-full grid md:grid-cols-2 gap-5">
                <ReelsBox
                  v-if="packagePrice.reels || packagePrice.reels?.length > 0"
                  v-for="(reels, reelsIndex) in packagePrice.reels"
                  :key="reelsIndex"
                >
                  <template v-slot:file>
                    <div class="absolute top-2 end-2">
                      <button
                        type="button"
                        class="text-red-500 w-6 h-6 inline-flex items-center justify-center p-1 border border-red-500 rounded-full"
                        @click="
                          removeItemObjectWithId(packagePrice.reels, reels.id)
                        "
                      >
                        <Icon icon="entypo:trash" />
                      </button>
                    </div>
                    <div>
                      <h6
                        class="font-medium mb-2 text-sm text-zinc-600 dark:text-zinc-400"
                      >
                        Video File
                      </h6>
                      <FileUploader
                        :ref="`reelsVideoFilesUploader${reels.id}`"
                        acceptedFileTypes="video/mp4, video/webm"
                        @updatePendingFiles="
                          updatePendingFiles($event, 'reelsVideo', reels.id)
                        "
                      ></FileUploader>
                    </div>
                    <div>
                      <h6
                        class="font-medium mb-2 text-sm text-zinc-600 dark:text-zinc-400"
                      >
                        Poster Image
                      </h6>
                      <FileUploader
                        :ref="`reelsPosterFilesUploader${reels.id}`"
                        @updatePendingFiles="
                          updatePendingFiles($event, 'posterImage', reels.id)
                        "
                      ></FileUploader>
                    </div>
                  </template>
                  <template v-slot:more>
                    <Input
                      :isTitleShow="false"
                      type="textarea"
                      textareaRows="4"
                      placeholder="Description"
                      v-model="reels.description"
                      :isRequired="true"
                      class="required"
                      input-class="dark:!bg-zinc-900"
                      outer-input-class="!rounded-xl sm:!text-sm !text-xs dark:!border-zinc-800"
                    ></Input>
                  </template>
                </ReelsBox>

                <div
                  class="w-full h-full p-1 rounded-2xl border-2 border-zinc-300/60 dark:border-zinc-800/60 dark:text-white/60 bg-zinc-200/60 dark:bg-zinc-950/60"
                  :class="
                    !packagePrice.reels || packagePrice.reels?.length === 0
                      ? 'col-span-2'
                      : ''
                  "
                >
                  <div
                    class="w-full h-full relative bg-white/60 dark:bg-zinc-800/60 px-4 py-16 rounded-xl flex justify-center items-center"
                  >
                    <button
                      @click="addNewReelsBox"
                      type="button"
                      class="text-white h-fit text-4xl bg-primary rounded-md hover:bg-primary/80 transition-all duration-300 focus:outline-none py-2 px-4 w-fit disabled:opacity-60 disabled:pointer-events-none"
                    >
                      <Icon icon="ic:baseline-plus" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>

        <hr class="w-full border dark:border-zinc-700" />
        <div class="wrapper-button sm:text-base text-sm flex justify-end mt-5">
          <button
            v-if="!isAddingInformations"
            :disabled="
              packagePrice?.title?.length == 0 ||
              packagePrice?.description?.length == 0
            "
            type="submit"
            class="text-white bg-primary rounded-md hover:bg-primary/80 transition-all duration-300 focus:outline-none py-2 px-6 w-fit disabled:opacity-60 disabled:pointer-events-none"
          >
            Save Information
          </button>

          <button
            v-else
            disabled
            type="button"
            class="text-white bg-primary rounded-md opacity-60 cursor-default transition-all duration-300 focus:outline-none py-2 px-6 w-fit"
          >
            <svg
              role="status"
              class="inline w-4 h-4 me-1 text-white animate-spin"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="#E5E7EB"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentColor"
              />
            </svg>
            Uploading...
          </button>
        </div>
      </form>

      <div
        class="flex justify-center items-center w-full h-full py-10"
        v-if="isLoading"
      >
        <LoadingSpinner class="text-primary dark:text-primary" />
      </div>
    </div>

    <DeleteModal
      mode="sample"
      :info="deleteInfo"
      @deleteSubmit="deleteSubmit"
    />
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";
import axios from "axios";
import Input from "@/components/Input.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import FilterBox from "@/components/FilterBox.vue";
import FileUploader from "@/components/FileUploader.vue";
import DeleteModal from "@/components/DeleteModal.vue";

import settings from "@/settings/appSettings";

import PackageBox from "@/components/PackagePrice/PackageBox";
import ReelsBox from "@/components/ReelsBox.vue";

import InputNewValue from "@/components/PackagePrice/InputNewValue.vue";

import { getPackageAttributes } from "@/components/PackagePrice/modules/attributes";

import { getMoreServiceCategories } from "@/components/PackagePrice/modules/serviceCategories";

import Combobox from "@/components/Combobox.vue";
import { getPackagePrice } from "@/components/PackagePrice/modules/packagePrice";

import { updateImage, updateImages } from "@/modules/updateImage";

export default {
  components: {
    Icon,
    FileUploader,
    Input,
    PackageBox,
    InputNewValue,
    LoadingSpinner,
    FilterBox,
    DeleteModal,
    Combobox,
    ReelsBox,
  },

  data() {
    return {
      packagePrice: {},
      category: {
        id: null,
        title: "",
      },
      overalPackageBox: {
        id: null,
        title: "",
      },
      type: "",
      isAddingInformations: false,
      isAddingCurrentPackageBox: false,
      deleteInfo: null,

      packageAttributes: [],
      packageAttributesIsLoading: true,
      newPackageAttributesValue: "",

      moreServicesInfo: {},
      moreServiceCategories: [],
      moreServiceCategoriesIsLoading: true,
      newMoreServiceCategory: "",

      isLoading: true,
    };
  },

  async created() {
    this.type = this.$route.query["type"] ?? "category";

    // When the package price is called by category
    let categoryId = this.$route.query["category-id"];
    let categoryTitle = this.$route.query["category-title"];

    this.category.title = categoryTitle;
    this.category.id = categoryId;

    // When the package price is called by overal package box
    let overalPackageBoxId = this.$route.query["overal-package-box-id"];
    let overalPackageBoxTitle = this.$route.query["overal-package-box-title"];

    this.overalPackageBox.title = overalPackageBoxTitle;
    this.overalPackageBox.id = overalPackageBoxId;

    let { isLoading, packagePrice } = await getPackagePrice(
      this.type == "category" ? categoryId : overalPackageBoxId,
      this.type
    );

    packagePrice?.moreServices?.forEach((service) => {
      service.isServiceInitialized = true;
    });

    if (!packagePrice) {
      this.$router.push("/404");
      return;
    }

    this.transformMoreServicesData(packagePrice);

    packagePrice.packagePriceBoxes.forEach((box) => {
      box.isInitialized = true;
    });

    packagePrice.moreServices.forEach((service) => {
      service.isInitialized = true;
    });

    this.packagePrice = packagePrice;

    this.packagePrice.packagePriceBoxesInitial = JSON.parse(
      JSON.stringify(this.packagePrice.packagePriceBoxes)
    );
    this.packagePrice.moreServicesInitial = JSON.parse(
      JSON.stringify(this.packagePrice.moreServices)
    );
    this.packagePrice.reelsInitial = JSON.parse(
      JSON.stringify(this.packagePrice.reels)
    );

    if (this.packagePrice) await this.getMoreServicesInfo(this.packagePrice.id);

    if (
      this.moreServicesInfo?.title?.length > 0 &&
      this.moreServicesInfo?.subTitle?.length > 0 &&
      this.moreServicesInfo?.description?.length > 0 &&
      this.moreServicesInfo?.headerImageUrl
    )
      this.moreServicesInfo.isInitialized = true;

    // Add image to header image file uploader
    if (this.packagePrice.headerImageUrl)
      this.$refs.headerImageFileUploader.refPound.addFile(
        `${this.packagePrice.headerImageUrl}?_`
      );

    // Add files to gallery file uploader
    if (this.packagePrice.galleryFiles) {
      this.packagePrice.galleryFiles.reverse();

      this.packagePrice.galleryFiles.forEach((file) => {
        this.$refs.galleryFilesUploader.refPound.addFile(`${file}?_`);
      });
    }

    // Add files to reels file uploader
    if (this.packagePrice.reels?.length > 0) {
      this.packagePrice.reels.forEach((reels) => {
        // Because dynamic refs return proxy(Array)
        this.$refs[`reelsVideoFilesUploader${reels.id}`][0].refPound.addFile(
          `${reels.videoUrl}?_`
        );
        this.$refs[`reelsPosterFilesUploader${reels.id}`][0].refPound.addFile(
          `${reels.posterUrl}?_`
        );
      });
    }

    let { isLoadingAttribute, attributes } = await getPackageAttributes();
    this.packageAttributes = attributes ?? [];

    let { isLoadingServiceCategories, serviceCategories } =
      await getMoreServiceCategories();
    this.moreServiceCategories = serviceCategories ?? [];

    this.packageAttributesIsLoading = isLoadingAttribute;
    this.moreServiceCategoriesIsLoading = isLoadingServiceCategories;

    this.isLoading = false;
  },

  methods: {
    updatePendingFiles: function (files, imageType, id, sliderIndex = 0) {
      if (files) {
        if (imageType == "headerImageUrl") {
          this.packagePrice.headerImageUrl = files[0]?.file;
        }
        if (imageType == "moreServiceHeaderImage") {
          this.moreServicesInfo.headerImageUrl = files[0]?.file;
        }
        if (imageType == "galleryFiles") {
          this.packagePrice.galleryFiles = [];

          for (let index = 0; index < files.length; index++) {
            const element = files[index]?.file;

            if (element.constructor.name === "File" || "Blob") {
              this.packagePrice.galleryFiles[index] = element;
            }
          }
        }
        if (imageType == "reelsVideo") {
          this.packagePrice.reels.find(
            (current) => current.id === id
          ).videoUrl = files[0]?.file;
        }
        if (imageType == "posterImage") {
          this.packagePrice.reels.find(
            (current) => current.id === id
          ).posterUrl = files[0]?.file;
        }
      }
    },

    transformMoreServicesData(packagePrice) {
      const groupedServices = packagePrice.moreServices.reduce(
        (acc, service) => {
          const categoryId = service.categoryId;
          if (!acc[categoryId]) {
            acc[categoryId] = {
              id: null,
              categoryId: categoryId,
              category: service.category,
              informations: [],
              deletedInformationItems: [],
              newServiceInfo: {
                title: "",
                price: null,
              },
            };
          }
          acc[categoryId].informations.push({
            id: service.id,
            title: service.title,
            price: service.price,
            isServiceInitialized: service.isServiceInitialized,
          });
          return acc;
        },
        {}
      );
      packagePrice.moreServices = Object.values(groupedServices);
      packagePrice.moreServices.forEach(
        (current, index) => (current.id = index + 1)
      );
    },

    deleteSubmit(e) {
      if (e.type == "packagePriceBox" || e.type == "packagePriceService") {
        e.type == "packagePriceBox"
          ? this.deleteCurrentPackageBox(
              e.id,
              this.packagePrice.packagePriceBoxes
            )
          : this.deleteCurrentMoreService(e.id, this.packagePrice.moreServices);
      }
      if (e.type == "packageAttribute") {
        this.deletePackageAttribute(e.id);
      }
      if (e.type == "serviceCategory") {
        this.deleteServiceCategory(e.id);
      }
    },

    async updatePackagePrice() {
      let packagePriceInformations = {
        title: this.packagePrice.title,
        description: this.packagePrice.description,
        categoryId: this.packagePrice.categoryId,
        overalPackageBoxId: this.packagePrice.overalPackageBoxId,
        subTitle: this.packagePrice.subTitle,
        subDescription: this.packagePrice.subDescription,
      };

      let packagePriceMoreServices = this.packagePrice.moreServices;
      let moreServiceInformation = this.moreServicesInfo;

      let packageId = this.packagePrice.id;

      let packagePriceBoxes = this.packagePrice.packagePriceBoxes;

      if (
        packagePriceInformations.overalPackageBoxId &&
        this.type === "overalPackageBox"
      ) {
        for (const file of this.packagePrice.reels.map(
          (current) => current.videoUrl
        )) {
          if (!file) {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Error!",
              body: "One of the desired reels boxes does not have a video file!",
            });
            return;
          }
        }
      }

      for (const box of packagePriceBoxes) {
        if (box.rawAttributes?.length === 0) {
          this.$store.commit("showToast", {
            id: "myToast",
            type: "error",
            title: "Error!",
            body: "One of the package boxes has no attribute!",
          });

          return;
        }
      }

      if (packagePriceMoreServices?.length > 0) {
        for (const service of packagePriceMoreServices) {
          if (service?.informations?.length === 0) {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Error!",
              body: "More service box should have at least one service package with the desired category!",
            });

            return;
          }

          if (
            service?.newServiceInfo?.title?.length > 0 ||
            service?.newServiceInfo?.price?.length > 0
          ) {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "warning",
              title: "Warning!",
              body: "First, add the filled service information to the more service box!",
            });

            return;
          }
        }

        if (!moreServiceInformation.headerImageUrl) {
          this.$store.commit("showToast", {
            id: "myToast",
            type: "error",
            title: "Service Header Image Is Required!",
            body: "You must choice any image for services header image!",
          });
          return;
        }
      }

      if (!this.packagePrice.headerImageUrl) {
        this.$store.commit("showToast", {
          id: "myToast",
          type: "error",
          title: "Header Image Is Required!",
          body: "You must choice any image for header image!",
        });
        return;
      }

      this.isAddingInformations = true;

      try {
        await this.updatePackagePriceInformations(
          `PackagePrices/${packageId}`,
          packagePriceInformations
        );

        await updateImage(
          this.packagePrice.headerImageUrl,
          "HeaderImage",
          `PackagePrices/${packageId}/HeaderImage`
        );

        if (packagePriceBoxes?.length > 0) {
          await this.addPackagePriceBox(
            "PackagePrices/box",
            packagePriceBoxes,
            packageId
          );

          await this.updatePackagePriceBox(
            "PackagePrices/box",
            packagePriceBoxes,
            packageId
          );
        }

        if (packagePriceMoreServices?.length > 0) {
          await this.addMoreService(
            "PackagePrices/box",
            packagePriceMoreServices,
            packageId
          );

          await this.updateMoreService(
            "PackagePrices/box",
            packagePriceMoreServices,
            packageId
          );

          if (this.moreServicesInfo.isInitialized) {
            await this.updateMoreServiceInformation(
              `PackagePrices/moreServiceInfo/${packageId}`,
              moreServiceInformation,
              packageId
            );

            await updateImage(
              moreServiceInformation.headerImageUrl,
              "HeaderImage",
              `PackagePrices/${packageId}/moreServiceInfo/headerImage`
            );
          } else {
            await this.addMoreServiceInformation(
              "PackagePrices/moreServiceInfo",
              moreServiceInformation,
              packageId
            );
          }
        }

        if (
          packagePriceInformations.overalPackageBoxId &&
          this.type === "overalPackageBox"
        ) {
          await updateImages(
            this.packagePrice.galleryFiles,
            "GalleryFiles",
            `PackagePrices/${packageId}/GalleryFiles`
          );

          await this.updateReels(
            `PackagePrices/${packageId}/Reels`,
            this.packagePrice.reels
          );
        }

        this.$store.commit("showToast", {
          id: "myToast",
          type: "success",
          title: "Success!",
          body: "Your desired package has been successfully edited!",
        });

        this.isAddingInformations = false;

        this.$router.go();
      } catch (error) {
        if (error.response?.data?.isSuccess == false) {
          this.$store.commit("showToast", {
            id: "myToast",
            type: "error",
            title: "Error!",
            body: error.response?.data?.messages[0],
          });
        } else {
          this.$store.commit("showToast", {
            id: "myToast",
            type: "error",
            title: "Error!",
            body: "Unfortunately, an unknown error has occurred! Please contact the website support!",
          });
        }

        this.isAddingInformations = false;
        console.log(error);
        console.log(error.response);
      }
    },

    async updatePackagePriceInformations(url, informationsViewModel) {
      let bodyFormData = new FormData();

      if (informationsViewModel.categoryId)
        bodyFormData.append("CategoryId", informationsViewModel.categoryId);

      if (informationsViewModel.overalPackageBoxId) {
        bodyFormData.append(
          "OveralPackageBoxId",
          informationsViewModel.overalPackageBoxId
        );
      }

      bodyFormData.append("Title", informationsViewModel.title);

      bodyFormData.append("Description", informationsViewModel.description);

      if (informationsViewModel.subTitle)
        bodyFormData.append("SubTitle", informationsViewModel.subTitle);

      if (informationsViewModel.subDescription)
        bodyFormData.append(
          "SubDescription",
          informationsViewModel.subDescription
        );

      let options = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        isNeedJwtAuth: true,
      };

      await axios.put(url, bodyFormData, options);
    },

    async addPackagePriceBox(url, boxesInformation, packageId) {
      const newBoxes = boxesInformation.filter(
        (current) => !current.isInitialized
      );

      if (!newBoxes || newBoxes.length === 0) return;

      for (const box of newBoxes) {
        let bodyFormData = new FormData();

        bodyFormData.append("Title", box.title);
        bodyFormData.append("PackagePriceId", packageId);
        bodyFormData.append("Type", box.type);
        bodyFormData.append("Price", box.price);

        // rawAttributes created in get package price module
        box.rawAttributes?.forEach((attribute) => {
          bodyFormData.append("Attributes", attribute);
        });

        bodyFormData.append("IsBestValue", box.isBestValue);

        bodyFormData.append("IsMoreService", false);

        let options = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          isNeedJwtAuth: true,
        };

        await axios.post(url, bodyFormData, options);
      }
    },

    async updatePackagePriceBox(url, boxesInformation, packageId) {
      const currentBoxes = boxesInformation.filter(
        (current) => current.isInitialized
      );
      const changedBoxes = this.findChangedItems(
        this.packagePrice.packagePriceBoxesInitial,
        currentBoxes
      );
      if (!changedBoxes || changedBoxes.length === 0) return;

      for (const box of changedBoxes) {
        let packagePriceBox = {
          type: "-",
          ordering: null,
          categoryId: null,
          isBestValue: box.isBestValue,
          description: null,
          isMoreService: false,
          // rawAttributes created in get package price module
          attributes: box.rawAttributes,
          price: box.price,
          title: box.title,
          packagePriceId: packageId,
        };

        await axios.put(`${url}/${box.id}`, packagePriceBox, {
          isNeedJwtAuth: true,
        });
      }
    },

    async addMoreServiceInformation(url, moreServicesInfoViewModel, packageId) {
      let bodyFormData = new FormData();

      bodyFormData.append("Title", moreServicesInfoViewModel.title);

      bodyFormData.append("Description", moreServicesInfoViewModel.description);

      bodyFormData.append(
        "HeaderImage",
        moreServicesInfoViewModel.headerImageUrl
      );

      bodyFormData.append("PackagePriceId", packageId);

      if (moreServicesInfoViewModel.subTitle)
        bodyFormData.append("SubTitle", moreServicesInfoViewModel.subTitle);

      bodyFormData.append("OptimizeImage.Quality", 85);
      bodyFormData.append("OptimizeImage.ImageExtensions", "webp");

      let options = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        isNeedJwtAuth: true,
      };

      await axios.post(url, bodyFormData, options);
    },

    async updateMoreServiceInformation(url, moreServicesInfoViewModel) {
      let moreServiceInformation = {
        subTitle: moreServicesInfoViewModel.subTitle,
        title: moreServicesInfoViewModel.title,
        description: moreServicesInfoViewModel.description,
      };

      await axios.put(url, moreServiceInformation, {
        isNeedJwtAuth: true,
      });
    },

    async addMoreService(url, moreServices, packageId) {
      const newServices = moreServices.filter(
        (current) => !current.isInitialized
      );

      if (!newServices || newServices.length === 0) return;

      const formatedMoreService = this.formatMoreServices(newServices);

      for (const element of formatedMoreService) {
        await this.postNewMoreService(url, element, packageId);
      }
    },

    async postNewMoreService(postUrl, service, packageId) {
      let bodyFormData = new FormData();

      bodyFormData.append("Title", service.title);
      bodyFormData.append("PackagePriceId", packageId);
      bodyFormData.append("Type", service.type);
      bodyFormData.append("Price", service.price);

      bodyFormData.append("IsBestValue", false);

      bodyFormData.append("IsMoreService", true);
      bodyFormData.append("CategoryId", service.categoryId);

      let options = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        isNeedJwtAuth: true,
      };

      await axios.post(postUrl, bodyFormData, options);
    },

    async updateMoreService(url, moreServices, packageId) {
      const currentServices = moreServices.filter(
        (current) => current.isInitialized
      );

      const changedServices = this.findChangedItems(
        this.packagePrice.moreServicesInitial,
        currentServices
      );

      if (!changedServices || changedServices.length === 0) return;

      const formatedMoreService = this.formatMoreServices(changedServices);

      for (const service of formatedMoreService) {
        if (service.isDeleted) {
          await this.deleteExistingPackage(service.id);
          continue;
        }

        if (!service.isServiceInitialized) {
          await this.postNewMoreService(
            "PackagePrices/box",
            service,
            packageId
          );
          continue;
        }

        let packagePriceService = {
          type: "-",
          ordering: null,
          categoryId: service.categoryId,
          isBestValue: false,
          description: null,
          isMoreService: true,
          attributes: [],
          price: service.price,
          title: service.title,
          packagePriceId: packageId,
        };

        await axios.put(`${url}/${service.id}`, packagePriceService, {
          isNeedJwtAuth: true,
        });
      }
    },

    async updateReels(url, reels) {
      if (reels?.length === 0) {
        await axios.delete(url, { isNeedJwtAuth: true });
        return;
      }

      const changedReels = this.findChangedItems(
        this.packagePrice.reelsInitial,
        reels
      );

      if (!changedReels || changedReels.length === 0) return;

      let bodyFormData = new FormData();

      if (changedReels?.length > 0) {
        for (const current of changedReels) {
          bodyFormData.append("Reels", current.videoUrl, current.videoUrl.name);
          bodyFormData.append("ReelsDescriptions", current.description ?? " ");

          if (current.posterUrl) {
            if (current.posterUrl?.name != "undefined")
              bodyFormData.append(
                "ReelsPosters",
                current.posterUrl,
                current.posterUrl.name
              );
          } else {
            await this.convertUrlToBlob("/black-poster.jpg").then((res) => {
              bodyFormData.append("ReelsPosters", res, "black-poster.jpg");
            });
          }
        }
      }

      let options = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        isNeedJwtAuth: true,
      };

      await axios.patch(url, bodyFormData, options);
    },

    async deleteCurrentPackageBox(boxId, array) {
      await this.deleteExistingPackage(boxId)
        .then((response) => {
          this.$store.commit("showToast", {
            id: "myToast",
            type: "success",
            title: "Success!",
            body: "Your desired package box has been successfully deleted!",
          });

          // mixins: custom-functions
          this.removeItemObjectWithId(array, boxId);
        })
        .catch((error) => {
          if (error.response?.data?.isSuccess == false) {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Error!",
              body: error.response?.data?.messages[0],
            });
          } else {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Error!",
              body: "Unfortunately, an unknown error has occurred! Please contact the website support!",
            });
          }

          console.log(error);
          console.log(error.response);
        });
    },

    async deleteCurrentMoreService(boxId, array) {
      const currentServices = array.filter((current) => current.id === boxId);

      const formatedMoreService = this.formatMoreServices(currentServices);

      const deletePromises = formatedMoreService.map(
        async (service) => await this.deleteExistingPackage(service.id)
      );
      Promise.all(deletePromises)
        .then((response) => {
          this.$store.commit("showToast", {
            id: "myToast",
            type: "success",
            title: "Success!",
            body: "Your desired service has been successfully deleted!",
          });

          // mixins: custom-functions
          this.removeItemObjectWithId(array, boxId);
        })
        .catch((error) => {
          if (error.response?.data?.isSuccess == false) {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Error!",
              body: error.response?.data?.messages[0],
            });
          } else {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Error!",
              body: "Unfortunately, an unknown error has occurred! Please contact the website support!",
            });
          }

          console.log(error);
          console.log(error.response);
        });
    },

    async deleteExistingPackage(boxId) {
      await axios.delete(`PackagePrices/Box/${boxId}`, { isNeedJwtAuth: true });
    },

    async addPackageAttribute(newValue) {
      this.packageAttributesIsLoading = true;
      await axios
        .post("PackageAttributes", { value: newValue }, { isNeedJwtAuth: true })
        .then(async (response) => {
          this.packageAttributes.unshift({
            value: newValue,
            id: response.data.value.id,
          });

          this.packageAttributesIsLoading = false;

          this.$store.commit("showToast", {
            id: "myToast",
            type: "success",
            title: "Success!",
            body: "Your desired package attribute has been successfully added!",
          });
        })
        .catch((error) => {
          if (error.response?.data?.isSuccess == false) {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Error!",
              body: error.response?.data?.messages[0],
            });
          } else {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Error!",
              body: "Unfortunately, an unknown error has occurred! Please contact the website support!",
            });
          }

          this.packageAttributesIsLoading = false;
          console.log(error);
          console.log(error.response);
        });
    },

    async editPackageAttribute(item) {
      if (item.value?.length == 0) {
        this.$store.commit("showToast", {
          id: "myToast",
          type: "error",
          title: "Error!",
          body: "Entering the desired attribute value is required!",
        });

        return;
      }
      await axios
        .put(
          `PackageAttributes/${item.id}`,
          { value: item?.value },
          { isNeedJwtAuth: true }
        )
        .then(async (response) => {
          item.editableFilterItems = false;

          this.$store.commit("showToast", {
            id: "myToast",
            type: "success",
            title: "Success!",
            body: "Your desired package attribute has been successfully edited!",
          });
        })
        .catch((error) => {
          if (error.response?.data?.isSuccess == false) {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Error!",
              body: error.response?.data?.messages[0],
            });
          } else {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Error!",
              body: "Unfortunately, an unknown error has occurred! Please contact the website support!",
            });
          }

          console.log(error);
          console.log(error.response);
        });
    },

    async deletePackageAttribute(id) {
      this.packageAttributesIsLoading = true;

      await axios
        .delete(`PackageAttributes/${id}`, { isNeedJwtAuth: true })
        .then(async (response) => {
          this.removeItemObjectWithId(this.packageAttributes, id);

          this.packageAttributesIsLoading = false;

          this.$store.commit("showToast", {
            id: "myToast",
            type: "success",
            title: "Success!",
            body: "Your desired package attribute has been successfully deleted!",
          });
        })
        .catch((error) => {
          if (error.response?.data?.isSuccess == false) {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Error!",
              body: error.response?.data?.messages[0],
            });
          } else {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Error!",
              body: "Unfortunately, an unknown error has occurred! Please contact the website support!",
            });
          }

          this.packageAttributesIsLoading = false;
          console.log(error);
          console.log(error.response);
        });
    },

    async addMoreServiceCategory(newValue) {
      this.moreServiceCategoriesIsLoading = true;
      await axios
        .post(
          "PackagePrices/box/categories",
          { title: newValue },
          { isNeedJwtAuth: true }
        )
        .then(async (response) => {
          this.moreServiceCategories.push({
            id: response.data.value.id,
            title: newValue,
          });

          this.moreServiceCategoriesIsLoading = false;

          this.$store.commit("showToast", {
            id: "myToast",
            type: "success",
            title: "Success!",
            body: "Your desired service category has been successfully added!",
          });
        })
        .catch((error) => {
          if (error.response?.data?.isSuccess == false) {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Error!",
              body: error.response?.data?.messages[0],
            });
          } else {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Error!",
              body: "Unfortunately, an unknown error has occurred! Please contact the website support!",
            });
          }

          this.moreServiceCategoriesIsLoading = false;
          console.log(error);
          console.log(error.response);
        });
    },

    async editServiceCategory(item) {
      if (item.title?.length == 0) {
        this.$store.commit("showToast", {
          id: "myToast",
          type: "error",
          title: "Error!",
          body: "Entering the desired category title is required!",
        });

        return;
      }
      await axios
        .put(
          `PackagePrices/box/categories/${item.id}`,
          { title: item?.title },
          { isNeedJwtAuth: true }
        )
        .then(async (response) => {
          item.editableFilterItems = false;

          this.$store.commit("showToast", {
            id: "myToast",
            type: "success",
            title: "Success!",
            body: "Your desired service category has been successfully edited!",
          });
        })
        .catch((error) => {
          if (error.response?.data?.isSuccess == false) {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Error!",
              body: error.response?.data?.messages[0],
            });
          } else {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Error!",
              body: "Unfortunately, an unknown error has occurred! Please contact the website support!",
            });
          }

          console.log(error);
          console.log(error.response);
        });
    },

    async deleteServiceCategory(id) {
      this.moreServiceCategoriesIsLoading = true;

      await axios
        .delete(`PackagePrices/Box/categories/${id}`, { isNeedJwtAuth: true })
        .then(async (response) => {
          this.removeItemObjectWithId(this.moreServiceCategories, id);

          this.moreServiceCategoriesIsLoading = false;

          this.$store.commit("showToast", {
            id: "myToast",
            type: "success",
            title: "Success!",
            body: "Your desired service category has been successfully deleted!",
          });
        })
        .catch((error) => {
          if (error.response?.data?.isSuccess == false) {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Error!",
              body: error.response?.data?.messages[0],
            });
          } else {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Error!",
              body: "Unfortunately, an unknown error has occurred! Please contact the website support!",
            });
          }

          this.moreServiceCategoriesIsLoading = false;
          console.log(error);
          console.log(error.response);
        });
    },

    async getMoreServicesInfo(id) {
      this.moreServicesInfo = {};
      let isLoading = true;
      const url = `PackagePrices/moreServiceInfo/${id}`;

      try {
        const httpResponse = await axios.get(url);
        this.moreServicesInfo = httpResponse.data.value;

        this.moreServicesInfo.headerImageUrl = `${settings.backendBaseUrl}/${this.moreServicesInfo.headerImageUrl}`;
        if (this.moreServicesInfo?.headerImageUrl)
          this.$refs.serviceHeaderImageFileUploader.refPound.addFile(
            `${this.moreServicesInfo.headerImageUrl}?_`
          );

        isLoading = false;
      } catch (error) {
        console.log(error);
        isLoading = false;
      }

      return isLoading;
    },

    addNewPackageBox() {
      let packagePriceBoxId = this.packagePrice.packagePriceBoxes.length + 1;

      const newBox = {
        id: packagePriceBoxId,
        title: "",
        price: null,
        type: "-",
        isBestValue: false,
        attributes: [],
        rawAttributes: [],
      };

      this.packagePrice.packagePriceBoxes.push(newBox);
    },

    addNewMoreService() {
      let moreServiceId = this.packagePrice.moreServices.length + 1;

      const newService = {
        id: moreServiceId,
        categoryId: null,
        informations: [],
        newServiceInfo: {
          title: "",
          price: null,
        },
      };

      this.packagePrice.moreServices.push(newService);
    },

    addNewServiceInfo(serviceId) {
      let service = this.packagePrice.moreServices.find(
        (current) => current.id === serviceId
      );

      if (!service.categoryId) {
        this.$store.commit("showToast", {
          id: "myToast",
          type: "error",
          title: "Error!",
          body: "Please select the category related to the services!",
        });

        return;
      }

      if (!service.newServiceInfo.title || !service.newServiceInfo.price) {
        this.$store.commit("showToast", {
          id: "myToast",
          type: "warning",
          title: "Warning!",
          body: "Please fill in the requested fields!",
        });

        return;
      }

      let serviceInfoId = service.informations.length + 1;

      const newService = {
        id: serviceInfoId,
        title: service.newServiceInfo.title,
        price: service.newServiceInfo.price,
        isServiceInitialized: false,
      };

      service.informations.push(newService);

      service.newServiceInfo.title = "";
      service.newServiceInfo.price = null;
    },

    addNewReelsBox() {
      let reelsBoxId = this.packagePrice.reels?.length + 1;

      const newReelsBox = {
        id: reelsBoxId,
        videoUrl: null,
        posterUrl: null,
        description: "",
      };

      this.packagePrice.reels.push(newReelsBox);
    },

    mostPopularChange(event, box) {
      // get alll inputs with checkbox type that contain isBestValue id
      this.packagePrice.packagePriceBoxes.forEach(
        (current) => (current.isBestValue = false)
      );

      const checkboxElements = Array.from(
        document.querySelectorAll("input[type='checkbox'][id*='isBestValue']")
      );

      if (event.target.checked) {
        box.isBestValue = true;

        checkboxElements
          .filter((current) => current.id !== event.target.id)
          .forEach((el) => {
            el.checked = false;
            el.setAttribute("disabled", "");
            el.parentNode.style.opacity = 0.5;
            el.parentNode.style.pointerEvents = "none";
          });
        return;
      }

      checkboxElements.forEach((el) => {
        el.removeAttribute("disabled");
        el.parentNode.style.opacity = 1;
        el.parentNode.style.pointerEvents = "auto";
      });
    },

    deleteMoreServiceItem(currentService, item) {
      if (item?.isServiceInitialized) {
        currentService.deletedInformationItems.push(item);
      }

      this.removeItemObjectWithId(currentService?.informations, item?.id);
    },

    formatMoreServices(moreServices) {
      return moreServices?.slice().reduce((acc, currentService) => {
        currentService.informations.forEach((current) => {
          acc?.push({
            id: current.id,
            title: current.title,
            price: current.price,
            isServiceInitialized: current.isServiceInitialized,
            type: "-",
            isBestValue: false,
            isMoreService: true,
            categoryId: currentService.categoryId,
          });
        });
        if (currentService?.deletedInformationItems?.length > 0)
          currentService.deletedInformationItems.forEach((current) => {
            acc?.push({
              id: current.id,
              isServiceInitialized: current.isServiceInitialized,
              categoryId: currentService.categoryId,
              isDeleted: true,
            });
          });

        return acc;
      }, []);
    },
  },
};
</script>
