<template>
  <Combobox
    as="div"
    class="w-full"
    :modelValue="modelValue"
    @update:modelValue="updateValue"
    nullable
  >
    <ComboboxLabel
      class="block capitalize text-sm font-medium leading-6 text-zinc-600 dark:text-zinc-300"
      >Selected {{ selectedTitle ?? "Item" }}:
      <strong>
        {{ currentCategory ?? selectedItem ?? "-" }}
      </strong>
    </ComboboxLabel>
    <div class="relative mt-2">
      <ComboboxInput
        class="w-full rounded-md border-0 bg-zinc-100 dark:bg-zinc-950 py-1.5 pl-3 pr-10 text-black dark:text-white placeholder:text-zinc-400 dark:placeholder:text-zinc-500 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-600 text-sm leading-6 outline-none"
        placeholder="Search Category..."
        @change="query = $event.target.value"
        :displayValue="() => currentCategory ?? selectedItem"
      />

      <ComboboxButton
        class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none bg-zinc-300 dark:bg-zinc-600"
      >
        <Icon
          class="h-5 w-5 text-zinc-700 dark:text-white"
          icon="ph:caret-up-down-fill"
          aria-hidden="true"
        />
      </ComboboxButton>

      <ComboboxOptions
        v-if="filteredItems.length > 0"
        class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-zinc-900 py-1 shadow-lg ring-1 ring-black dark:ring-white dark:ring-opacity-20 ring-opacity-10 focus:outline-none text-sm"
      >
        <ComboboxOption
          v-for="item in filteredItems"
          :key="item.value"
          :value="item.value"
          as="template"
          v-slot="{ active, selected }"
        >
          <li
            :class="[
              'relative cursor-default select-none py-2 pl-8 pr-4',
              active
                ? 'bg-primary text-white'
                : 'text-zinc-900 dark:text-white',
            ]"
          >
            <span :class="['block truncate', selected && 'font-semibold']">
              {{ item.title }}
            </span>

            <span
              v-if="selected"
              :class="[
                'absolute inset-y-0 left-0 flex items-center pl-1.5',
                active ? 'text-white' : 'text-primary',
              ]"
            >
              <Icon class="h-5 w-5" aria-hidden="true" icon="pajamas:check" />
            </span>
          </li>
        </ComboboxOption>
      </ComboboxOptions>
    </div>
  </Combobox>
</template>

<script>
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/vue";
import { Icon } from "@iconify/vue";

export default {
  data() {
    return {
      query: "",
      selectedItem: null,
      currentCategory: this.category
    };
  },
  props: {
    items: {
      type: Array,
      required: false,
    },
    selectedTitle: {
      type: String,
      required: false,
    },
    category: {
      type: String,
      required: false,
    },
    modelValue: {
      type: [String, Number, Object],
      required: false,
    },
  },
  components: {
    Icon,
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxLabel,
    ComboboxOption,
    ComboboxOptions,
  },
  computed: {
    filteredItems() {
      if (this.query === "") return this.items;

      return this.items.filter((current) => {
        return current.title.toLowerCase().includes(this.query.toLowerCase());
      });
    },
  },
  methods: {
    updateValue(value) {
      this.$emit("update:modelValue", value);
      if (value) {
        this.query = "";

        this.selectedItem = this.items.find(
          (current) => current.value === value
        )?.title;

        if (this.currentCategory) this.currentCategory = this.selectedItem;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
