<template>
  <Modal :has-footer="true" :modal-size="`md`" :modal-id="`deleteModal`" footerBorderColor="border-red-500">
    <!-- Modal body -->
    <template v-slot:modalBody>
      <div>
        <div class="space-y-1">
          <div
            class="flex flex-col gap-3 font-medium text-zinc-700 dark:text-zinc-300 text-md max-sm:text-base"
          >
            <template v-if="mode == 'normal'">
              <div class="flex flex-col gap-2">
                <span class="text-red-600 font-bold text-md"
                  >{{ title }}</span
                >

                <span v-if="info?.message" class="text-black dark:text-white text-sm">{{ info?.message }}</span>

                <span class="text-black dark:text-white text-sm">
                  If you want to delete, enter the word <span class="italic font-bold mx-1">"yes"</span> in the entry below
                </span>
              </div>

              <Input
                :is-title-show="false"
                type="text"
                :outer-input-class="
                  value == 'yes'
                    ? `!border !border-green-500`
                    : `!border !border-red-500`
                "
                input-class="dark:!bg-zinc-900"
                placeholder="yes"
                :isRequired="true"
                v-model="value"
              ></Input>
            </template>

            <template v-if="mode == 'sample'">
              <div class="flex flex-col py-2">
                <span class="text-red-600 font-bold text-md"
                  >{{ title }}</span
                >
              </div>
            </template>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:modalFooter>
      <div class="flex gap-2">
        <button
          type="button"
          data-te-modal-dismiss
          class="inline-flex items-center text-sm font-normal text-center text-white rounded-md bg-primary px-4 py-2 disabled:opacity-60"
        >
          Cancel
        </button>

        <button
          v-if="mode == 'normal'"
          @click="submit"
          type="button"
          :disabled="value != 'yes'"
          data-te-modal-dismiss
          class="inline-flex items-center text-sm font-normal text-center text-white rounded-md bg-red-500 px-4 py-2 disabled:opacity-60"
        >
          {{ buttonTitle }}
        </button>

        <button
          @click="submit"
          v-if="mode == 'sample'"
          type="button"
          data-te-modal-dismiss
          class="inline-flex items-center text-sm font-normal text-center text-white rounded-md bg-red-500 px-4 py-2 disabled:opacity-60"
        >
          {{ buttonTitle }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import Input from "@/components/Input.vue";

export default {
  components: {
    Modal,
    Input,
  },
  data() {
    return {
      value: "",
    };
  },
  props: {
    mode: {
      type: String,
      default: "normal",
      required: false,
    },
    title: {
      type: String,
      default: "Are you sure about the delete operation?",
      required: false,
    },
    buttonTitle: {
      type: String,
      default: "Delete",
      required: false,
    },
    info: {
      type: Object,
      required: false,
    },
  },
  
  updated(){
    this.value = ''
  },

  methods: {
    async submit() {
      this.$emit("deleteSubmit", this.info);
    },
  },
};
</script>
