import axios from "axios";

export async function getPackageAttributes() {
    let attributes = null;
    let isLoadingAttribute = true;

    let url = `PackageAttributes`;

    try {
        let httpResponse = await axios.get(url);

        attributes = httpResponse.data.value;
        
        isLoadingAttribute = false;
    } catch (error) {
        console.log(error);
        isLoadingAttribute = false;
    }

    return {
        isLoadingAttribute, attributes
    };
}