<template>
  <button
    @click="toggleAccordion()"
    class="text-zinc-900 relative z-[2] dark:text-zinc-400  hover:bg-zinc-200 dark:hover:bg-zinc-700 w-full flex text-start rounded-md box-border p-3"
    :aria-expanded="isOpen"
    :class="{
      'bg-zinc-200  dark:bg-zinc-700': isOpen,
      'bg-transparent': !isOpen,
    }"
  >
    <span class="me-3 xs:text-2xl text-xl">
      <slot name="icon"></slot>
    </span>
    <span class="w-full xs:text-base text-sm">
      <slot name="title" />
    </span>
    <span
      class="box-border mt-1 text-zinc-400 dark:text-zinc-400"
      :class="{
        'rotate-180': isOpen,
        'rotate-0': !isOpen,
      }"
    >
      <span class="float-end">
        <Icon icon="fa6-solid:angle-down" />
      </span>
    </span>
  </button>

  <transition name="slide-down">
    <div
      class="dark:bg-zinc-950 relative xs:text-sm text-xs z-[1] bg-zinc-300 rounded-md mt-2 p-2"
      v-show="isOpen"
    >
      <slot name="content" />
    </div>
  </transition>
</template>

<script>
import { Icon } from "@iconify/vue";
export default {
  data() {
    return {
      isOpen: false,
    };
  },

  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
  },
  components: {
    Icon,
  },
};
</script>
