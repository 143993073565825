<template>
  <div class="h-auto p-3 pb-10">
    <nav class="flex flex-col" aria-label="Breadcrumb">
      <ol class="inline-flex flex-wrap items-center">
        <li class="inline-flex items-center">
          <router-link
            to="/"
            class="inline-flex items-center sm:text-sm text-xs font-medium text-zinc-700 hover:text-zinc-950 dark:text-zinc-400 dark:hover:text-white"
          >
            <svg
              class="me-2 w-4 h-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
              ></path>
            </svg>
            Home
          </router-link>
        </li>
        <li>
          <div class="flex items-center">
            <svg
              class="w-6 h-6 text-zinc-400 rtl:rotate-180"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <router-link
              to="/categories"
              class="sm:text-sm text-xs font-medium text-zinc-700 hover:text-zinc-950 dark:text-zinc-400 dark:hover:text-white"
              >Categories List</router-link
            >
          </div>
        </li>
        <li v-if="type == 'overalPackageBox'">
          <div class="flex items-center">
            <svg
              class="w-6 h-6 text-zinc-400 rtl:rotate-180"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <router-link
              :to="`/packages/overall?category-id=${category.id}&is-category-exist=true&category-title=${category.title}`"
              class="sm:text-sm text-xs font-medium text-zinc-700 hover:text-zinc-950 dark:text-zinc-400 dark:hover:text-white"
              >Overall Package</router-link
            >
          </div>
        </li>
        <li>
          <div class="flex items-center">
            <svg
              class="w-6 h-6 text-zinc-400 rtl:rotate-180"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <router-link
              :to="
                type == 'category'
                  ? `/packages/packages-price?category-id=${category.id}&is-category-exist=true&category-title=${category.title}`
                  : `/packages/packages-price?type=overalPackageBox&&is-overal-package-box-exist=true&overal-package-box-id=${overalPackageBox.id}&overal-package-box-title=${overalPackageBox.title}&category-id=${category.id}&category-title=${category.title}`
              "
              class="sm:text-sm text-xs font-medium text-zinc-700 hover:text-zinc-950 dark:text-zinc-400 dark:hover:text-white"
              >Packages Price</router-link
            >
          </div>
        </li>
        <li>
          <div class="flex items-center">
            <svg
              class="w-6 h-6 text-zinc-400 rtl:rotate-180"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span
              class="sm:text-sm text-xs font-medium text-zinc-700 dark:text-zinc-400"
              >Add Project</span
            >
          </div>
        </li>
      </ol>

      <div class="mt-5 w-full">
        <h1
          v-if="type == 'category'"
          class="md:text-lg text-base text-zinc-950 font-medium dark:text-zinc-200"
        >
          Add Project - Package Price | Category: {{ category.title }}
        </h1>
        <h1
          v-else
          class="md:text-lg text-base text-zinc-950 font-medium dark:text-zinc-200"
        >
          Add Project - Package Price | Overall Package Box:
          {{ overalPackageBox.title }}
        </h1>
      </div>
    </nav>

    <div
      class="bg-white dark:bg-zinc-900 rounded-md p-5 w-full box-border border dark:border-zinc-700 mt-5"
    >
      <form @submit.prevent="addProject">
        <div class="informations flex flex-col gap-6 mb-5">
          <div class="flex flex-col gap-3">
            <div class="flex flex-col gap-2 mb-2">
              <h3
                class="md:text-lg text-base text-secondary dark:text-white font-medium"
              >
                Basic information
              </h3>
              <hr
                class="border-none w-full h-[2px] bg-zinc-200 dark:bg-zinc-600"
              />
            </div>

            <div class="flex max-[600px]:flex-col gap-3">
              <Input
                class="w-full required"
                type="text"
                placeholder="Title"
                v-model="addProjectViewModel.title"
                :isRequired="true"
              ></Input>

              <Input
                class="w-full required max-w-[200px] max-md:max-w-[150px] max-[600px]:max-w-none"
                type="number"
                placeholder="Ordering"
                v-model="addProjectViewModel.ordering"
              ></Input>
            </div>

            <Input
              class="w-full required"
              type="textarea"
              textareaRows="3"
              placeholder="Preview Description"
              v-model="addProjectViewModel.previewDescription"
              :isRequired="true"
            ></Input>

            <Input
              class="w-full required"
              type="textarea"
              placeholder="Description"
              v-model="addProjectViewModel.description"
              :isRequired="true"
            ></Input>

            <div class="flex flex-col gap-2 w-full required">
              <h6 class="font-medium text-sm text-zinc-600 dark:text-zinc-400">
                Header Image
              </h6>
              <FileUploader
                @updatePendingFiles="updatePendingFiles($event, 'headerImage')"
              ></FileUploader>
            </div>
          </div>

          <div class="flex flex-col gap-2">
            <div class="flex flex-col gap-2 mb-2">
              <h3
                class="md:text-lg text-base text-secondary dark:text-white font-medium"
              >
                Sub Header Informations
              </h3>
              <hr
                class="border-none w-full h-[2px] bg-zinc-200 dark:bg-zinc-600"
              />
            </div>

            <Input
              class="w-full"
              type="text"
              placeholder="Sub Title"
              v-model="addProjectViewModel.subTitle"
              :isRequired="false"
            ></Input>

            <Input
              type="textarea"
              textareaRows="3"
              placeholder="Sub Description"
              v-model="addProjectViewModel.subDescription"
              :isRequired="false"
            ></Input>
          </div>

          <div class="flex flex-col gap-3">
            <div class="flex flex-col gap-2 mb-2">
              <h3
                class="md:text-lg text-base text-secondary dark:text-white font-medium"
              >
                Gallery Section
              </h3>
              <hr
                class="border-none w-full h-[2px] bg-zinc-200 dark:bg-zinc-600"
              />
            </div>

            <div class="flex flex-col gap-2 w-full required">
              <h6 class="font-medium text-sm text-zinc-600 dark:text-zinc-400">
                Gallery Files
              </h6>
              <FileUploader
                :allow-multiple="true"
                acceptedFileTypes="image/jpeg, image/png, image/webp, video/mp4, video/webm"
                @updatePendingFiles="updatePendingFiles($event, 'imageFiles')"
              ></FileUploader>
              <span class="text-xs text-black dark:text-white"
                >* Notice: You can select multiple pictures for the gallery
                section.</span
              >
              <span class="text-xs text-black dark:text-white"
                >* Notice: You can reorder pictures. The pictures are displayed
                on the site in the order they are placed.</span
              >
              <div class="flex gap-5 items-center mt-2">
                <h5
                  class="font-medium text-sm text-zinc-600 dark:text-zinc-400"
                >
                  Gallery Type:
                </h5>
                <div class="flex items-center gap-3">
                  <div class="flex w-full items-center gap-1">
                    <input
                      v-model="addProjectViewModel.isGridGallery"
                      class="relative shrink-0 float-left h-4 w-4 appearance-none rounded-full border-2 border-solid border-zinc-300 before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[8px] checked:after:w-[8px] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-zinc-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:checked:focus:border-primary"
                      type="radio"
                      :value="true"
                      name="typeRadio"
                      id="gridGalleryRadio"
                    />
                    <label
                      class="text-black text-sm max-sm:text-xs dark:text-white mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
                      for="gridGalleryRadio"
                    >
                      Grid
                    </label>
                  </div>
                  <div class="flex w-full items-center gap-1">
                    <input
                      v-model="addProjectViewModel.isGridGallery"
                      class="relative shrink-0 float-left h-4 w-4 appearance-none rounded-full border-2 border-solid border-zinc-300 before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[8px] checked:after:w-[8px] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-zinc-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:checked:focus:border-primary"
                      type="radio"
                      :value="false"
                      name="typeRadio"
                      id="sliderGalleryRadio"
                    />
                    <label
                      class="text-black text-sm max-sm:text-xs dark:text-white mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
                      for="sliderGalleryRadio"
                    >
                      Slider
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex flex-col gap-2 mt-5">
              <div class="flex flex-col gap-2 mb-2">
                <h6
                  class="md:text-lg text-base text-secondary dark:text-white font-medium"
                >
                  Reels Section
                </h6>

                <hr
                  class="border-none w-full h-[2px] bg-zinc-200 dark:bg-zinc-600"
                />
              </div>

              <div class="w-full grid md:grid-cols-2 gap-5">
                <ReelsBox
                  v-if="reelsBox || reelsBox.length > 0"
                  v-for="(reels, reelsIndex) in reelsBox"
                  :key="reelsIndex"
                >
                  <template v-slot:file>
                    <div class="absolute top-2 end-2">
                      <button
                        type="button"
                        class="text-red-500 w-6 h-6 inline-flex items-center justify-center p-1 border border-red-500 rounded-full"
                        @click="removeItemObjectWithId(reelsBox, reels.id)"
                      >
                        <Icon icon="entypo:trash" />
                      </button>
                    </div>
                    <div>
                      <h6
                        class="font-medium mb-2 text-sm text-zinc-600 dark:text-zinc-400"
                      >
                        Video File
                      </h6>
                      <FileUploader
                        acceptedFileTypes="video/mp4, video/webm"
                        @updatePendingFiles="
                          updatePendingFiles($event, 'reelsVideo', reels.id)
                        "
                      ></FileUploader>
                    </div>
                    <div>
                      <h6
                        class="font-medium mb-2 text-sm text-zinc-600 dark:text-zinc-400"
                      >
                        Poster Image
                      </h6>
                      <FileUploader
                        @updatePendingFiles="
                          updatePendingFiles($event, 'posterImage', reels.id)
                        "
                      ></FileUploader>
                    </div>
                  </template>
                  <template v-slot:more>
                    <Input
                      :isTitleShow="false"
                      type="textarea"
                      textareaRows="4"
                      placeholder="Description"
                      v-model="reels.description"
                      :isRequired="true"
                      class="required"
                      input-class="dark:!bg-zinc-900"
                      outer-input-class="!rounded-xl sm:!text-sm !text-xs dark:!border-zinc-800"
                    ></Input>
                  </template>
                </ReelsBox>

                <div
                  class="w-full h-full p-1 rounded-2xl border-2 border-zinc-300/60 dark:border-zinc-800/60 dark:text-white/60 bg-zinc-200/60 dark:bg-zinc-950/60"
                  :class="
                    !reelsBox || reelsBox.length === 0 ? 'col-span-2' : ''
                  "
                >
                  <div
                    class="w-full h-full relative bg-white/60 dark:bg-zinc-800/60 px-4 py-16 rounded-xl flex justify-center items-center"
                  >
                    <button
                      @click="addNewReelsBox"
                      type="button"
                      class="text-white h-fit text-4xl bg-primary rounded-md hover:bg-primary/80 transition-all duration-300 focus:outline-none py-2 px-4 w-fit disabled:opacity-60 disabled:pointer-events-none"
                    >
                      <Icon icon="ic:baseline-plus" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr class="w-full border dark:border-zinc-700" />
        <div class="wrapper-button sm:text-base text-sm flex justify-end mt-5">
          <button
            v-if="!isAdding"
            :disabled="
              addProjectViewModel.title?.length == 0 ||
              addProjectViewModel.previewDescription?.length == 0 ||
              addProjectViewModel.description?.length == 0 ||
              !addProjectViewModel.ordering ||
              !addProjectViewModel.imageFiles ||
              addProjectViewModel.imageFiles?.length == 0
            "
            type="submit"
            class="text-white bg-primary rounded-md hover:bg-primary/80 transition-all duration-300 focus:outline-none py-2 px-6 w-fit disabled:opacity-60 disabled:pointer-events-none"
          >
            Save
          </button>

          <button
            v-else
            disabled
            type="button"
            class="text-white bg-primary rounded-md opacity-60 cursor-default transition-all duration-300 focus:outline-none py-2 px-6 w-fit"
          >
            <svg
              role="status"
              class="inline w-4 h-4 me-1 text-white animate-spin"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="#E5E7EB"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentColor"
              />
            </svg>
            Uploading...
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";
import Input from "@/components/Input.vue";
import FilterBox from "@/components/FilterBox.vue";
import FileUploader from "@/components/FileUploader.vue";
import axios from "axios";

import ReelsBox from "@/components/ReelsBox.vue";

export default {
  components: {
    Icon,
    FileUploader,
    Input,
    FilterBox,
    ReelsBox,
  },

  data() {
    return {
      addProjectViewModel: {
        ordering: 10000,
        title: "",
        description: "",
        previewDescription: "",
        headerImage: null,
        subTitle: "",
        subDescription: "",
        packagePriceId: null,
        imageFiles: null,
        reels: null,
        isGridGallery: true,
      },
      category: {
        id: null,
        title: "",
      },
      overalPackageBox: {
        id: null,
        title: "",
      },
      type: "",
      packagePriceId: null,
      isAdding: false,
      reelsBox: [],
    };
  },

  async created() {
    this.type = this.$route.query["type"] ?? "category";

    // When the package price is called by category
    let categoryId = this.$route.query["category-id"];
    let categoryTitle = this.$route.query["category-title"];

    this.category.title = categoryTitle;
    this.category.id = categoryId;

    // When the package price is called by overal package box
    let overalPackageBoxId = this.$route.query["overal-package-box-id"];
    let overalPackageBoxTitle = this.$route.query["overal-package-box-title"];

    this.overalPackageBox.title = overalPackageBoxTitle;
    this.overalPackageBox.id = overalPackageBoxId;

    let packagePriceId = this.$route.query["package-price-id"];
    this.addProjectViewModel.packagePriceId = packagePriceId;
    this.packagePriceId = packagePriceId;
  },

  methods: {
    updatePendingFiles: function (files, imageType, id, sliderIndex = 0) {
      if (files) {
        if (imageType == "headerImage") {
          this.addProjectViewModel.headerImage = files[0]?.file;
        }
        if (imageType == "imageFiles") {
          this.addProjectViewModel.imageFiles = [];

          for (let index = 0; index < files.length; index++) {
            const element = files[index]?.file;

            if (element.constructor.name === "File" || "Blob") {
              this.addProjectViewModel.imageFiles[index] = element;
            }
          }
        }
        if (imageType == "reelsVideo") {
          this.reelsBox.find((current) => current.id === id).videoUrl =
            files[0]?.file;
        }
        if (imageType == "posterImage") {
          this.reelsBox.find((current) => current.id === id).posterUrl =
            files[0]?.file;
        }
      }
    },

    async addProject() {
      this.addProjectViewModel.reels = this.reelsBox;

      for (const file of this.addProjectViewModel.reels.map(
        (current) => current.videoUrl
      )) {
        if (!file) {
          this.$store.commit("showToast", {
            id: "myToast",
            type: "error",
            title: "Error!",
            body: "One of the desired reels boxes does not have a video file!",
          });
          return;
        }
      }

      var bodyFormData = new FormData();

      bodyFormData.append("Title", this.addProjectViewModel.title);
      bodyFormData.append("Description", this.addProjectViewModel.description);
      bodyFormData.append("HeaderImage", this.addProjectViewModel.headerImage);

      if (this.addProjectViewModel.subTitle)
        bodyFormData.append("SubTitle", this.addProjectViewModel.subTitle);

      if (this.addProjectViewModel.subDescription)
        bodyFormData.append(
          "SubDescription",
          this.addProjectViewModel.subDescription
        );

      bodyFormData.append(
        "PreviewDescription",
        this.addProjectViewModel.previewDescription
      );

      if (this.addProjectViewModel.ordering) {
        bodyFormData.append("Ordering", this.addProjectViewModel.ordering);
      }

      bodyFormData.append(
        "PackagePriceId",
        this.addProjectViewModel.packagePriceId
      );

      bodyFormData.append(
        "IsGridGallery",
        this.addProjectViewModel.isGridGallery
      );

      this.addProjectViewModel.imageFiles?.forEach((imageFile) => {
        bodyFormData.append("ImageFiles", imageFile, imageFile.name);
      });

      if (this.addProjectViewModel.reels?.length > 0) {
        for (const current of this.addProjectViewModel.reels) {
          bodyFormData.append("Reels", current.videoUrl, current.videoUrl.name);
          bodyFormData.append("ReelsDescriptions", current.description ?? " ");

          if (current.posterUrl) {
            if (current.posterUrl?.name != "undefined")
              bodyFormData.append(
                "ReelsPosters",
                current.posterUrl,
                current.posterUrl.name
              );
          } else {
            await this.convertUrlToBlob("/black-poster.jpg").then((res) => {
              bodyFormData.append("ReelsPosters", res, "black-poster.jpg");
            });
          }
        }
      }

      bodyFormData.append("OptimizeImage.Quality", 85);
      bodyFormData.append("OptimizeImage.ImageExtensions", "webp");

      let options = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        isNeedJwtAuth: true,
      };

      this.isAdding = true;

      await axios
        .post("Projects", bodyFormData, options)
        .then((response) => {
          document
            .querySelector(".main-content")
            ?.scrollTo({ top: 0, behavior: "smooth" });

          this.$store.commit("showToast", {
            id: "myToast",
            type: "success",
            title: "Success!",
            body: "Your desired project has been successfully added!",
          });

          if (this.type == "category") {
            this.$router.push(
              `/packages/packages-price?category-id=${this.category.id}&is-category-exist=true&category-title=${this.category.title}`
            );
          } else {
            this.$router.push(
              `/packages/packages-price?type=overalPackageBox&&is-overal-package-box-exist=true&overal-package-box-id=${this.overalPackageBox.id}&overal-package-box-title=${this.overalPackageBox.title}&category-id=${this.category.id}&category-title=${this.category.title}`
            );
          }
        })
        .catch((error) => {
          if (error.response?.data?.isSuccess == false) {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Error!",
              body: error.response?.data?.messages[0],
            });
          } else {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Error!",
              body: "Unfortunately, an unknown error has occurred! Please contact the website support!",
            });
          }

          console.log(error);
          console.log(error.response);

          if (this.type == "category") {
            this.$router.push(
              `/packages/packages-price?category-id=${this.category.id}&is-category-exist=true&category-title=${this.category.title}`
            );
          } else {
            this.$router.push(
              `/packages/packages-price?type=overalPackageBox&&is-overal-package-box-exist=true&overal-package-box-id=${this.overalPackageBox.id}&overal-package-box-title=${this.overalPackageBox.title}&category-id=${this.category.id}&category-title=${this.category.title}`
            );
          }
        });
    },

    addNewReelsBox() {
      let reelsBoxId = this.reelsBox.length + 1;

      const newReelsBox = {
        id: reelsBoxId,
        videoUrl: null,
        posterUrl: null,
        description: "",
      };

      this.reelsBox.push(newReelsBox);
    },
  },
};
</script>
