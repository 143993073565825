<template>
  <div
    v-if="mode == 'normal'"
    class="w-full relative h-full p-1 border-2 border-zinc-300 dark:border-zinc-800 text-black dark:text-white rounded-2xl bg-zinc-200 dark:bg-zinc-950 mb-7 max-xl:mb-5 font-[inter]"
  >
    <div
      class="relative w-full gap-3 bg-white dark:bg-zinc-800 px-4 py-8 rounded-xl flex flex-col justify-between items-center"
    >
      <div
        v-if="isBestValue"
        class="absolute shrink-0 whitespace-nowrap -top-4 left-1/2 -translate-x-1/2 bg-primary rounded-full py-1 px-4 text-xs uppercase text-white"
      >
        <span>Most Popular</span>
      </div>

      <!-- Title -->
      <h3 class="font-semibold text-xl max-xl:text-lg capitalize">
        {{ title }}
      </h3>

      <div class="flex items-center justify-center">
        <h4 class="font-medium text-5xl max-lg:text-4xl">
          <span class="dark:text-green-500">$</span>{{ price }}
        </h4>
      </div>
    </div>

    <div class="pt-8 max-xl:pt-6 lg:text-sm text-xs">
      <ul class="space-y-5 px-4 overflow-y-auto h-full max-h-[250px]">
        <li
          v-for="(item, index) in attributes"
          :key="item.id"
          class="flex gap-3 items-start"
        >
          <span
            class="border-2 border-green-500 fill-green-500 p-[2px] inline-block rounded-md"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-3 h-3"
              viewBox="0 0 448 512"
            >
              <path
                d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
              />
            </svg>
          </span>
          <span class="leading-tight pt-[2px]">{{ item.value }}</span>
        </li>
      </ul>
    </div>
  </div>

  <div
    v-else-if="mode == 'editable'"
    class="flex max-[660px]:flex-col w-full min-[661px]:h-[310px] relative p-1 border-2 border-zinc-300 dark:border-zinc-800 text-black dark:text-white rounded-xl bg-zinc-200 dark:bg-zinc-950 font-[inter]"
  >
    <div
      class="relative max-w-[330px] max-[850px]:max-w-[280px] max-md:max-w-[230px] max-[660px]:max-w-none overflow-hidden w-full gap-8 max-sm:gap-6 bg-white dark:bg-zinc-800 p-3 pt-10 rounded-lg flex justify-center items-center"
    >
      <slot name="informations"></slot>
    </div>

    <div
      class="ps-3 max-[660px]:ps-2 py-1 max-[660px]:pt-3 w-full h-full max-[660px]:h-[250px]"
    >
      <slot name="more"></slot>
    </div>
  </div>
</template>

<script>
import Input from "@/components/Input.vue";

export default {
  props: {
    title: {
      type: String,
      required: false,
    },
    price: {
      type: Number,
      required: false,
    },
    isBestValue: {
      type: Boolean,
      required: false,
      default: false,
    },
    attributes: {
      type: Array,
      required: false,
    },
    mode: {
      type: String,
      required: false,
      default: "normal",
    },
  },
  component: {
    Input,
  },
};
</script>

<style lang="scss" scoped></style>
