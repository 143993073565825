<script setup>
import { vOnClickOutside } from "@vueuse/components";
</script>

<template>
  <!-- sidebar -->
  <nav class="sidebar bg-white dark:bg-zinc-900" v-on-click-outside="closeMenuOnOutside">
    <!-- sidebar head -->
    <div class="sidebar-head flex justify-between items-center gap-5 p-4 pt-7">
      <router-link to="/" exact class="flex max-w-[250px]">
        <span class="text-2xl dark:text-white text-black font-bold">Arctic Sky Media</span>
      </router-link>
      <!-- Logo -->
      <button class="lg:hidden block dark:text-zinc-400" @click="$emit('sidebarToggle')">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          role="img"
          width="25px"
          height="25px"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 32 32"
        >
          <path
            fill="currentColor"
            d="M7.219 5.781L5.78 7.22L14.563 16L5.78 24.781l1.44 1.439L16 17.437l8.781 8.782l1.438-1.438L17.437 16l8.782-8.781L24.78 5.78L16 14.563z"
          />
        </svg>
      </button>
    </div>
    <!-- sidebar list -->
    <div
      class="sidebar-list overflow-y-auto h-[calc(var(--doc-height)-(77.34px+1rem))] px-4 pb-9 mt-4"
    >
      <div class="wrap-item font-medium dark:text-zinc-400">
        <div class="item">
          <router-link
            to="/"
            class="w-full flex text-start rounded-md box-border p-3 text-zinc-900 dark:text-zinc-400 hover:bg-zinc-200 dark:hover:bg-zinc-700"
          >
            <span class="me-3 xs:text-2xl text-xl"><Icon icon="tabler:home" /></span>
            <span class="w-full xs:text-base text-sm"> Home </span>
          </router-link>
        </div>
        <div class="item mt-3">
          <menu-accordion>
            <template v-slot:icon>
              <Icon icon="bx:category" />
            </template>
            <template v-slot:title> Categories </template>
            <template v-slot:content>
              <router-link
                to="/categories"
                class="flex items-center gap-3 w-full text-start rounded-md p-3 hover:bg-zinc-200 dark:hover:bg-zinc-700"
              >
                <span class="text-sm rtl:rotate-180"
                  ><Icon icon="mingcute:arrow-right-fill"
                /></span>
                Categories List
              </router-link>
              <router-link
                to="/categories/add"
                class="flex items-center gap-3 w-full text-start rounded-md p-3 hover:bg-zinc-200 dark:hover:bg-zinc-700"
              >
                <span class="text-sm rtl:rotate-180"
                  ><Icon icon="mingcute:arrow-right-fill"
                /></span>
                Add Category
              </router-link>
            </template>
          </menu-accordion>
        </div>
        <div class="item mt-3">
          <menu-accordion>
            <template v-slot:icon>
              <Icon icon="ic:outline-email" />
            </template>
            <template v-slot:title> Contact </template>
            <template v-slot:content>
              <router-link
                to="/contact/requests"
                class="flex items-center gap-3 w-full text-start rounded-md p-3 hover:bg-zinc-200 dark:hover:bg-zinc-700"
              >
                <span class="text-sm rtl:rotate-180"
                  ><Icon icon="mingcute:arrow-right-fill"
                /></span>
                Requests List
              </router-link>
            </template>
          </menu-accordion>
        </div>
        <!-- <div class="item mt-3">
          <menu-accordion>
            <template v-slot:icon>
              <Icon icon="icon-park-outline:page" />
            </template>
            <template v-slot:title> Pages </template>
            <template v-slot:content>
              <router-link
                to="/aboutus"
                class="flex items-center gap-3 w-full text-start rounded-md p-3 hover:bg-zinc-200 dark:hover:bg-zinc-700"
              >
                <span class="text-sm rtl:rotate-180"
                  ><Icon icon="mingcute:arrow-right-fill"
                /></span>
                About Us
              </router-link>
            </template>
          </menu-accordion>
        </div> -->
      </div>
    </div>
  </nav>
</template>

<script>
import { Icon } from "@iconify/vue";
import MenuAccordion from "./MenuAccordion.vue";
export default {
  components: {
    Icon,
    MenuAccordion,
  },
  methods: {
    closeMenuOnOutside() {
      this.$emit("closeMenuOnOutside");
    },
  },
};
</script>
