// this configuration use for theme dark and light for tailwindcss
// you can use this for personal project or others without libs download

export const setDarkMode = (toggle) => {
  if (localStorage.getItem("theme-color")) {
    if (toggle) {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme-color", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme-color", "light");
    }
    // if NOT set via local storage previously
  } else {
    if (document.documentElement.classList.contains("light")) {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme-color", "light");
    } else {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme-color", "dark");
    }
  }
};

export const loadDarkMode = () => {
  const isDark = localStorage.getItem("theme-color") == "dark" ? true : false;

  if (localStorage.getItem("theme-color")) {
    if (localStorage.getItem("theme-color") === "dark") {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme-color", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme-color", "light");
    }
    // if NOT set via local storage previously
  } else {
    if (document.documentElement.classList.contains("light")) {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme-color", "light");
    } else {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme-color", "dark");
    }
  }

  return isDark;
};
