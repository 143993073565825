<template>
  <!-- App -->
  <div class="flex bg-zinc-50 dark:bg-zinc-950">
    <div
      v-if="!$route.meta.hideNav"
      class="lg:block"
      :class="{ hidden: !sidebar, block: sidebar }"
    >
      <div
        class="lg:flex-auto xl:w-sidebar xs:w-[300px] w-[270px] bg-white dark:bg-zinc-900 overflow-hidden border-e-2 dark:border-zinc-700 lg:z-0 z-20 lg:relative fixed"
      >
        <div class="h-full-screen">
          <Sidebar
            v-if="!$route.meta.hideNav"
            @closeMenuOnOutside="closeSidebar"
            @sidebarToggle="closeSidebar"
          />
        </div>
      </div>
    </div>

    <div class="flex flex-col w-full h-full">
      <Header v-if="!$route.meta.hideNav" @sidebarToggle="openSidebar" />
      <main
        class="main-content flex-auto w-full overflow-x-hidden overflow-y-auto transition-colors"
        :class="[
          !$route.meta.hideNav ? 'h-[calc(var(--doc-height)-66px)]' : 'h-full-screen',
          $route.meta.loginPage
            ? `bg-[url('@/assets/img/black-and-white.jpg')] bg-cover`
            : ``,
        ]"
      >
        <router-view v-slot="{ Component }">
          <transition name="fade" mode="out-in">
            <component :is="Component" />
          </transition>
        </router-view>
      </main>
    </div>

    <Toast toastId="myToast" />
  </div>
  <!-- End app -->
</template>

<script>
// Vue components
import Sidebar from "@/components/Sidebar";
import Header from "@/components/Header";

import Toast from "@/components/Toast.vue";

import fullScreenHeight from "@/mixins/full-screen-height";

export default {
  name: "App",

  data() {
    return {
      sidebarDark: false,
      sidebar: false,
    };
  },
  mixins: [fullScreenHeight],
  components: {
    Header,
    Toast,
    Sidebar,
  },
  methods: {
    openSidebar() {
      this.sidebar = true;
    },
    closeSidebar() {
      this.sidebar = false;
    },
  },
  watch: {
    $route() {
      this.sidebar = false;
    },
  },
};
</script>

<style lang="scss">
.main-content {
  /* *Scroll Bar */
  /* width */
  &::-webkit-scrollbar {
    width: 7px;
    height: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #2563eb;
    border-radius: 10px;
  }

  scrollbar-width: 9px;
  scrollbar-color: #2563eb transparent;
}

/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-up-enter-active {
  transition: all 0.3s ease-out;
}

.slide-up-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-up-enter-from,
.slide-up-leave-to {
  transform: translateY(20px);
  opacity: 0;
}

/* //////////////////////// */

.slide-down-enter-active {
  transition: all 0.3s ease;
}

.slide-down-leave-active {
  transition: all 0.3s ease;
}

.slide-down-enter-from,
.slide-down-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
/* //////////////////////// */

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
