const appSettings = {

    // backendBaseUrl: "http://localhost:10303",
    backendBaseUrl: "https://api.Arcticskymedia.ca",

    features: {
        lightBox: false,
    }
}

export default appSettings;