import { createRouter, createWebHistory } from "vue-router";

// Default Pages
import Home from "../views/Home.vue";

// Login Page
import Login from "../views/Login.vue";
import ChangePassword from "../views/ChangePassword.vue";

// Categories Pages
import CategoriesList from "../views/categories/CategoriesList.vue";
import AddCategory from "../views/categories/AddCategory.vue";
import EditCategory from "../views/categories/EditCategory.vue";

// Overall Pages
import OverallPackage from "../views/packages/overall/OverallPackage.vue";
import MorePackage from "../views/packages/overall/More.vue";
import AddMore from "../views/packages/overall/AddMore.vue";
import EditMore from "../views/packages/overall/EditMore.vue"
import AddOverallPackage from "../views/packages/overall/AddOverallPackage.vue"
import EditOverallPackage from "../views/packages/overall/EditOverallPackage.vue"

// Packages Price Pages
import PackagesPrice from "../views/packages/packagesPrice/PackagesPrice.vue";
import MoreServices from "../views/packages/packagesPrice/MoreServices.vue";
import AddPackagePrice from "../views/packages/packagesPrice/AddPackagePrice.vue"
import EditPackagePrice from "../views/packages/packagesPrice/EditPackagePrice.vue"

// Projects Pages
import AddProject from "../views/packages/projects/AddProject.vue"
import EditProject from "../views/packages/projects/EditProject.vue"

// Contact Page
import RequestsList from "../views/contact/RequestsList.vue";

// layouts
import Blank from "../views/layouts/Blank.vue";

// error page
import Page404 from "../views/layouts/error/404.vue";
import Page500 from "../views/layouts/error/500.vue";


// AboutUs
// import AboutUs from "../views/AboutUs.vue";

var appname = " | Dashboard";

import { useUserSignData } from "@/store/userSignData";

const routes = [
  // Routes
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { title: "Home " + appname, homePage: true },
  },

  // Categories Routes
  {
    path: "/categories",
    name: "CategoriesList",
    component: CategoriesList,
    meta: { title: "Categories" + appname },
  },
  {
    path: "/categories/add",
    name: "AddCategory",
    component: AddCategory,
    meta: { title: "Add Category" + appname },
  },
  {
    path: "/categories/:id/edit",
    name: "EditCategory",
    component: EditCategory,
    meta: { title: "Edit Category" + appname },
  },

  // Overall Routes
  {
    path: "/packages/overall",
    name: "OverallPackage",
    component: OverallPackage,
    meta: { title: "Overall Package" + appname },
  },
  {
    path: "/packages/overall/more",
    name: "MorePackage",
    component: MorePackage,
    meta: { title: "More Package" + appname },
  },
  {
    path: "/packages/overall/more/add",
    name: "AddMore",
    component: AddMore,
    meta: { title: "Add More Package" + appname },
  },
  {
    path: "/packages/overall/more/:id/edit",
    name: "EditMore",
    component: EditMore,
    meta: { title: "Edit More Package" + appname },
  },
  {
    path: "/packages/overall/add",
    name: "AddOverallPackage",
    component: AddOverallPackage,
    meta: { title: "Add Overall Package" + appname },
  },
  {
    path: "/packages/overall/:id/edit",
    name: "EditOverallPackage",
    component: EditOverallPackage,
    meta: { title: "Edit Overall Package" + appname },
  },

  // Packages Price Routes
  {
    path: "/packages/packages-price",
    name: "PackagesPrice",
    component: PackagesPrice,
    meta: { title: "Packages Price" + appname },
  },
  {
    path: "/packages/packages-price/more-services",
    name: "MoreServices",
    component: MoreServices,
    meta: { title: "More Services" + appname },
  },
  {
    path: "/packages/packages-price/add",
    name: "AddPackagePrice",
    component: AddPackagePrice,
    meta: { title: "Add Package Price" + appname },
  },
  {
    path: "/packages/packages-price/:id/edit",
    name: "EditPackagePrice",
    component: EditPackagePrice,
    meta: { title: "Edit Package Price" + appname },
  },

  // Projects Routes
  {
    path: "/packages/packages-price/projects/add",
    name: "AddProject",
    component: AddProject,
    meta: { title: "Add Project" + appname },
  },
  {
    path: "/packages/packages-price/projects/:slug/edit",
    name: "EditProject",
    component: EditProject,
    meta: { title: "Edit Project" + appname },
  },

  // Contact Route
  {
    path: "/contact/requests",
    name: "RequestsList",
    component: RequestsList,
    meta: { title: "Request List" + appname },
  },

  // layouts
  {
    path: "/Blank",
    name: "Blank Page",
    component: Blank,
    meta: { title: "Blank Page" + appname },
  },

  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { title: "Login" + appname, hideNav: true, loginPage: true },
  },

  {
    path: "/password",
    name: "ChangePassword",
    component: ChangePassword,
    meta: { title: "Change Password" + appname },
  },

  // about us
  // {
  //   path: "/aboutus",
  //   name: "About us",
  //   component: AboutUs,
  //   meta: { title: "AboutUs" + appname },
  // },

  // layout/error
  // default page 404
  {
    path: "/:pathMatch(.*)*",
    name: "Page404",
    component: Page404,
    meta: { title: "Upps! 404" + appname, hideNav: true },
  },
  {
    path: "/500",
    name: "Page500",
    component: Page500,
    meta: { title: "Server internal Error" + appname, hideNav: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,

  linkExactActiveClass: "exact-active",
});

router.beforeEach((to, from, next) => {
  const store = useUserSignData();

  console.log(store);

  document.title = to.meta.title;

  if (!store.isAuthenticated && to.path !== "/login") next("/login");
  else next();
});

export default router;
