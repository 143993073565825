<template>
  <div class="h-auto p-3 pb-10">
    <nav class="flex flex-col" aria-label="Breadcrumb">
      <ol class="inline-flex flex-wrap items-center">
        <li class="inline-flex items-center">
          <router-link
            to="/"
            class="inline-flex items-center sm:text-sm text-xs font-medium text-zinc-700 hover:text-zinc-950 dark:text-zinc-400 dark:hover:text-white"
          >
            <svg
              class="me-2 w-4 h-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
              ></path>
            </svg>
            Home
          </router-link>
        </li>
        <li>
          <div class="flex items-center">
            <svg
              class="w-6 h-6 text-zinc-400 rtl:rotate-180"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <router-link
              to="/categories"
              class="sm:text-sm text-xs font-medium text-zinc-700 hover:text-zinc-950 dark:text-zinc-400 dark:hover:text-white"
              >Categories List</router-link
            >
          </div>
        </li>
        <li>
          <div class="flex items-center">
            <svg
              class="w-6 h-6 text-zinc-400 rtl:rotate-180"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <router-link
              :to="`/packages/overall?category-id=${category.id}&is-category-exist=true&category-title=${category.title}`"
              class="sm:text-sm text-xs font-medium text-zinc-700 hover:text-zinc-950 dark:text-zinc-400 dark:hover:text-white"
              >Overall Package</router-link
            >
          </div>
        </li>
        <li>
          <div class="flex items-center">
            <svg
              class="w-6 h-6 text-zinc-400 rtl:rotate-180"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span
              class="sm:text-sm text-xs font-medium text-zinc-700 dark:text-zinc-400"
              >Add Overall Package</span
            >
          </div>
        </li>
      </ol>

      <div class="mt-5 w-full">
        <h1
          class="md:text-lg text-base text-zinc-950 font-medium dark:text-zinc-200"
        >
          Add Overall Package | {{ category.title }}
        </h1>
      </div>
    </nav>

    <div
      class="bg-white dark:bg-zinc-900 rounded-md p-5 w-full box-border border dark:border-zinc-700 mt-5"
    >
      <form @submit.prevent="addOverallPackage">
        <div class="informations flex flex-col gap-6 mb-5">
          <div class="flex flex-col gap-5">
            <div class="flex flex-col gap-2">
              <div class="flex flex-col gap-2 mb-2">
                <h3
                  class="md:text-lg text-base text-secondary dark:text-white font-medium"
                >
                  Header Informations
                </h3>
                <hr
                  class="border-none w-full h-[2px] bg-zinc-200 dark:bg-zinc-600"
                />
              </div>

              <Input
                class="w-full required"
                type="text"
                placeholder="Header Title"
                v-model="addOverallPackageViewModel.informations.title"
                :isRequired="true"
              ></Input>

              <Input
                type="textarea"
                textareaRows="3"
                placeholder="Header Description"
                v-model="addOverallPackageViewModel.informations.description"
                :isRequired="true"
                class="required"
              ></Input>

              <div class="flex flex-col gap-2 w-full required">
                <h6
                  class="font-medium text-sm text-zinc-600 dark:text-zinc-400"
                >
                  Header Image
                </h6>
                <FileUploader
                  @updatePendingFiles="
                    updatePendingFiles($event, 'headerImage')
                  "
                ></FileUploader>
              </div>
            </div>

            <div class="flex flex-col gap-2">
              <div class="flex flex-col gap-2 mb-2">
                <h3
                  class="md:text-lg text-base text-secondary dark:text-white font-medium"
                >
                  Sub Header Informations
                </h3>
                <hr
                  class="border-none w-full h-[2px] bg-zinc-200 dark:bg-zinc-600"
                />
              </div>

              <Input
                class="w-full"
                type="text"
                placeholder="Sub Title"
                v-model="addOverallPackageViewModel.informations.subTitle"
                :isRequired="false"
              ></Input>

              <Input
                type="textarea"
                textareaRows="3"
                placeholder="Sub Description"
                v-model="addOverallPackageViewModel.informations.subDescription"
                :isRequired="false"
              ></Input>
            </div>

            <div class="flex flex-col gap-2">
              <div class="flex flex-col gap-2 mb-2">
                <div class="required">
                  <h6
                    class="md:text-lg text-base text-secondary dark:text-white font-medium"
                  >
                    Overall Package Boxes
                  </h6>
                </div>
                <hr
                  class="border-none w-full h-[2px] bg-zinc-200 dark:bg-zinc-600"
                />
              </div>

              <div
                class="grid min-[2100px]:grid-cols-4 min-[1600px]:grid-cols-3 min-[1150px]:grid-cols-3 lg:grid-cols-2 min-[950px]:grid-cols-3 min-[600px]:grid-cols-2 gap-5"
              >
                <PackageBox
                  v-for="(
                    box, index
                  ) in addOverallPackageViewModel.boxesInformation"
                  mode="editable"
                >
                  <template v-slot:title>
                    <div
                      class="flex flex-col items-center justify-center gap-5"
                    >
                      <div
                        v-if="
                          addOverallPackageViewModel.boxesInformation.length > 1
                        "
                        class="absolute top-2 end-2"
                      >
                        <button
                          type="button"
                          class="text-red-500 w-6 h-6 inline-flex items-center justify-center p-1 border border-red-500 rounded-full"
                          @click="deleteCurrentPackageBox(box.id)"
                        >
                          <Icon icon="entypo:trash" />
                        </button>
                      </div>
                      <Input
                        :isTitleShow="false"
                        class="max-w-max required"
                        type="text"
                        placeholder="Title"
                        v-model="box.title"
                        :isRequired="true"
                        outer-input-class="!rounded-xl sm:!text-lg !text-base"
                      ></Input>
                      <div class="flex items-center gap-2">
                        <input
                          :id="`isMore${index}`"
                          v-model="box.isMore"
                          class="custom-checkbox"
                          type="checkbox"
                          @change="morePackageChange($event, box)"
                        />

                        <label
                          class="inline-block text-sm text-black dark:text-white hover:cursor-pointer"
                          :for="`isMore${index}`"
                        >
                          Type: More Package
                        </label>
                      </div>
                    </div>
                  </template>
                  <template v-slot:more>
                    <Input
                      :isTitleShow="false"
                      type="textarea"
                      textareaRows="6"
                      placeholder="Description"
                      v-model="box.description"
                      :isRequired="true"
                      class="required"
                      input-class="dark:!bg-zinc-900"
                      outer-input-class="!rounded-xl sm:!text-sm !text-xs dark:!border-zinc-800"
                    ></Input>
                  </template>
                </PackageBox>

                <div
                  class="w-full h-full p-1 rounded-2xl border-2 border-zinc-300/60 dark:border-zinc-800/60 dark:text-white/60 rounded-2xl/60 bg-zinc-200/60 dark:bg-zinc-950/60"
                >
                  <div
                    class="w-full h-full relative bg-white/60 dark:bg-zinc-800/60 px-4 py-16 rounded-xl flex justify-center items-center"
                  >
                    <button
                      @click="addNewPackageBox"
                      type="button"
                      class="text-white h-fit text-4xl bg-primary rounded-md hover:bg-primary/80 transition-all duration-300 focus:outline-none py-2 px-4 w-fit disabled:opacity-60 disabled:pointer-events-none"
                    >
                      <Icon icon="mdi:package-variant-closed-add" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr class="w-full border dark:border-zinc-700" />
        <div class="wrapper-button sm:text-base text-sm flex justify-end mt-5">
          <button
            v-if="!isAdding"
            :disabled="
              addOverallPackageViewModel.informations.title?.length == 0 ||
              addOverallPackageViewModel.informations.description?.length == 0
            "
            type="submit"
            class="text-white bg-primary rounded-md hover:bg-primary/80 transition-all duration-300 focus:outline-none py-2 px-6 w-fit disabled:opacity-60 disabled:pointer-events-none"
          >
            Save
          </button>

          <button
            v-else
            disabled
            type="button"
            class="text-white bg-primary rounded-md opacity-60 cursor-default transition-all duration-300 focus:outline-none py-2 px-6 w-fit"
          >
            <svg
              role="status"
              class="inline w-4 h-4 me-1 text-white animate-spin"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="#E5E7EB"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentColor"
              />
            </svg>
            Uploading...
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";
import axios from "axios";
import Input from "@/components/Input.vue";
import FilterBox from "@/components/FilterBox.vue";
import FileUploader from "@/components/FileUploader.vue";

import PackageBox from "@/components/OverallPackage/PackageBox";

export default {
  components: {
    Icon,
    FileUploader,
    Input,
    PackageBox,
    FilterBox,
  },

  data() {
    return {
      addOverallPackageViewModel: {
        id: null,
        informations: {
          title: "",
          categoryId: "",
          description: "",
          headerImage: null,
          subTitle: "",
          subDescription: "",
        },
        boxesInformation: [
          {
            id: 1,
            title: "",
            description: "",
            isMore: false,
          },
        ],
      },
      category: {
        id: null,
        title: "",
      },
      isAdding: false,
    };
  },

  async created() {
    let categoryId = this.$route.query["category-id"];
    let categoryTitle = this.$route.query["category-title"];

    this.addOverallPackageViewModel.informations.categoryId = categoryId;
    this.category.title = categoryTitle;
    this.category.id = categoryId;
  },

  methods: {
    updatePendingFiles: function (files, imageType, sliderIndex = 0) {
      if (files) {
        if (imageType == "headerImage") {
          this.addOverallPackageViewModel.informations.headerImage =
            files[0]?.file;
        }
      }
    },

    async addOverallPackage() {
      let overallPackageInformations =
        this.addOverallPackageViewModel.informations;
      let overallPackageBoxesInformation =
        this.addOverallPackageViewModel.boxesInformation;

      if (!overallPackageInformations.headerImage) {
        this.$store.commit("showToast", {
          id: "myToast",
          type: "error",
          title: "Header Image Is Required!",
          body: "You must choice any image for header image!",
        });
        return;
      }

      this.isAdding = true;

      try {
        let overallPackageInformationsResponse =
          await this.addOverallPackageInformations(
            "OveralPackages",
            overallPackageInformations
          );

        // overallPackage package id
        this.addOverallPackageViewModel.id =
          overallPackageInformationsResponse.data.value.id;

        await this.addOverallPackageBox(
          "OveralPackages/box",
          overallPackageBoxesInformation,
          this.addOverallPackageViewModel.id
        );

        this.$store.commit("showToast", {
          id: "myToast",
          type: "success",
          title: "Success!",
          body: "Your desired overallPackage package has been successfully added!",
        });

        this.$router.push(
          `/packages/overall?category-id=${this.category.id}&is-category-exist=true&category-title=${this.category.title}`
        );
      } catch (error) {
        if (error.response?.data?.isSuccess == false) {
          this.$store.commit("showToast", {
            id: "myToast",
            type: "error",
            title: "Error!",
            body: error.response?.data?.messages[0],
          });
        } else {
          this.$store.commit("showToast", {
            id: "myToast",
            type: "error",
            title: "Error!",
            body: "Unfortunately, an unknown error has occurred! Please contact the website support!",
          });
        }

        console.log(error);
        console.log(error.response);

        this.$router.push(
          `/packages/overall?category-id=${this.category.id}&is-category-exist=true&category-title=${this.category.title}`
        );
      }
    },

    async addOverallPackageInformations(url, informationsViewModel) {
      let bodyFormData = new FormData();

      bodyFormData.append("CategoryId", informationsViewModel.categoryId);

      bodyFormData.append("Title", informationsViewModel.title);

      bodyFormData.append("Description", informationsViewModel.description);

      bodyFormData.append("HeaderImage", informationsViewModel.headerImage);

      if (informationsViewModel.subTitle)
        bodyFormData.append("SubTitle", informationsViewModel.subTitle);

      if (informationsViewModel.subDescription)
        bodyFormData.append(
          "SubDescription",
          informationsViewModel.subDescription
        );

      bodyFormData.append("OptimizeImage.Quality", 85);
      bodyFormData.append("OptimizeImage.ImageExtensions", "webp");

      let options = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        isNeedJwtAuth: true,
      };

      return await axios.post(url, bodyFormData, options);
    },

    async addOverallPackageBox(url, boxesInformation, packageId) {
      for (let index = 0; index < boxesInformation.length; index++) {
        const box = boxesInformation[index];
        let bodyFormData = new FormData();

        bodyFormData.append("Title", box.title);
        bodyFormData.append("OveralPackageId", packageId);
        bodyFormData.append("Description", box.description);
        bodyFormData.append("IsMore", box.isMore);

        let options = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          isNeedJwtAuth: true,
        };

        await axios.post(url, bodyFormData, options);
      }
    },

    addNewPackageBox() {
      let overallPackagePackageBoxId =
        this.addOverallPackageViewModel.boxesInformation.length + 1;

      const newBox = {
        id: overallPackagePackageBoxId,
        title: "",
        description: "",
        isMore: false,
      };

      this.addOverallPackageViewModel.boxesInformation.push(newBox);
    },

    morePackageChange(event, box) {
      // get alll inputs with checkbox type that contain isMore id
      this.addOverallPackageViewModel.boxesInformation.forEach(
        (current) => (current.isMore = false)
      );

      const checkboxElements = Array.from(
        document.querySelectorAll("input[type='checkbox'][id*='isMore']")
      );

      if (event.target.checked) {
        box.isMore = true;

        checkboxElements
          .filter((current) => current.id !== event.target.id)
          .forEach((el) => {
            el.checked = false;
            el.setAttribute("disabled", "");
            el.parentNode.style.opacity = 0.5;
            el.parentNode.style.pointerEvents = "none";
          });
        return;
      }

      checkboxElements.forEach((el) => {
        el.removeAttribute("disabled");
        el.parentNode.style.opacity = 1;
        el.parentNode.style.pointerEvents = "auto";
      });
    },

    deleteCurrentPackageBox(boxId) {
      // mixins: custom-functions
      this.removeItemObjectWithId(
        this.addOverallPackageViewModel.boxesInformation,
        boxId
      );
    },
  },
};
</script>
