<template>
  <div class="h-auto p-3 pb-10">
    <nav class="flex flex-col" aria-label="Breadcrumb">
      <ol class="inline-flex flex-wrap items-center">
        <li class="inline-flex items-center">
          <router-link
            to="/"
            class="inline-flex items-center sm:text-sm text-xs font-medium text-zinc-700 hover:text-zinc-950 dark:text-zinc-400 dark:hover:text-white"
          >
            <svg
              class="me-2 w-4 h-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
              ></path>
            </svg>
            Home
          </router-link>
        </li>
        <li>
          <div class="flex items-center">
            <svg
              class="w-6 h-6 text-zinc-400 rtl:rotate-180"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <router-link
              to="/categories"
              class="sm:text-sm text-xs font-medium text-zinc-700 hover:text-zinc-950 dark:text-zinc-400 dark:hover:text-white"
              >Categories List</router-link
            >
          </div>
        </li>
        <li v-if="type == 'overalPackageBox'">
          <div class="flex items-center">
            <svg
              class="w-6 h-6 text-zinc-400 rtl:rotate-180"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <router-link
              :to="`/packages/overall?category-id=${category.id}&is-category-exist=true&category-title=${category.title}`"
              class="sm:text-sm text-xs font-medium text-zinc-700 hover:text-zinc-950 dark:text-zinc-400 dark:hover:text-white"
              >Overall Package</router-link
            >
          </div>
        </li>
        <li>
          <div class="flex items-center">
            <svg
              class="w-6 h-6 text-zinc-400 rtl:rotate-180"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span
              class="sm:text-sm text-xs font-medium text-zinc-700 dark:text-zinc-400"
              >Packages Price</span
            >
          </div>
        </li>
      </ol>

      <div class="mt-5 w-full">
        <h1
          v-if="type == 'category'"
          class="md:text-lg text-base text-zinc-950 font-medium dark:text-zinc-200"
        >
          Packages Price | Category: {{ category.title }}
        </h1>
        <h1
          v-else
          class="md:text-lg text-base text-zinc-950 font-medium dark:text-zinc-200"
        >
          Packages Price | Overall Package Box: {{ overalPackageBox.title }}
        </h1>
      </div>
    </nav>

    <div
      class="bg-white dark:bg-zinc-900 rounded-md p-5 w-full box-border border dark:border-zinc-700 mt-5"
    >
      <div v-show="!isLoading && packagePrice" class="flex flex-col gap-3 mb-5">
        <div class="flex flex-col gap-2 mb-2">
          <div class="flex items-center justify-between gap-6">
            <h3
              class="md:text-lg text-base text-secondary dark:text-white font-medium"
            >
              Informations
            </h3>

            <div
              class="gap-4 flex justify-end items-center lg:text-2xl text-xl"
            >
              <router-link
                type="button"
                class="text-zinc-600 dark:text-zinc-400"
                :to="
                  type == 'category'
                    ? `/packages/packages-price/${packagePrice?.id}/edit?category-id=${category.id}&category-title=${category.title}`
                    : `/packages/packages-price/${packagePrice?.id}/edit?type=${type}&overal-package-box-id=${overalPackageBox.id}&overal-package-box-title=${overalPackageBox.title}&category-id=${category.id}&category-title=${category.title}`
                "
              >
                <Icon icon="emojione-monotone:fountain-pen" />
              </router-link>
              <button
                type="button"
                class="text-red-500"
                @click="
                  () =>
                    (deleteInfo = {
                      type: 'packagePrice',
                      id: packagePrice?.id,
                    })
                "
                data-te-target="#deleteModal"
                data-te-toggle="modal"
              >
                <Icon icon="entypo:trash" />
              </button>
            </div>
          </div>
          <hr class="border-none w-full h-[2px] bg-zinc-200 dark:bg-zinc-600" />
        </div>

        <!-- Package price -->
        <div class="flex flex-col items-center gap-6">
          <PageHeader
            :title="packagePrice?.title"
            :description="packagePrice?.description"
            :imgUrl="packagePrice?.headerImageUrl"
          ></PageHeader>
          <PageInformation
            v-if="packagePrice?.subTitle || packagePrice?.subDescription"
            :title="packagePrice?.subTitle"
            :description="packagePrice?.subDescription"
          ></PageInformation>
          <div
            v-if="finalPackageBoxes?.length > 0"
            class="grid w-full min-[1350px]:grid-cols-3 lg:grid-cols-2 min-[950px]:grid-cols-3 sm:grid-cols-2 gap-5"
          >
            <PackageBox
              v-for="(item, index) in finalPackageBoxes"
              :key="item.id"
              :title="item.title"
              :price="item.price"
              :isBestValue="item.isBestValue"
              :attributes="item.attributes"
              :class="item.customClass"
            >
            </PackageBox>
          </div>
          <router-link
            v-if="packagePrice?.moreServices?.length > 0"
            :to="
              type == 'category'
                ? `/packages/packages-price/more-services?package-id=${packagePrice?.id}&category-id=${category.id}&is-category-exist=true&category-title=${category.title}`
                : `/packages/packages-price/more-services?package-id=${packagePrice?.id}&type=overalPackageBox&&is-overal-package-box-exist=true&overal-package-box-id=${overalPackageBox.id}&overal-package-box-title=${overalPackageBox.title}&category-id=${category.id}&category-title=${category.title}`
            "
            class="inline-flex items-center gap-2 transition-transform duration-300 hover:scale-105 bg-blue-600 text-white shadow-normal rounded-lg font-medium py-3 px-3"
          >
            <span>More Service</span>
            <span class="border border-white p-[2px] inline-block rounded-md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-3 h-3 fill-white"
                viewBox="0 0 320 512"
              >
                <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                <path
                  d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"
                />
              </svg>
            </span>
          </router-link>
        </div>

        <!-- Projects -->
        <template v-if="type === 'category'">
          <template v-if="projects.length > 0">
            <div class="flex flex-col items-center gap-6 mt-8">
              <div class="flex flex-col gap-3 items-center w-full">
                <div class="flex items-center w-full justify-between gap-4">
                  <h2
                    class="font-medium text-lg max-sm:text-base text-secondary dark:text-white"
                  >
                    Projects list
                  </h2>

                  <router-link
                    :to="
                      type == 'category'
                        ? `/packages/packages-price/projects/add?package-price-id=${packagePrice?.id}&category-id=${category.id}&category-title=${category.title}`
                        : `/packages/packages-price/projects/add?package-price-id=${packagePrice?.id}&type=${type}&overal-package-box-id=${overalPackageBox.id}&overal-package-box-title=${overalPackageBox.title}&category-id=${category.id}&category-title=${category.title}`
                    "
                    class="inline-flex gap-[2px] items-center justify-center w-max flex-nowrap bg-primary rounded-lg text-white ps-1 p-2 text-xs"
                  >
                    <Icon class="text-base" icon="ic:baseline-plus" />
                    Project
                  </router-link>
                </div>
                <hr
                  class="border-none w-full h-[1px] bg-zinc-200 dark:bg-zinc-600"
                />
              </div>

              <div class="w-full space-y-5">
                <div class="w-full" v-for="item in projects" :key="item.id">
                  <ProjectBox
                    :id="item.id"
                    :title="item.title"
                    :gallery="item.imageFiles"
                    :content="item.previewDescription"
                  >
                    <template v-slot:more>
                      <div
                        class="absolute bottom-3 end-[10px] gap-3 flex justify-end items-center lg:text-2xl text-xl"
                      >
                        <router-link
                          type="button"
                          class="text-zinc-600 dark:text-zinc-400"
                          :to="
                            type == 'category'
                              ? `/packages/packages-price/projects/${item?.slug}/edit?package-price-id=${packagePrice?.id}&category-id=${category.id}&category-title=${category.title}`
                              : `/packages/packages-price/projects/${item?.slug}/edit?package-price-id=${packagePrice?.id}&type=${type}&overal-package-box-id=${overalPackageBox.id}&overal-package-box-title=${overalPackageBox.title}&category-id=${category.id}&category-title=${category.title}`
                          "
                        >
                          <Icon icon="emojione-monotone:fountain-pen" />
                        </router-link>
                        <button
                          type="button"
                          class="text-red-500"
                          @click="
                            () =>
                              (deleteInfo = {
                                type: 'project',
                                id: item?.id,
                              })
                          "
                          data-te-target="#deleteModal"
                          data-te-toggle="modal"
                        >
                          <Icon icon="entypo:trash" />
                        </button>
                      </div>
                    </template>
                  </ProjectBox>
                </div>
              </div>
            </div>
            <ShowMoreButton
              @showMore="showMore"
              v-if="pagination?.hasNext"
              class="mt-6 mb-10"
              text="Show More"
            ></ShowMoreButton>
          </template>

          <div
            class="w-full lg:max-w-7xl sm:mt-20 mt-14 mb-8 flex items-center justify-center text-center"
            v-if="projects.length == 0"
          >
            <div class="space-y-5 px-4">
              <div class="w-full flex justify-center items-center">
                <Icon
                  class="sm:text-9xl text-8xl dark:text-white text-zinc-900 font-bold"
                  icon="pajamas:project"
                />
              </div>
              <h2
                class="md:text-2xl sm:text-xl xs:text-lg font-medium max-w-md mx-auto dark:text-white text-zinc-900"
              >
                Projects list is empty.
              </h2>
              <router-link
                :to="
                  type == 'category'
                    ? `/packages/packages-price/projects/add?package-price-id=${packagePrice?.id}&category-id=${category.id}&category-title=${category.title}`
                    : `/packages/packages-price/projects/add?package-price-id=${packagePrice?.id}&type=${type}&overal-package-box-id=${overalPackageBox.id}&overal-package-box-title=${overalPackageBox.title}&category-id=${category.id}&category-title=${category.title}`
                "
                class="inline-flex gap-1 items-center justify-center w-max flex-nowrap bg-primary rounded-lg text-white p-3 text-sm"
              >
                <Icon class="text-base" icon="ic:baseline-plus" />
                New Project
              </router-link>
            </div>
          </div>
        </template>

        <template v-if="type === 'overalPackageBox'">
          <div
            v-if="packagePrice?.galleryFiles?.length > 0"
            class="flex slider-gallery mt-3 flex-col gap-5 w-full"
          >
            <h4
              class="font-bold text-black dark:text-zinc-100 xl:text-2xl text-xl max-[522px]:text-center"
            >
              Gallery
            </h4>
            <div
              class="w-full grid min-[1101px]:grid-cols-3 lg:grid-cols-2 md:grid-cols-3 min-[523px]:grid-cols-2 gap-3"
            >
              <div
                v-for="(url, index) in packagePrice?.galleryFiles"
                :key="`slideGallery${index}`"
              >
                <div
                  class="w-full rounded-xl overflow-hidden aspect-w-16 aspect-h-9"
                >
                  <img
                    v-if="!isVideo(url)"
                    class="w-full h-full object-cover object-center"
                    :src="url"
                    :data-te-img="url"
                    alt="Gallery Images"
                  />
                  <template v-if="isVideo(url)">
                    <VideoPlayer
                      name="sliderGalleryPlayerCustom"
                      :videoId="`sliderGalleryPlayer${index}`"
                      custom-class="w-full h-full"
                      :source="url"
                      :videoType="getVideoType(url)"
                      poster="/black-poster.jpg"
                    >
                    </VideoPlayer>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="packagePrice?.reels?.length > 0"
            class="flex reels-gallery mt-8 flex-col gap-5 w-full max-[522px]:max-w-[300px] max-[522px]:mx-auto"
          >
            <h4
              class="font-bold text-black dark:text-zinc-100 xl:text-2xl text-xl max-[522px]:text-center"
            >
              Reels
            </h4>
            <div
              class="w-full grid 2xl:grid-cols-4 min-[1101px]:grid-cols-3 lg:grid-cols-2 md:grid-cols-3 min-[523px]:grid-cols-2 gap-3"
            >
              <div
                v-for="(reels, index) in packagePrice?.reels"
                :key="`reels${index}`"
              >
                <div
                  class="w-full h-full rounded-xl overflow-hidden aspect-w-9 aspect-h-16"
                >
                  <template v-if="isVideo(reels.videoUrl)">
                    <VideoPlayer
                      name="reelsPlayerCustom"
                      custom-class="w-full h-full"
                      :source="reels.videoUrl"
                      :videoId="`reelsPlayer${reels.id}`"
                      :videoType="getVideoType(reels.videoUrl)"
                      :poster="reels.posterUrl"
                    >
                    </VideoPlayer>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>

      <div
        class="w-full lg:max-w-7xl flex items-center justify-center text-center"
        v-if="!isLoading && !packagePrice"
      >
        <div class="space-y-5 px-4 py-10">
          <div class="w-full flex justify-center items-center">
            <Icon
              class="sm:text-[9rem] text-9xl dark:text-white text-zinc-900 font-bold"
              icon="raphael:package"
            />
          </div>
          <h2
            class="md:text-2xl sm:text-xl xs:text-lg font-medium max-w-md mx-auto dark:text-white text-zinc-900"
          >
            The desired package does not exist.
          </h2>
          <p
            class="dark:text-zinc-400 max-w-2xl mx-auto max-sm:text-sm text-zinc-900"
          >
            Your desired package was not found! Please add a new package.
          </p>
          <router-link
            :to="
              type == 'category'
                ? `/packages/packages-price/add?category-id=${category.id}&category-title=${category.title}`
                : `/packages/packages-price/add?type=${type}&overal-package-box-id=${
                    overalPackageBox.id
                  }&overal-package-box-title=${
                    overalPackageBox.title
                  }&category-id=${category.id}&category-title=${
                    category.title
                  }${overalPackageBox.isMore ? '&is-more=true' : ''}`
            "
            class="inline-flex gap-1 items-center justify-center w-max flex-nowrap bg-primary rounded-lg text-white p-3 text-sm"
          >
            <Icon class="text-base" icon="ic:baseline-plus" />
            New Package
          </router-link>
        </div>
      </div>

      <div
        class="flex justify-center items-center w-full h-full py-10"
        v-if="isLoading"
      >
        <LoadingSpinner class="text-primary dark:text-primary" />
      </div>
    </div>

    <DeleteModal
      mode="normal"
      :info="deleteInfo"
      @deleteSubmit="deleteSubmit"
    />
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";
import axios from "axios";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import settings from "@/settings/appSettings";
import DeleteModal from "@/components/DeleteModal.vue";

import PackageBox from "@/components/PackagePrice/PackageBox";
import ProjectBox from "@/components/PackagePrice/Projects/ProjectBox";

import PageHeader from "@/components/PageHeader.vue";
import PageInformation from "@/components/PageInformation.vue";
import ServiceBox from "@/components/PackagePrice/ServiceBox.vue";

import ShowMoreButton from "@/components/ShowMoreButton.vue";

import { getPackagePrice } from "@/components/PackagePrice/modules/packagePrice";

import VideoPlayer from "@/components/VideoPlayer.vue";
import { videoPlayerPoster, videoTypeCheck } from "@/mixins/videoPlayer";

export default {
  mixins: [videoPlayerPoster, videoTypeCheck],
  components: {
    Icon,
    LoadingSpinner,
    PackageBox,
    ProjectBox,
    DeleteModal,
    ShowMoreButton,
    PageHeader,
    PageInformation,
    ServiceBox,
    VideoPlayer,
  },

  data() {
    return {
      packagePrice: null,
      projects: [],
      pagination: {},
      isLoading: true,
      deleteInfo: null,

      category: {
        id: null,
        title: "",
      },
      overalPackageBox: {
        id: null,
        title: "",
        isMore: false,
      },
      type: "",
      finalPackageBoxes: [],
    };
  },
  async created() {
    this.type = this.$route.query["type"] ?? "category";

    // When the package price is called by category
    let categoryId = this.$route.query["category-id"];
    let categoryTitle = this.$route.query["category-title"];
    let isCategoryExist = this.$route.query["is-category-exist"];

    this.category.title = categoryTitle;
    this.category.id = categoryId;

    // When the package price is called by overal package box
    let overalPackageBoxId = this.$route.query["overal-package-box-id"];
    let overalPackageBoxTitle = this.$route.query["overal-package-box-title"];
    let isOveralPackageBoxExist =
      this.$route.query["is-overal-package-box-exist"];

    this.overalPackageBox.title = overalPackageBoxTitle;
    this.overalPackageBox.id = overalPackageBoxId;

    if (
      this.type == "category" &&
      (!isCategoryExist || isCategoryExist == "false")
    ) {
      this.$router.push("/404");
      return;
    }
    if (
      this.type == "overalPackageBox" &&
      (!isOveralPackageBoxExist || isOveralPackageBoxExist == "false")
    ) {
      this.$router.push("/404");
      return;
    }

    let { isLoading, packagePrice } = await getPackagePrice(
      this.type == "category" ? categoryId : overalPackageBoxId,
      this.type
    );

    if (packagePrice?.moreServices) {
      packagePrice.moreServices = this.groupBy(
        packagePrice.moreServices,
        "category"
      );
    }

    this.packagePrice = packagePrice;

    if (this.packagePrice && this.type == "category") {
      let projectListIsLoading = await this.getProjectsList(
        this.packagePrice.id
      );

      if (!isLoading && !projectListIsLoading) {
        this.isLoading = false;
      }
    }

    setTimeout(() => {
      this.addOverlayReels(
        '[name="reelsPlayerCustom"]',
        "reels-poster-overlay"
      );

      // from videoPlayerPoster mixin
      this.addOverlay(
        '[name="sliderGalleryPlayerCustom"]',
        "slidergallery-poster-overlay"
      );
    }, 100);

    this.getPackagePriceBoxesByOrders()

    this.isLoading = false;
  },

  methods: {
    deleteSubmit(e) {
      if (e.type == "packagePrice") {
        this.deletePackagePrice(e.id);
      }
      if (e.type == "project") {
        this.deleteProject(e.id);
      }
    },
    async getProjectsList(id, currentPage = null) {
      let isLoading = false;
      if (!currentPage) {
        isLoading = true;
        this.projects = [];
      }

      let url = `/Projects?filter=packagePriceId=${id}&orderby=createdDateRaw desc`;

      url += `&PageSize=5`;

      if (currentPage) {
        url += `&Page=${currentPage}`;
      }

      try {
        let httpResponse = await axios.get(url);

        console.log(httpResponse);

        let projects = httpResponse.data.value?.data;

        projects?.forEach((project) => {
          let imageFiles = [];
          // Set baseUrl before image urls
          project?.imageFiles?.forEach((url) => {
            // Set baseUrl before image urls
            imageFiles.push(`${settings.backendBaseUrl}/${url}`);
          });

          project.imageFiles = imageFiles;
        });

        if (currentPage) {
          this.projects = this.projects.concat(projects);
        } else {
          this.projects = projects;
        }

        if (httpResponse.headers["x-pagination"]) {
          this.pagination = JSON.parse(httpResponse.headers["x-pagination"]);
        }

        console.log("finish");

        isLoading = false;
      } catch (error) {
        isLoading = false;
        console.log(error);
      }

      return isLoading;
    },
    async showMore() {
      await this.getProjectsList(
        this.packagePrice.id,
        ++this.pagination.currentPage
      );
    },
    async deletePackagePrice(itemId) {
      this.isLoading = true;

      await axios
        .delete(`PackagePrices/${itemId}`, { isNeedJwtAuth: true })
        .then(async (response) => {
          this.packagePrice = null;
          this.$store.commit("showToast", {
            id: "myToast",
            type: "success",
            title: "Success!",
            body: "Your desired package has been successfully deleted!",
          });

          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response?.data?.isSuccess == false) {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Error!",
              body: error.response?.data?.messages[0],
            });
          } else {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Error!",
              body: "Unfortunately, an unknown error has occurred! Please contact the website support!",
            });
          }

          this.isLoading = false;
          console.log(error);
          console.log(error.response);
        });
    },
    async deleteProject(itemId) {
      this.isLoading = true;

      await axios
        .delete(`Projects/${itemId}`, { isNeedJwtAuth: true })
        .then(async (response) => {
          // mixins: custom-functions
          this.removeItemObjectWithId(this.projects, itemId);

          this.$store.commit("showToast", {
            id: "myToast",
            type: "success",
            title: "Success!",
            body: "Your desired project has been successfully deleted!",
          });

          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response?.data?.isSuccess == false) {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Error!",
              body: error.response?.data?.messages[0],
            });
          } else {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Error!",
              body: "Unfortunately, an unknown error has occurred! Please contact the website support!",
            });
          }

          this.isLoading = false;
          console.log(error);
          console.log(error.response);
        });
    },
    addOverlayReels(videoPlayerIDSelector, posterOverlayClass) {
      const videoPlayerEls = document.querySelectorAll(videoPlayerIDSelector);

      for (const playerEl of videoPlayerEls) {
        const posterSelector = `#${playerEl.id} .vjs-poster`;

        const posterEl = document.querySelector(posterSelector);
        posterEl.innerHTML += `<div class="vjs-poster-overlay"></div>`;

        const posterOverlayEl = document.querySelector(
          `${posterSelector} .vjs-poster-overlay`
        );
        posterOverlayEl.classList.add(posterOverlayClass);

        posterOverlayEl.innerHTML += `<div class="overlay-content"><p></p></div>`;

        const posterCaptionEl = document.querySelector(
          `${posterSelector} .${posterOverlayClass} p`
        );

        let posterElId = playerEl.id.replace("reelsPlayer", "");

        let reelsId = this.packagePrice?.reels.filter(
          (current) => current.id == posterElId
        );

        posterCaptionEl.innerText = reelsId[0].description;
      }
    },
    getPackagePriceBoxesByOrders() {
      const packageBoxes = this.packagePrice?.packagePriceBoxes
      const bestValue = packageBoxes?.find((current) => current.isBestValue);
      
      if (!bestValue) {
        this.finalPackageBoxes = packageBoxes;
        return;
      }

      bestValue.customClass = "order-2";

      const others = packageBoxes.filter((current) => !current.isBestValue);
      
      others.forEach((current, index) => {
        index === 0
          ? (current.customClass = "order-1")
          : (current.customClass = "order-3");
      });

      this.finalPackageBoxes = [bestValue, ...others];
    },
  },
};
</script>
<style lang="scss">
.slider-gallery {
  .slidergallery-poster-overlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  .vjs-custom {
    @media screen and (max-width: 460px) {
      .vjs-big-play-button {
        width: 60px;
        height: 60px;

        span {
          display: flex;
          color: #e1cbef;
          transform: scale(1.2);
        }
      }
    }
  }
}
.reels-gallery {
  .reels-poster-overlay {
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, transparent 30%, #1b012d);
    display: flex;
    align-items: flex-end;
    position: relative;

    .overlay-content {
      padding: 0 15px 0 15px;
      position: relative;
      user-select: contain;
      pointer-events: stroke;
      position: relative;

      p {
        font-size: 18px;
        line-height: 28px;
        font-family: "kalameh", sans-serif;
        font-weight: 20;
        height: 100%;
        max-height: 120px;
        overflow-y: auto;
        padding-bottom: 35px;
        &::-webkit-scrollbar {
          display: none;
        }

        scrollbar-width: none;
      }

      &::after {
        z-index: 12;
        content: "";
        pointer-events: none;
        background: linear-gradient(to bottom, transparent 40%, #1b012d 100%);
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
html.dark {
  .reels-gallery {
    .reels-poster-overlay {
      background: linear-gradient(to bottom, transparent 30%, #363636);

      .overlay-content {
        &::after {
          background: linear-gradient(to bottom, transparent 40%, #363636 90%);
        }
      }
    }
  }
}
</style>
