<template>
  <div class="w-full ring-1 ring-zinc-300 dark:ring-zinc-700 rounded-lg">
    <table class="min-w-full divide-y divide-zinc-300 dark:divide-zinc-700 ">
      <thead>
        <tr>
          <th
            scope="col"
            class="px-3 py-3.5 text-left text-sm font-bold text-zinc-900 dark:text-white"
          >
            {{ title }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td
            :class="[
              index === 0 ? '' : 'border-t border-zinc-300 dark:border-zinc-700 ',
              'relative px-3 py-3.5 text-sm ',
            ]"
          >
            <p v-html="item.title" class="font-medium text-zinc-700 dark:text-zinc-300"></p>
          </td>
          <td
            :class="[
              index === 0 ? '' : 'border-t border-zinc-300 dark:border-zinc-700 ',
              'px-3 py-3.5 text-sm text-zinc-500 dark:text-zinc-400 text-end',
            ]"
          >
            <p>${{ item.price }}</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>
