<template>
  <div class="w-full flex flex-col gap-2" :id="globalId">
    <h6 v-if="title" class="font-medium text-sm text-zinc-600 dark:text-zinc-400">
      {{ title }}
    </h6>

    <div
      class="border-2 h-full bg-zinc-100 rounded-md overflow-hidden dark:bg-zinc-950 dark:border-zinc-700 w-full box-border"
      :class="validationClass"
    >
      <div class="mb-0">
        <button
          class="group relative text-base max-md:text-sm max-sm:text-xs bg-zinc-100 dark:bg-zinc-950 flex w-full items-center border-0 p-3 text-start text-zinc-500 dark:text-zinc-400 [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:[&:not([data-te-collapse-collapsed])]:bg-zinc-950 dark:[&:not([data-te-collapse-collapsed])]:text-primary-400 dark:[&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(63,63,70)]"
          type="button"
          data-te-collapse-init
          data-te-collapse-collapsed
          :data-te-target="`#${id}`"
          aria-expanded="false"
          :aria-controls="id"
        >
          <slot name="title"></slot>
          <span
            class="ms-auto h-5 w-5 shrink-0 rotate-[-180deg] origin-center transition-transform duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-6 w-6 stroke-zinc-400 dark:stroke-zinc-600"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
              />
            </svg>
          </span>
        </button>
      </div>
      <div :id="id" class="!visible hidden" data-te-collapse-item>
        <div class="px-5 py-4 max-sm:p-3">
          <slot name="body"></slot>
        </div>
      </div>
    </div>

    <h6 v-if="validationMessage" :class="validationMessageClass">
      {{ validationMessage }}
    </h6>
  </div>
</template>

<script>
import { Collapse, initTE } from "tw-elements";

export default {
  props: {
    globalId: {
      type: String,
      requirde: false,
    },
    id: {
      type: String,
      requirde: true,
    },
    title: {
      type: String,
      requirde: false,
    },
    validationClass: {
      type: String,
      requirde: false,
    },
    validationMessage: {
      type: String,
      requirde: false,
    },
    validationMessageClass: {
      type: String,
      requirde: false,
    },
  },
  mounted() {
    setTimeout(() => {
      initTE({ Collapse });
    }, 1000);
  },
};
</script>
