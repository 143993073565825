<template>
  <div class="w-full">
    <div class="w-full flex justify-center ">
      <div
        class="justify-center fill-black dark:fill-white text-black dark:text-white flex w-full max-w-[300px] flex-col items-center gap-3 max-sm:max-w-[200px]"
      >
        <hr class="mt-5 h-1 w-full rounded-lg border-none bg-black dark:bg-white max-sm:h-[2px]" />
        <button
          @click="$emit('showMore')"
          type="button"
          class="flex flex-col items-center justify-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            version="1.1"
            class="fill-current h-16 w-16 max-sm:h-10 max-sm:w-10"
            viewBox="0 0 256 256"
            xml:space="preserve"
          >
            <defs></defs>
            <g
              style="
                stroke: none;
                stroke-width: 0;
                stroke-dasharray: none;
                stroke-linecap: butt;
                stroke-linejoin: miter;
                stroke-miterlimit: 10;
                fill: currentColor;
                fill-rule: nonzero;
                opacity: 1;
              "
              transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
            >
              <path
                d="M 90 23.25 c 0 -0.384 -0.146 -0.768 -0.439 -1.061 c -0.586 -0.586 -1.535 -0.586 -2.121 0 L 45 64.629 L 2.561 22.189 c -0.585 -0.586 -1.536 -0.586 -2.121 0 c -0.586 0.586 -0.586 1.535 0 2.121 l 43.5 43.5 c 0.585 0.586 1.536 0.586 2.121 0 l 43.5 -43.5 C 89.854 24.018 90 23.634 90 23.25 z"
                style="
                  stroke: none;
                  stroke-width: 1;
                  stroke-dasharray: none;
                  stroke-linecap: butt;
                  stroke-linejoin: miter;
                  stroke-miterlimit: 10;
                  fill: currentColor;
                  fill-rule: nonzero;
                  opacity: 1;
                "
                transform=" matrix(1 0 0 1 0 0) "
                stroke-linecap="round"
              />
            </g>
          </svg>

          <span class="uppercase font-semibold text-base max-sm:text-sm tracking-[0px]"
            >Show More</span
          >
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped></style>
