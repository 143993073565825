<template>
  <div class="relative flex">
    <input
      type="text"
      :placeholder="placeholder"
      :value="modelValue"
      @input="updateValue"
      @keydown="keydown"
      class="p-2 w-full text-sm rounded-s-lg text-black dark:text-white border-zinc-300 placeholder:text-zinc-400 dark:placeholder:text-zinc-500 border-2 bg-zinc-100 dark:bg-zinc-900 dark:border-zinc-700 outline-none"
    />
    <button
      @click="$emit('addValueClick')"
      class="border-2 border-s-0 p-2 rounded-e-lg border-zinc-300 dark:border-zinc-600 text-xl text-zinc-600 dark:text-zinc-400"
      type="button"
    >
      <Icon icon="ic:baseline-plus" />
    </button>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";
export default {
  props: {
    placeholder: {
      type: String,
      required: false,
    },
    modelValue: {
      type: String,
      required: false,
    },
  },
  methods: {
    updateValue(event) {
      this.$emit("update:modelValue", event.target.value);
    },
    keydown(e) {
      this.$emit("inputkeydown", e);
    },
  },
  components: { Icon },
};
</script>
