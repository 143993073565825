const videoPlayerPoster = {
  methods: {
    addOverlay(videoPlayerIDSelector, posterOverlayClass) {
      const videoPlayerEls = document.querySelectorAll(videoPlayerIDSelector);

      for (const playerEl of videoPlayerEls) {
        const posterSelector = `#${playerEl.id} .vjs-poster`;

        const posterEl = document.querySelector(posterSelector);

        if (posterEl) {
          posterEl.innerHTML += `<div class="vjs-poster-overlay"></div>`;

          const posterOverlayEl = document.querySelector(
            `${posterSelector} .vjs-poster-overlay`
          );
          posterOverlayEl.classList.add(posterOverlayClass);
        }
      }
    },
  },
};
const videoTypeCheck = {
  methods: {
    isVideo(sourceUrl) {
      const url = sourceUrl?.toString();

      if (url?.includes(".mp4") || url?.includes(".webm")) {
        return true;
      }
      return false;
    },
    getVideoType(sourceUrl) {
      const url = sourceUrl?.toString();

      if (url?.includes(".mp4")) {
        return "video/mp4";
      }
      if (url?.includes(".webm")) {
        return "video/webm";
      }
    },
  },
};

export { videoPlayerPoster, videoTypeCheck };
