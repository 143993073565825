<template>
  <div class="container h-full-screen flex items-center justify-center lg:max-w-7xl mx-auto text-center ">
    <div class="sm:space-y-8 space-y-5 px-4 py-12">
      <h1 class="sm:text-9xl text-8xl dark:text-white text-zinc-900 font-bold">500</h1>
      <h2 class="sm:text-4xl xs:text-2xl text-xl dark:text-white text-zinc-900">Internal Server Error</h2>
      <p class="dark:text-zinc-400 max-w-2xl mx-auto max-sm:text-sm text-zinc-900">
        Unfortunately, an unknown error has occurred! Please contact the website support!
      </p>
      <div class="flex gap-5 justify-center">
        <button
          @click="directTo('https://www.google.com/search?q=internal server error 500')"
          class="bg-primary rounded-lg text-white py-3 px-5 max-sm:text-sm"
        >
          How to fix this?
        </button>
        <button
          @click="directTo('')"
          class="bg-white border dark:bg-zinc-900 dark:border-zinc-700 rounded-lg dark:text-white py-3 px-5 max-sm:text-sm"
        >
          Try Reload
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    directTo(links) {
      window.location.href = links;
    },
  },
};
</script>
