<template>
  <header
    class="relative rounded-xl overflow-hidden md:py-[80px] py-[30px] flex items-center justify-center w-full"
  >
    <img
      class="absolute top-0 left-0 w-full h-full object-cover"
      :src="imgUrl"
      alt="header image"
    />

    <!-- Overlay -->
    <div
      class="opacity-[0.85] absolute top-0 start-0 z-0 w-full h-full transition-colors duration-300 bg-[#1b012d]/60 dark:bg-zinc-950/60"
    ></div>

    <!-- Content -->
    <div
      class="text-center capitalize text-white relative z-[2] w-full max-w-[1300px] px-4 flex flex-col items-center gap-4"
    >
      <h2
        class="font-bold md:text-4xl sm:text-3xl tracking-[0.25rem] text-2xl text-center capitalize"
      >{{ title }}</h2>

      <p class="max-sm:text-sm">{{ description }}</p>
    </div>
  </header>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    imgUrl: {
      type: String,
      required: true,
    },
  },
};
</script>