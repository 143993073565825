<template>
  <!-- Portfolio modal for get information of portfolio by id -->
  <Modal :has-footer="true" :modal-size="`lg`" :modal-id="`contactInfo`">
    <!-- Modal body -->
    <template v-slot:modalBody>
      <div v-if="this.$store.state.contactModal.id" class="text-sm max-xs:text-xs">
        <div class="space-y-3 mb-5">
          <div
            class="flex flex-wrap items-center gap-1 font-medium text-zinc-700 dark:text-zinc-300"
          >
            <h6 class="font-medium text-primary">Subject:</h6>
            <p class="space-x-1">
              {{ this.$store.state.contactModal.subject }}
            </p>
          </div>
          <div
            class="flex flex-wrap items-center gap-1 font-medium text-zinc-700 dark:text-zinc-300"
          >
            <h6 class="font-medium text-primary">First Name:</h6>
            <p class="space-x-1">
              {{ this.$store.state.contactModal.firstName }}
            </p>
          </div>
          <div
            class="flex flex-wrap items-center gap-1 font-medium text-zinc-700 dark:text-zinc-300"
          >
            <h6 class="font-medium text-primary">Last Name:</h6>
            <p class="space-x-1">
              {{ this.$store.state.contactModal.lastName }}
            </p>
          </div>
          <div
            class="flex flex-wrap items-center gap-1 font-medium text-zinc-700 dark:text-zinc-300"
          >
            <h6 class="font-medium text-primary">Email:</h6>
            <p class="space-x-1 break-all">
              {{ this.$store.state.contactModal.email }}
            </p>
          </div>
          <div
            v-if="this.$store.state.contactModal.phoneNumber"
            class="flex flex-wrap items-center gap-1 font-medium text-zinc-700 dark:text-zinc-300"
          >
            <h6 class="font-medium text-primary">Phone Number:</h6>
            <p class="space-x-1 break-all">
              {{ this.$store.state.contactModal.phoneNumber }}
            </p>
          </div>
          <div
            class="flex flex-wrap items-center gap-1 font-medium text-zinc-700 dark:text-zinc-300"
          >
            <h6 class="font-medium text-primary">User Selected Date:</h6>
            <p class="space-x-1">
              {{
                dateToStructuredDate(
                  this.$store.state.contactModal.userSelectedDate,
                  "dd/MM/yyyy"
                )
              }}
            </p>
          </div>
          <div
            class="flex flex-wrap items-center gap-1 font-medium text-zinc-700 dark:text-zinc-300"
          >
            <h6 class="font-medium text-primary">Sent on:</h6>
            <p class="space-x-1">
              {{
                dateToStructuredDate(
                  this.$store.state.contactModal.createdDate,
                  "dd/MM/yyyy hh:mm"
                )
              }}
            </p>
          </div>
        </div>

        <div class="space-y-1">
          <h6 class="font-medium text-primary">Description:</h6>

          <p
            class="font-medium text-zinc-700 dark:text-zinc-300 sm:leading-[1.7] leading-relaxed sm:text-sm text-xs"
          >
            {{ this.$store.state.contactModal.description }}
          </p>
        </div>
      </div>

      <template v-if="!this.$store.state.contactModal.id">
        <div class="flex justify-center items-center w-full h-full py-16 px-4">
          <LoadingSpinner class="text-primary dark:text-primary" />
        </div>
      </template>
    </template>
    <template v-slot:modalFooter>
      <button
        v-if="this.$store.state.contactModal.id"
        @click="readContact"
        type="button"
        data-te-modal-dismiss
        class="inline-flex items-center gap-1 text-sm max-xs:text-xs font-normal text-center text-white rounded-md bg-primary px-3 py-2"
      >
        <span>
          {{ this.$store.state.contactModal.hasRead ? "Mark As Unread" : "Mark As Read" }}
        </span>
        <Icon
          class="xs:text-lg text-base"
          v-if="this.$store.state.contactModal.hasRead"
          icon="fluent:mail-unread-16-filled"
        />
        <Icon class="xs:text-lg text-base" v-else icon="fluent:mail-read-16-filled" />
      </button>
    </template>
  </Modal>
</template>

<script>
import Modal from "../../components/Modal.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { Icon } from "@iconify/vue";

export default {
  components: {
    Modal,
    LoadingSpinner,
    Icon,
  },
  methods: {
    async readContact() {
      this.$emit("readChange", {
        id: this.$store.state.contactModal.id,
        hasRead: !this.$store.state.contactModal.hasRead,
      });
    },
  },
};
</script>
