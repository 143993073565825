<template>
  <div>
    <div class="present-project h-auto p-3 pb-10">
      <nav class="flex flex-col" aria-label="Breadcrumb">
        <ol class="inline-flex flex-wrap items-center">
          <li class="inline-flex items-center">
            <router-link
              to="/"
              class="inline-flex items-center sm:text-sm text-xs font-medium text-zinc-700 hover:text-zinc-950 dark:text-zinc-400 dark:hover:text-white"
            >
              <svg
                class="me-2 w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                ></path>
              </svg>
              Home
            </router-link>
          </li>
          <li>
            <div class="flex items-center">
              <svg
                class="w-6 h-6 text-zinc-400 rtl:rotate-180"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span
                class="sm:text-sm text-xs font-medium text-zinc-700 dark:text-zinc-400"
                >Requests List</span
              >
            </div>
          </li>
        </ol>

        <div class="mt-5 w-full">
          <h1
            class="md:text-xl xs:text-lg text-base text-zinc-950 font-medium dark:text-zinc-200"
          >
            Requests List
          </h1>
        </div>
      </nav>

      <div
        class="card flex rounded-md flex-wrap gap-x-6 max-md:gap-y-3 mt-5 justify-between bg-white dark:bg-zinc-800 w-full p-3 border dark:border-zinc-700"
      >
        <div class="flex max-sm:flex-col gap-y-3 gap-x-2 max-md:w-full">
          <div class="w-full">
            <dropdown placement="left">
              <template v-slot:button>
                <button
                  class="flex justify-between w-full px-4 py-3 rounded-md border bg-zinc-100 dark:bg-zinc-900 text-sm dark:border-zinc-700"
                >
                  <span class="text-zinc-500 dark:text-zinc-400">Filter</span>

                  <span class="ms-4 mt-1 text-zinc-500"
                    ><Icon icon="ant-design:caret-down-filled"
                  /></span>
                </button>
              </template>
              <template v-slot:content>
                <div class="uppercase text-xs">
                  <a
                    @click="
                      () =>
                        filterChange(
                          'hasRead, userSelectedDateRaw desc, createdDateRaw desc'
                        )
                    "
                    href="#"
                    class="block hover:bg-primary hover:text-white px-4 py-2"
                    :class="
                      filter == 'hasRead, userSelectedDateRaw desc, createdDateRaw desc'
                        ? 'bg-primary text-white'
                        : 'hover:bg-primary hover:text-white'
                    "
                  >
                    All</a
                  >
                  <a
                    @click="() => filterChange('hasRead desc')"
                    href="#"
                    class="block hover:bg-primary hover:text-white px-4 py-2"
                    :class="
                      filter == 'hasRead desc'
                        ? 'bg-primary text-white'
                        : 'hover:bg-primary hover:text-white'
                    "
                  >
                    Readed</a
                  >
                  <a
                    @click="() => filterChange('hasRead')"
                    href="#"
                    class="block hover:bg-primary hover:text-white px-4 py-2"
                    :class="
                      filter == 'hasRead'
                        ? 'bg-primary text-white'
                        : 'hover:bg-primary hover:text-white'
                    "
                  >
                    Unreaded</a
                  >
                  <a
                    @click="() => filterChange('createdDateRaw desc')"
                    href="#"
                    class="block hover:bg-primary hover:text-white px-4 py-2"
                    :class="
                      filter == 'createdDateRaw desc'
                        ? 'bg-primary text-white'
                        : 'hover:bg-primary hover:text-white'
                    "
                  >
                    Newest</a
                  >
                  <a
                    @click="() => filterChange('createdDateRaw')"
                    href="#"
                    class="block hover:bg-primary hover:text-white px-4 py-2"
                    :class="
                      filter == 'createdDateRaw'
                        ? 'bg-primary text-white'
                        : 'hover:bg-primary hover:text-white'
                    "
                  >
                    Oldest</a
                  >
                  <a
                    @click="() => filterChange('userSelectedDateRaw desc')"
                    href="#"
                    class="block hover:bg-primary hover:text-white px-4 py-2"
                    :class="
                      filter == 'userSelectedDateRaw desc'
                        ? 'bg-primary text-white'
                        : 'hover:bg-primary hover:text-white'
                    "
                  >
                    Newest User Selected</a
                  >
                  <a
                    @click="() => filterChange('userSelectedDateRaw')"
                    href="#"
                    class="block hover:bg-primary hover:text-white px-4 py-2"
                    :class="
                      filter == 'userSelectedDateRaw'
                        ? 'bg-primary text-white'
                        : 'hover:bg-primary hover:text-white'
                    "
                  >
                    Oldest User Selected</a
                  >
                </div>
              </template>
            </dropdown>
          </div>
        </div>
        <div
          class="input-box rounded-md border bg-zinc-100 dark:bg-zinc-900 dark:border-zinc-700 w-fit max-md:w-full box-border flex focus-within:bg-zinc-100 dark:focus-within:bg-zinc-700"
        >
          <div class="text-3xl p-2 pe-0 text-zinc-400">
            <Icon icon="ei:search" />
          </div>

          <input
            v-model="searchContent"
            type="text"
            placeholder="Search..."
            class="p-3 ps-2 sm:text-base text-sm w-full bg-zinc-100 rounded-md dark:bg-zinc-900 dark:text-zinc-400 outline-none focus:bg-zinc-100 dark:focus:bg-zinc-700"
          />
        </div>
      </div>

      <div v-show="request.length > 0 && !isLoading" class="flex flex-col gap-5 mt-6">
        <ContactBox
          v-for="item in request"
          :key="item.id"
          :id="item.id"
          :firstName="item.firstName"
          :lastName="item.lastName"
          :email="item.email"
          :phoneNumber="item.phoneNumber"
          :createdDate="item.createdDate"
          :description="item.description"
          :subject="item.subject"
          :hasRead="item.hasRead"
          @deleteClick="
            () =>
              (deleteInfo = {
                type: 'request',
                id: item.id,
              })
          "
        ></ContactBox>
      </div>

      <div
        v-show="request.length === 0 && !isLoading"
        class="w-full h-full flex justify-center items-center mt-20"
      >
        <span class="dark:text-white text-center text-black">Requests list is empty</span>
      </div>

      <div
        class="flex justify-center items-center w-full h-full py-16 px-4 pt-[140px]"
        v-show="isLoading"
      >
        <LoadingSpinner class="text-primary dark:text-primary" />
      </div>

      <ShowMoreButton
        @showMore="showMore"
        v-if="pagination.hasNext && !isLoading"
        class="mt-10 max-sm:mt-8"
      ></ShowMoreButton>
    </div>

    <ContactModal @readChange="readChange($event)"></ContactModal>
    <DeleteModal mode="sample" :info="deleteInfo" @deleteSubmit="deleteSubmit" />
  </div>
</template>

<script>
import Dropdown from "@/components/Dropdown.vue";
import ContactBox from "@/components/Contact/ContactBox.vue";
import ContactModal from "@/components/Contact/ContactModal.vue";
import DeleteModal from "@/components/DeleteModal.vue";
import { Icon } from "@iconify/vue";
import axios from "axios";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import ShowMoreButton from "@/components/ShowMoreButton.vue";

export default {
  components: {
    Dropdown,
    ContactBox,
    ContactModal,
    DeleteModal,
    LoadingSpinner,
    Icon,
    ShowMoreButton,
  },
  data() {
    return {
      request: [],
      deleteInfo: null,
      isLoading: true,
      searchContent: null,
      filter: "hasRead, userSelectedDateRaw desc, createdDateRaw desc",
      pagination: {},
    };
  },
  async mounted() {
    await this.getAllRequests(this.filter);
  },
  methods: {
    deleteSubmit(e) {
      if (e.type == "request") {
        this.deleteRequest(e.id);
      }
    },
    async getAllRequests(orderBy = null, searchValue = null, pageNumber) {
      if (!pageNumber) {
        this.isLoading = true;
        this.request = [];
      }

      let url = `Requests?PageSize=12&dateFormat=o&DescriptionLimit=200`;

      if (orderBy) {
        url += `&OrderBy=${orderBy}`;
      }

      if (searchValue) {
        url += `&Filter=firstName=*${searchValue}|lastName=*${searchValue}|email=*${searchValue}|description=*${searchValue}|phoneNumber=*${searchValue}`;
      }

      if (pageNumber) {
        url += `&Page=${pageNumber}`;
      }

      try {
        let httpResponse = await axios.get(url, { isNeedJwtAuth: true });

        let requests = httpResponse.data?.value?.data;

        if (pageNumber) {
          this.request = this.request.concat(requests);
        } else {
          this.request = requests;
        }

        console.log(this.request);

        this.isLoading = false;

        if (httpResponse.headers["x-pagination"]) {
          this.pagination = JSON.parse(httpResponse.headers["x-pagination"]);
        }
      } catch (error) {
        this.pagination = {};
        console.log(error);
        this.isLoading = false;
      }
    },
    async deleteRequest(id) {
      this.isLoading = true;

      await axios
        .delete(`Requests/${id}`, { isNeedJwtAuth: true })
        .then(async (response) => {
          await this.getAllRequests();
          this.$store.commit("showToast", {
            id: "myToast",
            type: "success",
            title: "Success!",
            body: "Your desired request has been successfully deleted!",
          });

          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response?.data?.isSuccess == false) {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Error!",
              body: error.response?.data?.messages[0],
            });
          } else {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Error!",
              body:
                "Unfortunately, an unknown error has occurred! Please contact the website support!",
            });
          }
          this.isLoading = false;
          console.log(error);
          console.log(error.response);
        });
    },
    async filterChange(filter) {
      this.filter = filter;
      await this.getAllRequests(filter, null);
    },
    async readChange(e) {
      await axios
        .put(`Requests/${e.id}/ToggleRead`, null, {
          isNeedJwtAuth: true,
        })
        .then(async (response) => {
          await this.getAllRequests(this.filter);
          this.$store.commit("showToast", {
            id: "myToast",
            type: "success",
            title: "Success!",
            body: "Your desired request has been successfully changed!",
          });
        })
        .catch((error) => {
          if (error.response?.data?.isSuccess == false) {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Error!",
              body: error.response?.data?.messages[0],
            });
          } else {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Error!",
              body:
                "Unfortunately, an unknown error has occurred! Please contact the website support!",
            });
          }
          console.log(error);
          console.log(error.response);
        });
    },
    async showMore() {
      this.getAllRequests(this.filter, this.searchValue, ++this.pagination.currentPage);
    },
  },
  watch: {
    async searchContent(val) {
      this.searchContent = val;

      if (this.searchContent) {
        document.querySelector(".main-content")?.scrollTo({ top: 0, behavior: "smooth" });

        await this.getAllRequests(this.filter, this.searchContent);
      } else {
        document.querySelector(".main-content")?.scrollTo({ top: 0, behavior: "smooth" });

        await this.getAllRequests(this.filter);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
