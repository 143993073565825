<template>
  <div class="categories-list h-auto p-3 pb-10">
    <nav class="flex flex-col" aria-label="Breadcrumb">
      <ol class="inline-flex flex-wrap items-center">
        <li class="inline-flex items-center">
          <router-link
            to="/"
            class="inline-flex items-center sm:text-sm text-xs font-medium text-zinc-700 hover:text-zinc-950 dark:text-zinc-400 dark:hover:text-white"
          >
            <svg
              class="me-2 w-4 h-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
              ></path>
            </svg>
            Home
          </router-link>
        </li>
        <li>
          <div class="flex items-center">
            <svg
              class="w-6 h-6 text-zinc-400 rtl:rotate-180"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span class="sm:text-sm text-xs font-medium text-zinc-700 dark:text-zinc-400"
              >Categories List</span
            >
          </div>
        </li>
      </ol>

      <div class="mt-5 w-full">
        <h1
          class="md:text-xl xs:text-lg text-base text-zinc-950 font-medium dark:text-zinc-200"
        >
          Categories List
        </h1>
      </div>


    </nav>

    <div
      v-if="!isLoading"
      class="grid min-[2100px]:grid-cols-4 min-[1600px]:grid-cols-3 xl:grid-cols-3 lg:grid-cols-2 min-[950px]:grid-cols-3 min-[600px]:grid-cols-2 mt-8 max-sm:mt-6 gap-5"
    >
      <Box
        v-for="(category, index) in categories"
        :packageTitle="
          category.type == 'OveralPackage' ? 'Overall Package' : 'Package Price'
        "
        :packageLinkTo="
          category.type == 'OveralPackage'
            ? `/packages/overall?category-id=${category.id}&is-category-exist=true&category-title=${category.title}`
            : `/packages/packages-price?category-id=${category.id}&is-category-exist=true&category-title=${category.title}`
        "
        :key="index"
        :title="category.title"
        :description="category.description"
        :image="category.imageUrl"
      >
        <template v-slot:buttons>
          <router-link
            type="button"
            class="text-zinc-600 dark:text-zinc-400"
            :to="`/categories/${category.id}/edit`"
          >
            <Icon icon="emojione-monotone:fountain-pen" />
          </router-link>
          <button
            type="button"
            class="text-red-500"
            @click="
              () =>
                (deleteInfo = {
                  type: 'category',
                  id: category.id,
                })
            "
            data-te-target="#deleteModal"
            data-te-toggle="modal"
          >
            <Icon icon="entypo:trash" />
          </button>
        </template>
      </Box>
    </div>

    <div
      v-if="categories.length === 0 && !isLoading"
      class="w-full h-full flex justify-center items-center mt-20"
    >
      <span class="dark:text-white text-black">There is no content to display</span>
    </div>

    <div
      class="flex justify-center items-center w-full h-full p-16 pt-[140px]"
      v-if="isLoading"
    >
      <LoadingSpinner class="text-primary dark:text-primary" />
    </div>

    <DeleteModal mode="normal" :info="deleteInfo" @deleteSubmit="deleteSubmit" />
  </div>
</template>

<script>
import Dropdown from "../../components/Dropdown.vue";
import Box from "@/components/Categories/CategoryBox.vue";
import axios from "axios";
import { Icon } from "@iconify/vue";
import appSettings from "@/settings/appSettings";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import DeleteModal from "../../components/DeleteModal.vue";

import ShowMoreButton from "@/components/ShowMoreButton.vue";

export default {
  components: {
    Icon,
    Dropdown,
    Box,
    DeleteModal,
    LoadingSpinner,
    ShowMoreButton,
  },
  data() {
    return {
      categories: [],
      deleteInfo: null,
      isLoading: true,
      categoryId: null,
    };
  },

  async created() {
    await this.getCategories();
  },

  mounted() {
    // setTimeout(() => {
    //   initTE({ Modal, Ripple });
    // }, 500);
  },

  methods: {
    deleteSubmit(e) {
      if (e.type == "category") {
        this.removeItemClick(e.id);
      }
    },

    getCategories: async function () {
      this.categories = [];
      this.isLoading = true;

      let url = "Categories";

      try {
        let httpResponse = await axios.get(url);

        let categories = httpResponse.data.value;

        categories.forEach((category) => {
          // Set baseUrl before image urls
          category.imageUrl = `${appSettings.backendBaseUrl}/${category.imageUrl}`;
        });

        this.categories = categories;

        console.log(this.categories);
        console.log("finish");
        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
    async removeItemClick(itemId) {
      this.isLoading = true;

      await axios
        .delete(`Categories/${itemId}`, { isNeedJwtAuth: true })
        .then(async (response) => {
          await this.getCategories(this.categoryId, this.orderBy);
          this.$store.commit("showToast", {
            id: "myToast",
            type: "success",
            title: "Success!",
            body: "Your desired category has been successfully deleted!",
          });

          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response?.data?.isSuccess == false) {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Error!",
              body: error.response?.data?.messages[0],
            });

          } else {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Error!",
              body:
              "Unfortunately, an unknown error has occurred! Please contact the website support!",
            });
          }
          this.isLoading = false;
          console.log(error);
          console.log(error.response);
          
        });
    },
  },
};
</script>
