import axios from "axios";

export async function getMoreServiceCategories() {
  let serviceCategories = null;
  let isLoadingServiceCategories = true;

  let url = `PackagePrices/box/categories`;

  try {
    let httpResponse = await axios.get(url);

    serviceCategories = httpResponse.data.value;

    isLoadingServiceCategories = false;
  } catch (error) {
    console.log(error);
    isLoadingServiceCategories = false;
  }

  return {
    isLoadingServiceCategories,
    serviceCategories,
  };
}
