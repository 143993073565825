<template>
  <file-pond
    name="test"
    ref="pictureUpload"
    class-name="my-pond"
    v-bind:allow-multiple="allowMultiple"
    allow-reorder="true"
    allow-file-type-validation="true"
    allowImageExifOrientation="true"
    :accepted-file-types="acceptedFileTypes"
    max-file-size="500MB"
    :allowVideoPreview="true"
    :files="myFiles"
    @updatefiles="updateFiles"
    @reorderfiles="updateFiles"
  />
  
</template>

<script>
// Import FilePond
import vueFilePond from "vue-filepond";

// Import plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginMediaPreview from 'filepond-plugin-media-preview';

// Import styles
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

// Create FilePond component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginImageExifOrientation,
  FilePondPluginMediaPreview
);

export default {
  data: function () {
    return {
      myFiles: [],
    };
  },
  methods: {
    updateFiles(file) {
      this.myFiles = file;
      this.$emit("updatePendingFiles", this.$refs.pictureUpload.getFiles());
    },
  },
  components: {
    FilePond,
  },
  computed: {
    refPound: function () {
      return this.$refs.pictureUpload;
    },
  },
  props: {
    allowMultiple: {
      type: Boolean,
      default: false,
    },
    acceptedFileTypes: {
      type: String,
      default: "image/jpeg, image/png, image/webp",
    },
  },
};
</script>

<style lang="scss">
html {
  // Light theme
  $border-light: rgb(228 228 231);
  $background-light: rgb(244 244 245);
  $text-light: rgb(82 82 91);
  // @apply border-zinc-200;
  // @apply bg-zinc-100;
  // @apply text-zinc-600;

  // Dark theme
  $border-dark: rgb(63 63 70);
  $background-dark: rgb(9 9 11);
  $text-dark: rgb(113 113 122);
  // @apply border-zinc-700;
  // @apply bg-zinc-950;
  // @apply text-zinc-500;

  .filepond--root {
    margin-bottom: 0;
  }
  .filepond--credits {
    display: none;
  }

  .filepond--drop-label {
    color: $text-light;
  }
  .filepond--label-action {
    text-decoration-color: $text-light;
    color: $text-light;
    font-weight: bold;
  }
  /* bordered drop area */
  .filepond--panel-root {
    background-color: $background-light;
    border: 2px solid;
    border-color: $border-light;

    border-radius: 6px;
  }

  @media screen and (max-width: 550px) {
    .filepond--drop-label label {
      font-size: 80%;
    }

    .filepond--file {
      font-size: 80% !important;
    }
  }

  &.dark {
    .filepond--drop-label {
      color: $text-dark;
    }
    .filepond--label-action {
      text-decoration-color: $text-dark;
      color: $text-dark;
    }
    /* bordered drop area */
    .filepond--panel-root {
      background-color: $background-dark;
      border-color: $border-dark;
    }
  }
}
</style>
