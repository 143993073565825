<template>
  <div
    class="block rounded-xl overflow-hidden border-2 border-zinc-200 dark:border-zinc-800 text-black dark:text-white  bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-zinc-900"
  >
    <div class="relative overflow-hidden bg-cover bg-no-repeat">
      <div class="w-full h-full relative aspect-w-4 aspect-h-5">
        <img
          :src="image"
          :alt="title"
          class="w-full h-full object-cover object-center"
        />
        <div
          class="w-full h-full absolute top-0 start-0 flex items-center justify-center"
        >
          <router-link
            :to="packageLinkTo"
            class="inline-flex items-center gap-2 transition-transform duration-300 hover:scale-105 bg-blue-600 text-white shadow-normal rounded-lg text-xs font-medium py-3 px-3"
          >
            <span>{{ packageTitle }}</span>
            <span class="border border-white p-[2px] inline-block rounded-md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-3 h-3 fill-white"
                viewBox="0 0 320 512"
              >
                <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                <path
                  d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"
                />
              </svg>
            </span>
          </router-link>
        </div>
      </div>
      <svg
        v-if="mode == 'one'"
        class="absolute start-0 bottom-0 translate-y-[1px] text-white dark:text-zinc-900"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
      >
        <path
          fill="currentColor"
          d="M0,288L48,272C96,256,192,224,288,197.3C384,171,480,149,576,165.3C672,181,768,235,864,250.7C960,267,1056,245,1152,250.7C1248,256,1344,288,1392,304L1440,320L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
        ></path>
      </svg>
      <svg
        v-else-if="mode == 'two'"
        class="absolute start-0 bottom-0 translate-y-[1px] text-white dark:text-zinc-900"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
      >
        <path
          fill="currentColor"
          d="M0,96L48,128C96,160,192,224,288,240C384,256,480,224,576,213.3C672,203,768,213,864,202.7C960,192,1056,160,1152,128C1248,96,1344,64,1392,48L1440,32L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
        ></path>
      </svg>
      <svg
        v-else-if="mode == 'three'"
        class="absolute start-0 bottom-0 translate-y-[1px] text-white dark:text-zinc-900"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
      >
        <path
          fill="currentColor"
          d="M0,288L48,256C96,224,192,160,288,160C384,160,480,224,576,213.3C672,203,768,117,864,85.3C960,53,1056,75,1152,69.3C1248,64,1344,32,1392,16L1440,0L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
        ></path>
      </svg>
    </div>
    <div class="px-5 pb-5 text-black dark:text-white">
      <h5 class="mb-2 text-lg max-lg:text-base font-bold">{{ title }}</h5>
      <p class="text-sm max-lg:text-xs mb-6 line-clamp-2">
        {{ description }}
      </p>
      <div class="gap-4 flex justify-end items-center lg:text-2xl text-xl">
        <slot name="buttons"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { Lightbox, initTE } from "tw-elements";

export default {
  props: {
    image: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
    packageLinkTo: {
      type: String,
      required: false,
    },
    packageTitle: {
      type: String,
      required: false,
    },
    mode: {
      type: String,
      required: false,
      default: "one",
    },
  },

  mounted() {
    setTimeout(() => {
      initTE({ Lightbox });
    }, 500);
  },
};
</script>
