<template>
  <div class="h-auto p-3 pb-10">
    <nav class="flex flex-col" aria-label="Breadcrumb">
      <ol class="inline-flex flex-wrap items-center">
        <li class="inline-flex items-center">
          <router-link
            to="/"
            class="inline-flex items-center sm:text-sm text-xs font-medium text-zinc-700 hover:text-zinc-950 dark:text-zinc-400 dark:hover:text-white"
          >
            <svg
              class="me-2 w-4 h-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
              ></path>
            </svg>
            Home
          </router-link>
        </li>
        <li>
          <div class="flex items-center">
            <svg
              class="w-6 h-6 text-zinc-400 rtl:rotate-180"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <router-link
              to="/password"
              class="sm:text-sm text-xs font-medium text-zinc-700 hover:text-zinc-950 dark:text-zinc-400 dark:hover:text-white"
              >Change Password</router-link
            >
          </div>
        </li>
      </ol>

      <div class="mt-5 w-full">
        <h1
          class="md:text-xl xs:text-lg text-base text-zinc-950 font-medium dark:text-zinc-200"
        >
          Change Username and Password
        </h1>
      </div>
    </nav>

    <div
      class="bg-white dark:bg-zinc-900 rounded-md p-5 w-full box-border border dark:border-zinc-700 mt-5"
    >
      <form>
        <div class="informations flex flex-col gap-6 mb-5">
          <div class="flex flex-col gap-3">
            <div class="flex flex-col gap-2 mb-2">
              <h3
                class="md:text-lg text-base text-secondary dark:text-white font-medium"
              >
                Informations
              </h3>
              <hr
                class="border-none w-full h-[2px] bg-zinc-200 dark:bg-zinc-600"
              />
            </div>

            <Input
              class="w-full required"
              type="text"
              placeholder="Username"
              v-model="viewModel.userName"
              :isRequired="true"
            ></Input>

            <div class="flex max-xl:flex-col gap-3 mt-3">
              <div
                class="flex w-full max-xl:max-w-none max-[600px]:flex-col gap-3"
              >
                <div class="relative z-0 w-full">
                  <Input
                    class="w-full required"
                    :type="currentPasswordType"
                    placeholder="Current Password"
                    v-model="viewModel.currentPassword"
                    :isRequired="true"
                  ></Input>

                  <button
                    @click="
                      () => {
                        currentPasswordType == 'password'
                          ? (currentPasswordType = 'text')
                          : (currentPasswordType = 'password');
                      }
                    "
                    v-if="viewModel.currentPassword.length > 0"
                    class="absolute inline-flex items-center justify-center w-max z-[2] end-0 top-0 text-zinc-900"
                    type="button"
                  >
                    <Icon
                      v-if="currentPasswordType == 'password'"
                      class="text-xl"
                      icon="clarity:eye-show-solid"
                    />
                    <Icon
                      v-if="currentPasswordType == 'text'"
                      class="text-xl"
                      icon="dashicons:hidden"
                    />
                  </button>
                </div>

                <div class="relative z-0 w-full">
                  <Input
                    class="w-full required"
                    :type="newPasswordType"
                    placeholder="New Password"
                    v-model="viewModel.newPassword"
                    :isRequired="true"
                  ></Input>

                  <button
                    @click="
                      () => {
                        newPasswordType == 'password'
                          ? (newPasswordType = 'text')
                          : (newPasswordType = 'password');
                      }
                    "
                    v-if="viewModel.newPassword.length > 0"
                    class="absolute inline-flex items-center justify-center w-max z-[2] end-0 top-0 text-zinc-900"
                    type="button"
                  >
                    <Icon
                      v-if="newPasswordType == 'password'"
                      class="text-xl"
                      icon="clarity:eye-show-solid"
                    />
                    <Icon
                      v-if="newPasswordType == 'text'"
                      class="text-xl"
                      icon="dashicons:hidden"
                    />
                  </button>
                </div>

                <div class="relative z-0 w-full">
                  <Input
                    class="w-full required"
                    :type="confirmPasswordType"
                    placeholder="Confirm Password"
                    v-model="viewModel.confirmPassword"
                    :isRequired="true"
                  ></Input>

                  <button
                    @click="
                      () => {
                        confirmPasswordType == 'password'
                          ? (confirmPasswordType = 'text')
                          : (confirmPasswordType = 'password');
                      }
                    "
                    v-if="viewModel.confirmPassword.length > 0"
                    class="absolute inline-flex items-center justify-center w-max z-[2] end-0 top-0 text-zinc-900"
                    type="button"
                  >
                    <Icon
                      v-if="confirmPasswordType == 'password'"
                      class="text-xl"
                      icon="clarity:eye-show-solid"
                    />
                    <Icon
                      v-if="confirmPasswordType == 'text'"
                      class="text-xl"
                      icon="dashicons:hidden"
                    />
                  </button>

                  <span class="text-red-600 text-sm" v-if="isPasswordsDiffrent">Your input passwords are not the same!</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr class="w-full border dark:border-zinc-700" />

        <div class="wrapper-button sm:text-base text-sm flex justify-end mt-5">
          <button
            v-if="!isAdding"
            :disabled="
              viewModel.userName?.length == 0 ||
              viewModel.currentPassword?.length == 0 ||
              viewModel.newPassword?.length == 0 || 
              viewModel.confirmPassword?.length == 0||
              isPasswordsDiffrent == true
            "
            type="button"
            data-te-target="#deleteModal"
            data-te-toggle="modal"
            class="text-white bg-primary rounded-md hover:bg-primary/80 transition-all duration-300 focus:outline-none py-2 px-6 w-fit disabled:opacity-60 disabled:pointer-events-none"
          >
            Save
          </button>

          <button
            v-else
            disabled
            type="button"
            class="text-white bg-primary rounded-md opacity-60 cursor-default transition-all duration-300 focus:outline-none py-2 px-6 w-fit"
          >
            <svg
              role="status"
              class="inline w-4 h-4 me-1 text-white animate-spin"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="#E5E7EB"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentColor"
              />
            </svg>
            Updating...
          </button>
        </div>
      </form>
    </div>

    <DeleteModal
      mode="sample"
      title="Are you sure to change username and password?"
      button-title="Change"
      @deleteSubmit="changePassword"
    />
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";
import Input from "@/components/Input.vue";
import axios from "axios";
import DeleteModal from "@/components/DeleteModal.vue";
import { useUserSignData } from "@/store/userSignData";

export default {
  components: {
    Icon,
    Input,
    DeleteModal,
  },

  data() {
    return {
      viewModel: {
        userName: "",
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      isAdding: false,
      currentPasswordType: "password",
      newPasswordType: "password",
      confirmPasswordType: "password",
      isPasswordsDiffrent: false,
    };
  },

  mounted() {
    this.viewModel.userName = useUserSignData().user?.userName;
  },

  watch: {
    "viewModel.newPassword"() {
      this.checkConfirmPassword();
    },
    "viewModel.confirmPassword"() {
      this.checkConfirmPassword();
    },
  },

  methods: {
    async changePassword() {
      this.isAdding = true;

      let isSuccess = true;

      let options = {
        isNeedJwtAuth: true,
      };

      await axios
        .put(`Users/Password`, this.viewModel, options)
        .then(async (response) => {
          useUserSignData().logout();

          this.$store.commit("showToast", {
            id: "myToast",
            type: "success",
            title: "Success!",
            body: "Information was successfully changed. please login again.",
          });
        })
        .catch((error) => {
          isSuccess = false;

          if (error.response?.data?.isSuccess == false) {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Error!",
              body: error.response?.data?.messages[0],
            });
          } else {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Error!",
              body: "Unfortunately, an unknown error has occurred! Please contact the website support!",
            });
          }

          this.isAdding = false;
          console.log(error);
          console.log(error.response);
        });
    },
    checkConfirmPassword(e) {
      if (this.viewModel.newPassword != this.viewModel.confirmPassword) {
        this.isPasswordsDiffrent = true;
      } else {
        this.isPasswordsDiffrent = false;
      }
    },
  },
};
</script>
