<template>
  <!-- this header -->
  <header class="bg-white dark:bg-zinc-900 p-2 border-b-2 dark:border-zinc-700">
    <div class="wrap-header flex items-center gap-5 justify-between flex-wrap">
      <div class="flex flex-no-shrink items-center">
        <button
          class="text-zinc-500 lg:hidden ms-3 block"
          @click="$emit('sidebarToggle', true)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="img"
            width="2em"
            height="2em"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 16 16"
          >
            <path
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
              d="M2.75 12.25h10.5m-10.5-4h10.5m-10.5-4h10.5"
            />
          </svg>
        </button>
      </div>

      <div class="me-5 flex gap-3">
        <button @click="fullscreenToggle" class="me-5 text-2xl text-zinc-500 relative">
          <i v-if="!fullscreenMode">
            <Icon icon="ic:outline-fullscreen" />
          </i>
          <i v-else>
            <Icon icon="ic:outline-fullscreen-exit" />
          </i>
        </button>

        <!-- btn dark mode -->
        <button
          @click="setTheme(true)"
          class="me-5 text-2xl text-zinc-500"
          v-if="!darkMode"
        >
          <Icon icon="ph:sun-dim" />
        </button>
        <button @click="setTheme(false)" v-else class="me-5 text-2xl text-zinc-500">
          <Icon icon="ri:moon-fill" />
        </button>

        <button @blur="menuToggleBlur" @click="menuToggle">
          <div class="user-avatar flex p-1 cursor-pointer rounded-md">
            <div>
              <img
                src="../assets/img/user.png"
                class="rounded-full w-10 h-10 p-1 ring-1 ring-zinc-300 dark:ring-zinc-500"
                alt="user icon"
              />
            </div>
            <div class="text-start text-sm ms-4 lg:block md:block hidden">
              <h2 class="dark:text-white font-Me text-zinc-900">Welcome</h2>
              <p class="text-xs text-zinc-400 dark:text-zinc-500">Admin</p>
            </div>
            <!-- <span class="text-md mt-4 text-zinc-300"
              ><Icon icon="bi:caret-down-fill"
            /></span> -->
          </div>
        </button>

        <transition name="fade">
          <div
            id="dropdownSmall"
            v-show="menu"
            class="block absolute end-2 mt-12 z-50 w-52 border dark:border-zinc-700 bg-white dark:bg-zinc-900 rounded divide-y dark:divide-zinc-700 divide-zinc-100 shadow"
          >
            <div class="py-3 flex flex-col gap-1 px-4 text-sm text-zinc-950 dark:text-zinc-200">
              <span class="text-xs">Logged As</span>
              <span class="font-medium truncate">{{ username }}</span>
            </div>

            <hr class="!border-zinc-200 dark:!border-zinc-700" />

            <div class="py-1">
              <router-link to="/password"
                class="block py-2 px-4 text-sm text-zinc-700 dark:text-zinc-200 hover:bg-primary hover:text-white text-start w-full"
              >
                Change User and Pass
              </router-link>
            </div>

            <div class="py-1">
              <button
                @click="logout"
                class="block py-2 px-4 text-sm text-zinc-700 dark:text-zinc-200 hover:bg-primary hover:text-white text-start w-full"
              >
                Sign out
              </button>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </header>
</template>
<style></style>
<script>
import { Icon } from "@iconify/vue";
import { fullscreen } from "@/helper/fullscreen";
import { setDarkMode, loadDarkMode } from "@/helper/theme";
import { useUserSignData } from "@/store/userSignData";

export default {
  data() {
    return {
      menu: false,
      darkMode: false,
      fullscreenMode: false,
      username: "",
    };
  },
  components: {
    Icon,
  },
  watch: {
    $route() {
      this.menu = false;
    },
  },
  methods: {
    fullscreen,
    setDarkMode,
    loadDarkMode,

    logout() {
      useUserSignData().logout();
    },

    menuToggle: function () {
      this.menu = !this.menu;
    },
    menuToggleBlur: function () {
      this.menu = false;
    },
    limitText(message) {
      const text = message.length > 25 ? message.substring(0, 25) + "..." : message;
      return text;
    },
    fullscreenToggle() {
      this.fullscreenMode = !this.fullscreenMode;
      this.fullscreen(this.fullscreenMode);
    },
    // set theme to dark and light
    setTheme(bool) {
      this.darkMode = bool;
      this.setDarkMode(bool);
    },

    imageAssets(url) {
      return require("@/assets/img/" + url);
    },
  },
  mounted() {
    // get theme dark or light with loadDarkMode()
    this.darkMode = this.loadDarkMode();
    this.username = useUserSignData().user?.userName;

    document.onfullscreenchange = (event) => {
      if (document.fullscreenElement) {
        this.fullscreenMode = true;
      } else {
        this.fullscreenMode = false;
      }
    };
  },
};
</script>
