<template>
  <div
    v-if="mode == 'normal'"
    class="w-full h-full p-1 border-2 border-zinc-300 dark:border-zinc-800 dark:text-white rounded-2xl bg-zinc-200 dark:bg-zinc-950 font-[inter]"
  >
    <div
      class="w-full gap-6 bg-white dark:bg-zinc-800 p-4 pt-16 rounded-xl flex flex-col justify-end items-center"
    >
      <!-- Title -->
      <h3
        class="font-bold text-center text-4xl max-lg:text-3xl max-[950px]:text-4xl max-sm:text-3xl"
      >
        {{ title }}
      </h3>

      <div class="w-full flex flex-col items-center">
        <router-link
          :to="`${isMore ? '/packages/overall/more?' : '/packages/packages-price?type=overalPackageBox&'}is-overal-package-box-exist=true&overal-package-box-id=${id}&overal-package-box-title=${title}&category-id=${categoryId}&category-title=${categoryTitle}`"
          class="bg-black rounded-xl flex justify-center items-center fill-white text-white text-xs gap-2 py-3 px-3"
        >
          <span class="font-light">View Package</span>

          <span class="border border-white p-[2px] inline-block rounded-md">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-3 h-3"
              viewBox="0 0 320 512"
            >
              <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
              <path
                d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"
              />
            </svg>
          </span>
        </router-link>
      </div>
    </div>

    <div class="p-5">
      <p class="text-xs line-clamp-5 font-light">
        {{ content }}
      </p>
    </div>
  </div>

  <div
    v-else-if="mode == 'editable'"
    class="w-full h-full p-1 border-2 border-zinc-300 dark:border-zinc-800 dark:text-white rounded-2xl bg-zinc-200 dark:bg-zinc-950 font-[inter]"
  >
    <div
      class="w-full relative bg-white dark:bg-zinc-800 p-4 pt-12 rounded-xl flex flex-col justify-end items-center"
    >
      <!-- Title -->
      <slot name="title"></slot>
    </div>

    <div class="p-1 pt-2">
      <slot name="more"></slot>
    </div>
  </div>
</template>

<script>
import Input from "@/components/Input.vue";

export default {
  props: {
    id: {
      type: Number,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    categoryId: {
      type: Number,
      required: false,
    },
    categoryTitle: {
      type: String,
      required: false,
    },
    content: {
      type: String,
      required: false,
    },
    isMore: {
      type: Boolean,
      required: false,
    },
    mode: {
      type: String,
      required: false,
      default: "normal",
    },
  },
  component: {
    Input,
  },
};
</script>

<style lang="scss" scoped></style>
