<template>
  <div class="flex flex-col gap-2">
    <h6 class="font-medium text-sm text-zinc-600 dark:text-zinc-400" v-if="isTitleShow">
      {{ placeholder }}
    </h6>
    <div
      class="border-2 text-base max-md:text-sm rounded-md overflow-hidden border-zinc-200 bg-zinc-100 dark:bg-zinc-950 dark:border-zinc-700 w-full box-border flex"
      :class="outerInputClass"
    >
      <input
        v-if="type != 'textarea' && type != 'number'"
        :required="isRequired"
        :type="type"
        :placeholder="placeholder"
        :value="modelValue"
        @input="updateValue"
        @keydown="keydown"
        :disabled="isDisabled"
        class="p-3 w-full text-black dark:text-white bg-zinc-50 placeholder:text-zinc-400 dark:placeholder:text-zinc-500 dark:bg-zinc-950 outline-none"
        :class="inputClass"
        autocomplete="new-password"
      />
      <input
        v-if="type == 'number'"
        :required="isRequired"
        :type="type"
        :max="max"
        :min="min"
        :placeholder="placeholder"
        :value="modelValue"
        @input="updateValue"
        @keydown="keydown"
        :disabled="isDisabled"
        class="p-3 w-full text-black dark:text-white bg-zinc-50 placeholder:text-zinc-400 dark:placeholder:text-zinc-500 dark:bg-zinc-950 outline-none"
        :class="inputClass"
      />
      <textarea
        :required="isRequired"
        v-if="type === 'textarea'"
        :rows="textareaRows"
        :placeholder="placeholder"
        :value="modelValue"
        @input="updateValue"
        @keydown="keydown"
        :disabled="isDisabled"
        class="p-3 w-full text-black dark:text-white bg-zinc-50 placeholder:text-zinc-400 dark:placeholder:text-zinc-500 dark:bg-zinc-950 outline-none"
        :class="inputClass"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: false,
      default: "text",
    },
    inputClass: {
      type: String,
      required: false,
    },
    outerInputClass: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: true,
    },
    textareaRows: {
      type: String,
      required: false,
      default: "5",
    },
    isTitleShow: {
      type: Boolean,
      required: false,
      default: true,
    },
    min: {
      type: Number,
      required: false,
    },
    max: {
      type: Number,
      required: false,
    },
    modelValue: {
      type: [String, Number],
      required: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
      required: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  methods: {
    updateValue(event) {
      this.$emit("update:modelValue", event.target.value);
    },
    keydown(e) {
      this.$emit("inputkeydown", e);
    },
  },
};
</script>

<style lang="scss" scoped></style>
