import axios from "axios";
import settings from "@/settings/appSettings";

export async function getPackagePrice(id, type) {
  let packagePrice = null;
  let isLoading = true;

  let url = "";

  if (type == "category") {
    url = `PackagePrices?CategoryId=${id}`;
  }

  if (type == "overalPackageBox") {
    url = `PackagePrices?OveralPackageBoxId=${id}`;
  }

  try {
    let httpResponse = await axios.get(url);

    packagePrice = httpResponse.data.value[0];

    // Set baseUrl before header image url
    packagePrice.headerImageUrl = `${settings.backendBaseUrl}/${packagePrice.headerImageUrl}`;

    packagePrice.moreServices = packagePrice?.packagePriceBoxes?.filter(
      (current) => current.categoryId && current.isMoreService
    );

    packagePrice.packagePriceBoxes = packagePrice?.packagePriceBoxes?.filter(
      (current) => !current.categoryId && !current.isMoreService
    );

    let galleryFiles = [];
    packagePrice?.galleryFiles?.forEach((url) => {
      // Set baseUrl before image and video urls
      galleryFiles.push(`${settings.backendBaseUrl}/${url}`);
    });
    packagePrice.galleryFiles = galleryFiles;

    packagePrice?.reels?.forEach((current) => {
      // Set baseUrl before reels
      current.videoUrl = `${settings.backendBaseUrl}/${current.videoUrl}`.replaceAll("\\", "/");
      current.posterUrl =
        `${settings.backendBaseUrl}/${current.posterUrl}`.replaceAll("\\", "/");
    });

    packagePrice?.packagePriceBoxes.forEach((box) => {
      let imageUrls = [];
      box.rawAttributes = [];

      box.rawAttributes = box.attributes?.map((current) => current.id);

      // Set baseUrl before image urls
      box?.imageUrls?.forEach((url) => {
        // Set baseUrl before image urls
        imageUrls.push(`${settings.backendBaseUrl}/${url}`);
      });

      box.imageUrls = imageUrls;
    });

    isLoading = false;
  } catch (error) {
    console.log(error);
    isLoading = false;
  }

  return {
    isLoading,
    packagePrice,
  };
}
