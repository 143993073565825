<template>
  <div class="h-auto p-3 pb-10">
    <nav class="flex flex-col" aria-label="Breadcrumb">
      <ol class="inline-flex flex-wrap items-center">
        <li class="inline-flex items-center">
          <router-link
            to="/"
            class="inline-flex items-center sm:text-sm text-xs font-medium text-zinc-700 hover:text-zinc-950 dark:text-zinc-400 dark:hover:text-white"
          >
            <svg
              class="me-2 w-4 h-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
              ></path>
            </svg>
            Home
          </router-link>
        </li>
        <li>
          <div class="flex items-center">
            <svg
              class="w-6 h-6 text-zinc-400 rtl:rotate-180"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <router-link
              to="/categories"
              class="sm:text-sm text-xs font-medium text-zinc-700 hover:text-zinc-950 dark:text-zinc-400 dark:hover:text-white"
              >Categories List</router-link
            >
          </div>
        </li>
        <li>
          <div class="flex items-center">
            <svg
              class="w-6 h-6 text-zinc-400 rtl:rotate-180"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <router-link
              :to="`/packages/overall?category-id=${category.id}&is-category-exist=true&category-title=${category.title}`"
              class="sm:text-sm text-xs font-medium text-zinc-700 hover:text-zinc-950 dark:text-zinc-400 dark:hover:text-white"
              >Overall Package</router-link
            >
          </div>
        </li>
        <li>
          <div class="flex items-center">
            <svg
              class="w-6 h-6 text-zinc-400 rtl:rotate-180"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span
              class="sm:text-sm text-xs font-medium text-zinc-700 dark:text-zinc-400"
              >More Package</span
            >
          </div>
        </li>
      </ol>

      <div class="mt-5 w-full">
        <h1
          class="md:text-lg text-base text-zinc-950 font-medium dark:text-zinc-200"
        >
          More Package | Overall Package Box: {{ overalPackageBox.title }}
        </h1>
      </div>
    </nav>

    <div
      class="bg-white dark:bg-zinc-900 rounded-md p-5 w-full box-border border dark:border-zinc-700 mt-5"
    >
      <div v-show="!isLoading && packagePrice" class="flex flex-col gap-3 mb-5">
        <div class="flex flex-col gap-2 mb-2">
          <div class="flex items-center justify-between gap-6">
            <h3
              class="md:text-lg text-base text-secondary dark:text-white font-medium"
            >
              Informations
            </h3>

            <div
              class="gap-4 flex justify-end items-center lg:text-2xl text-xl"
            >
              <router-link
                type="button"
                class="text-zinc-600 dark:text-zinc-400"
                :to="`/packages/overall/more/${packagePrice?.id}/edit?type=${type}&overal-package-box-id=${overalPackageBox.id}&overal-package-box-title=${overalPackageBox.title}&category-id=${category.id}&category-title=${category.title}`"
              >
                <Icon icon="emojione-monotone:fountain-pen" />
              </router-link>
              <button
                type="button"
                class="text-red-500"
                @click="
                  () =>
                    (deleteInfo = {
                      type: 'packagePrice',
                      id: packagePrice?.id,
                    })
                "
                data-te-target="#deleteModal"
                data-te-toggle="modal"
              >
                <Icon icon="entypo:trash" />
              </button>
            </div>
          </div>
          <hr class="border-none w-full h-[2px] bg-zinc-200 dark:bg-zinc-600" />
        </div>

        <!-- Package price -->
        <div class="flex flex-col items-center gap-6">
          <PageHeader
            :title="packagePrice?.title"
            :description="packagePrice?.description"
            :imgUrl="packagePrice?.headerImageUrl"
          ></PageHeader>
          <PageInformation
            v-if="packagePrice?.subTitle || packagePrice?.subDescription"
            :title="packagePrice?.subTitle"
            :description="packagePrice?.subDescription"
          ></PageInformation>
          <router-link
            v-if="packagePrice?.moreServices?.length > 0"
            :to="`/packages/packages-price/more-services?package-id=${packagePrice?.id}&type=overalPackageBox&&is-overal-package-box-exist=true&overal-package-box-id=${overalPackageBox.id}&overal-package-box-title=${overalPackageBox.title}&category-id=${category.id}&category-title=${category.title}&is-more=true`"
            class="inline-flex items-center gap-2 transition-transform duration-300 hover:scale-105 bg-blue-600 text-white shadow-normal rounded-lg font-medium py-3 px-3"
          >
            <span>Visit Service Plans</span>
            <span class="border border-white p-[2px] inline-block rounded-md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-3 h-3 fill-white"
                viewBox="0 0 320 512"
              >
                <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                <path
                  d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"
                />
              </svg>
            </span>
          </router-link>
        </div>

        <div
          v-if="packagePrice?.galleryFiles?.length > 0"
          class="flex slider-gallery mt-3 flex-col gap-5 w-full"
        >
          <h4
            class="font-bold text-black dark:text-zinc-100 xl:text-2xl text-xl max-[522px]:text-center"
          >
            Gallery
          </h4>
          <div
            class="w-full grid min-[1101px]:grid-cols-3 lg:grid-cols-2 md:grid-cols-3 min-[523px]:grid-cols-2 gap-3"
          >
            <div
              v-for="(url, index) in packagePrice?.galleryFiles"
              :key="`slideGallery${index}`"
            >
              <div
                class="w-full rounded-xl overflow-hidden aspect-w-16 aspect-h-9"
              >
                <img
                  v-if="!isVideo(url)"
                  class="w-full h-full object-cover object-center"
                  :src="url"
                  :data-te-img="url"
                  alt="Gallery Images"
                />
                <template v-if="isVideo(url)">
                  <VideoPlayer
                    name="sliderGalleryPlayerCustom"
                    :videoId="`sliderGalleryPlayer${index}`"
                    custom-class="w-full h-full"
                    :source="url"
                    :videoType="getVideoType(url)"
                    poster="/black-poster.jpg"
                  >
                  </VideoPlayer>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="w-full lg:max-w-7xl flex items-center justify-center text-center"
        v-if="!isLoading && !packagePrice"
      >
        <div class="space-y-5 px-4 py-10">
          <div class="w-full flex justify-center items-center">
            <Icon
              class="sm:text-[9rem] text-9xl dark:text-white text-zinc-900 font-bold"
              icon="raphael:package"
            />
          </div>
          <h2
            class="md:text-2xl sm:text-xl xs:text-lg font-medium max-w-md mx-auto dark:text-white text-zinc-900"
          >
            The desired package does not exist.
          </h2>
          <p
            class="dark:text-zinc-400 max-w-2xl mx-auto max-sm:text-sm text-zinc-900"
          >
            Your desired package was not found! Please add a new package.
          </p>
          <router-link
            :to="`/packages/overall/more/add?overal-package-box-id=${overalPackageBox.id}&overal-package-box-title=${overalPackageBox.title}&category-id=${category.id}&category-title=${category.title}`"
            class="inline-flex gap-1 items-center justify-center w-max flex-nowrap bg-primary rounded-lg text-white p-3 text-sm"
          >
            <Icon class="text-base" icon="ic:baseline-plus" />
            New Package
          </router-link>
        </div>
      </div>

      <div
        class="flex justify-center items-center w-full h-full py-10"
        v-if="isLoading"
      >
        <LoadingSpinner class="text-primary dark:text-primary" />
      </div>
    </div>

    <DeleteModal
      mode="normal"
      :info="deleteInfo"
      @deleteSubmit="deleteSubmit"
    />
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";
import axios from "axios";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import settings from "@/settings/appSettings";
import DeleteModal from "@/components/DeleteModal.vue";

import PackageBox from "@/components/PackagePrice/PackageBox";

import PageHeader from "@/components/PageHeader.vue";
import PageInformation from "@/components/PageInformation.vue";

import { getPackagePrice } from "@/components/PackagePrice/modules/packagePrice";

import VideoPlayer from "@/components/VideoPlayer.vue";
import { videoPlayerPoster, videoTypeCheck } from "@/mixins/videoPlayer";

export default {
  mixins: [videoPlayerPoster, videoTypeCheck],
  components: {
    Icon,
    LoadingSpinner,
    PackageBox,
    DeleteModal,
    PageHeader,
    PageInformation,
    VideoPlayer,
  },

  data() {
    return {
      packagePrice: null,
      isLoading: true,
      deleteInfo: null,

      category: {
        id: null,
        title: "",
      },
      overalPackageBox: {
        id: null,
        title: "",
        isMore: false,
      },
      type: "overalPackageBox",
    };
  },
  async created() {
    // When the package price is called by category
    let categoryId = this.$route.query["category-id"];
    let categoryTitle = this.$route.query["category-title"];
    let isCategoryExist = this.$route.query["is-category-exist"];

    this.category.title = categoryTitle;
    this.category.id = categoryId;

    // When the package price is called by overal package box
    let overalPackageBoxId = this.$route.query["overal-package-box-id"];
    let overalPackageBoxTitle = this.$route.query["overal-package-box-title"];
    let isOveralPackageBoxExist =
      this.$route.query["is-overal-package-box-exist"];

    this.overalPackageBox.title = overalPackageBoxTitle;
    this.overalPackageBox.id = overalPackageBoxId;

    if (
      this.type == "overalPackageBox" &&
      (!isOveralPackageBoxExist || isOveralPackageBoxExist == "false")
    ) {
      this.$router.push("/404");
      return;
    }

    let { isLoading, packagePrice } = await getPackagePrice(
      overalPackageBoxId,
      this.type
    );

    if (packagePrice?.moreServices) {
      packagePrice.moreServices = this.groupBy(
        packagePrice.moreServices,
        "category"
      );
    }

    this.packagePrice = packagePrice;

    setTimeout(() => {
      // from videoPlayerPoster mixin
      this.addOverlay(
        '[name="sliderGalleryPlayerCustom"]',
        "slidergallery-poster-overlay"
      );
    }, 100);
    this.isLoading = false;
  },

  methods: {
    deleteSubmit(e) {
      if (e.type == "packagePrice") {
        this.deletePackagePrice(e.id);
      }
    },
    async deletePackagePrice(itemId) {
      this.isLoading = true;

      await axios
        .delete(`PackagePrices/${itemId}`, { isNeedJwtAuth: true })
        .then(async (response) => {
          this.packagePrice = null;
          this.$store.commit("showToast", {
            id: "myToast",
            type: "success",
            title: "Success!",
            body: "Your desired package has been successfully deleted!",
          });

          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response?.data?.isSuccess == false) {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Error!",
              body: error.response?.data?.messages[0],
            });
          } else {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Error!",
              body: "Unfortunately, an unknown error has occurred! Please contact the website support!",
            });
          }

          this.isLoading = false;
          console.log(error);
          console.log(error.response);
        });
    },
  },
};
</script>
<style lang="scss"></style>
