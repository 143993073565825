import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueApexCharts from "vue3-apexcharts";
import { createPinia } from "pinia";
// import "flowbite";
import "./assets/tailwind.css";
import "./assets/animate.css";
import "./assets/fullScreenHeight.css";
import "./assets/sass/css/dashboard.css";
import 'filepond-plugin-media-preview/dist/filepond-plugin-media-preview.min.css';
import vClickOutside from "click-outside-vue3";

import { Vue3Mq } from "vue3-mq";


// Mixins
import customFunction from "./mixins/custom-functions"

import setting from "./settings/appSettings"
import { data } from '@/store/data.js';
import { useUserSignData } from "@/store/userSignData";

import axios from "axios";

// import "@/lib/tw/js/index.min.js"

import {
  refreshTokenInterceptor,
  initialRefreshTokenInterceptor,
  jwtRequestInterceptor,
  initialLogOutMethod,
} from "./lib/axiosJwtInterceptors";

initialRefreshTokenInterceptor({
  refreshTokenUrl: `${setting.backendBaseUrl}/api/v1/Users/RefreshToken`,
});

axios.defaults.baseURL = `${setting.backendBaseUrl}/api/v1/`;

axios.interceptors.request.use(jwtRequestInterceptor, undefined);

axios.interceptors.response.use(
  refreshTokenInterceptor(axios),
  refreshTokenInterceptor(axios)
);

const app = createApp(App);
app.use(data);
app.use(createPinia());

app.mixin(customFunction)

initialLogOutMethod(useUserSignData().logout)

app.use(router);
app.use(VueApexCharts);
app.use(vClickOutside);

app.use(Vue3Mq, {
  preset: 'tailwind'
})

app.mount("#app");
