<template>
  <div class="h-auto p-3 pb-10">
    <nav class="flex flex-col" aria-label="Breadcrumb">
      <ol class="inline-flex flex-wrap items-center">
        <li class="inline-flex items-center">
          <router-link
            to="/"
            class="inline-flex items-center sm:text-sm text-xs font-medium text-zinc-700 hover:text-zinc-950 dark:text-zinc-400 dark:hover:text-white"
          >
            <svg
              class="me-2 w-4 h-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
              ></path>
            </svg>
            Home
          </router-link>
        </li>
        <li>
          <div class="flex items-center">
            <svg
              class="w-6 h-6 text-zinc-400 rtl:rotate-180"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <router-link
              to="/categories"
              class="sm:text-sm text-xs font-medium text-zinc-700 hover:text-zinc-950 dark:text-zinc-400 dark:hover:text-white"
              >Categories List</router-link
            >
          </div>
        </li>
        <li v-if="type == 'overalPackageBox'">
          <div class="flex items-center">
            <svg
              class="w-6 h-6 text-zinc-400 rtl:rotate-180"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <router-link
              :to="`/packages/overall?category-id=${category.id}&is-category-exist=true&category-title=${category.title}`"
              class="sm:text-sm text-xs font-medium text-zinc-700 hover:text-zinc-950 dark:text-zinc-400 dark:hover:text-white"
              >Overall Package</router-link
            >
          </div>
        </li>
        <li v-if="!overalPackageBox.isMore">
          <div class="flex items-center">
            <svg
              class="w-6 h-6 text-zinc-400 rtl:rotate-180"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <router-link
              :to="
                type == 'category'
                  ? `/packages/packages-price?category-id=${category.id}&is-category-exist=true&category-title=${category.title}`
                  : `/packages/packages-price?type=overalPackageBox&&is-overal-package-box-exist=true&overal-package-box-id=${overalPackageBox.id}&overal-package-box-title=${overalPackageBox.title}&category-id=${category.id}&category-title=${category.title}`
              "
              class="sm:text-sm text-xs font-medium text-zinc-700 hover:text-zinc-950 dark:text-zinc-400 dark:hover:text-white"
              >Packages Price</router-link
            >
          </div>
        </li>
        <li v-if="type == 'overalPackageBox' && overalPackageBox.isMore">
          <div class="flex items-center">
            <svg
              class="w-6 h-6 text-zinc-400 rtl:rotate-180"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <router-link
              :to="`/packages/overall/more?is-overal-package-box-exist=true&overal-package-box-id=${overalPackageBox.id}&overal-package-box-title=${overalPackageBox.title}&category-id=${category.id}&category-title=${category.title}`"
              class="sm:text-sm text-xs font-medium text-zinc-700 hover:text-zinc-950 dark:text-zinc-400 dark:hover:text-white"
              >More Package</router-link
            >
          </div>
        </li>
        <li>
          <div class="flex items-center">
            <svg
              class="w-6 h-6 text-zinc-400 rtl:rotate-180"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span
              class="sm:text-sm text-xs font-medium text-zinc-700 dark:text-zinc-400"
              >{{
                type == "overalPackageBox" && overalPackageBox.isMore
                  ? "Services"
                  : "More Services"
              }}</span
            >
          </div>
        </li>
      </ol>

      <div class="mt-5 w-full">
        <h1
          v-if="type == 'category'"
          class="md:text-lg text-base text-zinc-950 font-medium dark:text-zinc-200"
        >
          More Services - Package Price | Category: {{ category.title }}
        </h1>
        <h1
          v-else
          class="md:text-lg text-base text-zinc-950 font-medium dark:text-zinc-200"
        >
          {{
            overalPackageBox.isMore
              ? "Services - More Package | Overall Package Box:"
              : "More Services - Package Price | Overall Package Box:"
          }}

          {{ overalPackageBox.title }}
        </h1>
      </div>
    </nav>

    <div
      class="bg-white dark:bg-zinc-900 rounded-md px-5 py-4 w-full box-border border dark:border-zinc-700 mt-5"
    >
      <div v-show="!isLoading" class="flex flex-col gap-3 mb-5">
        <div class="flex flex-col gap-2 mb-2">
          <div class="flex items-center justify-between gap-6">
            <h3
              class="md:text-lg text-base text-secondary dark:text-white font-medium"
            >
              Informations
            </h3>

            <div
              class="gap-4 flex justify-end items-center lg:text-3xl text-xl"
            >
              <router-link
                v-if="!overalPackageBox.isMore"
                type="button"
                class="text-primary dark:text-white"
                :to="
                  type == 'category'
                    ? `/packages/packages-price?category-id=${category.id}&is-category-exist=true&category-title=${category.title}`
                    : `/packages/packages-price?type=overalPackageBox&&is-overal-package-box-exist=true&overal-package-box-id=${overalPackageBox.id}&overal-package-box-title=${overalPackageBox.title}&category-id=${category.id}&category-title=${category.title}`
                "
              >
                <Icon icon="fluent-emoji-high-contrast:back-arrow" />
              </router-link>
              <router-link
                v-if="type == 'overalPackageBox' && overalPackageBox.isMore"
                type="button"
                class="text-primary dark:text-white"
                :to="`/packages/overall/more?is-overal-package-box-exist=true&overal-package-box-id=${overalPackageBox.id}&overal-package-box-title=${overalPackageBox.title}&category-id=${category.id}&category-title=${category.title}`"
              >
                <Icon icon="fluent-emoji-high-contrast:back-arrow" />
              </router-link>
            </div>
          </div>
          <hr class="border-none w-full h-[2px] bg-zinc-200 dark:bg-zinc-600" />
        </div>

        <!-- Package price -->
        <div class="flex flex-col items-center gap-6">
          <PageHeader
            :title="moreServicesInfo?.title"
            :description="moreServicesInfo?.description"
            :imgUrl="moreServicesInfo?.headerImageUrl"
          ></PageHeader>
          <h4
            v-if="moreServicesInfo?.subTitle"
            class="font-bold text-center text-black dark:text-zinc-100 xl:text-3xl text-2xl"
          >
            {{ moreServicesInfo?.subTitle }}
          </h4>
          <div class="w-full" v-if="packagePrice?.moreServices?.length > 0">
            <h5
              class="mb-3 font-semibold text-black dark:text-zinc-100 xl:text-xl text-lg"
            >
              {{
                type == "overalPackageBox" && overalPackageBox.isMore
                  ? "Services"
                  : "More Services"
              }}
            </h5>
            <div class="grid grid-cols-2 gap-3 max-md:grid-cols-1">
              <ServiceBox
                v-for="(service, index) in packagePrice?.moreServices"
                :key="index"
                :title="service.key"
                :items="service.value"
              ></ServiceBox>
            </div>
          </div>
        </div>
      </div>

      <div
        class="flex justify-center items-center w-full h-full py-10"
        v-if="isLoading"
      >
        <LoadingSpinner class="text-primary dark:text-primary" />
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";
import axios from "axios";
import settings from "@/settings/appSettings";
import PageHeader from "@/components/PageHeader.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import PageInformation from "@/components/PageInformation.vue";
import ServiceBox from "@/components/PackagePrice/ServiceBox.vue";
import { getPackagePrice } from "@/components/PackagePrice/modules/packagePrice";

export default {
  components: {
    Icon,
    PageHeader,
    PageInformation,
    LoadingSpinner,
    ServiceBox,
  },
  data() {
    return {
      packagePrice: null,
      moreServicesInfo: null,
      isLoading: true,
      category: {
        id: null,
        title: "",
      },
      overalPackageBox: {
        id: null,
        title: "",
        isMore: false,
      },
      type: "",
      isAdding: false,
    };
  },
  async created() {
    this.type = this.$route.query["type"] ?? "category";
    this.overalPackageBox.isMore = this.$route.query["is-more"] ?? false;

    // When the package price is called by category
    let categoryId = this.$route.query["category-id"];
    let categoryTitle = this.$route.query["category-title"];
    let isCategoryExist = this.$route.query["is-category-exist"];

    this.category.title = categoryTitle;
    this.category.id = categoryId;

    // When the package price is called by overal package box
    let overalPackageBoxId = this.$route.query["overal-package-box-id"];
    let overalPackageBoxTitle = this.$route.query["overal-package-box-title"];
    let isOveralPackageBoxExist =
      this.$route.query["is-overal-package-box-exist"];

    this.overalPackageBox.title = overalPackageBoxTitle;
    this.overalPackageBox.id = overalPackageBoxId;

    if (
      this.type == "category" &&
      (!isCategoryExist || isCategoryExist == "false")
    ) {
      this.$router.push("/404");
      return;
    }
    if (
      this.type == "overalPackageBox" &&
      (!isOveralPackageBoxExist || isOveralPackageBoxExist == "false")
    ) {
      this.$router.push("/404");
      return;
    }

    let { isLoading, packagePrice } = await getPackagePrice(
      this.type == "category" ? categoryId : overalPackageBoxId,
      this.type
    );

    if (packagePrice?.moreServices) {
      packagePrice.moreServices = this.groupBy(
        packagePrice.moreServices,
        "category"
      );
    }

    this.packagePrice = packagePrice;

    if (this.packagePrice) {
      let isInfoLoading = await this.getMoreServicesInfo(this.packagePrice.id);

      if (!isLoading && !isInfoLoading) {
        this.isLoading = false;
      }
    }

    this.isLoading = false;
  },
  methods: {
    async getMoreServicesInfo(id) {
      this.moreServicesInfo = null;
      let isLoading = true;
      const url = `PackagePrices/moreServiceInfo/${id}`;

      try {
        const httpResponse = await axios.get(url);
        this.moreServicesInfo = httpResponse.data.value;

        this.moreServicesInfo.headerImageUrl = `${settings.backendBaseUrl}/${this.moreServicesInfo.headerImageUrl}`;

        isLoading = false;
      } catch (error) {
        console.log(error);
        isLoading = false;
      }

      return isLoading;
    },
  },
};
</script>
