<template>
  <!-- Contact box -->
  <div
    :id="id"
    class="card w-full h-full p-3 bg-zinc-100 hover:bg-zinc-200 rounded-md hover:border-zinc-300 border-zinc-200 border hover:dark:bg-zinc-700 dark:bg-zinc-800 hover:dark:border-zinc-800 dark:border-zinc-700 cursor-pointer"
    :class="
      hasRead
        ? 'bg-zinc-200/70 border-zinc-300/70 dark:bg-zinc-800/50 dark:border-zinc-700/50'
        : ''
    "
    data-te-target="#contactInfo"
    data-te-toggle="modal"
    @click="openContactModal"
  >
    <div
      class="flex flex-col gap-3 items-start font-medium text-zinc-700 dark:text-zinc-400 md:text-sm text-xs"
      :class="hasRead ? 'text-zinc-700/50 dark:text-zinc-400/40' : ''"
    >
      <div class="flex items-center md:gap-3 gap-2 justify-between w-full">
        <!-- Name -->
        <h5 class="line-clamp-1 grow">
          {{ subject }}
        </h5>

        <h6 class="max-md:hidden break-all">
          {{ phoneNumber }}
        </h6>

        <span
          v-if="phoneNumber"
          class="max-md:hidden h-4 w-[1px] bg-zinc-700 dark:bg-zinc-400"
        ></span>

        <h6 class="max-[550px]:hidden break-all">
          {{ email }}
        </h6>

        <span
          v-if="email"
          class="max-[550px]:hidden h-4 w-[1px] bg-zinc-700 dark:bg-zinc-400"
        ></span>

        <!-- Date -->
        <h6 class="whitespace-nowrap [direction:ltr]">
          {{ dateToStructuredDate(createdDate) }}
        </h6>
      </div>

      <p class="sm:line-clamp-1 line-clamp-2 max-xs:text-xs">
        {{ description }}
      </p>

      <div class="w-full flex items-center justify-end">
        <button
          type="button"
          class="text-red-500 text-lg"
          @click="$emit('deleteClick')"
          data-te-target="#deleteModal"
          data-te-toggle="modal"
        >
          <Icon icon="entypo:trash" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Icon } from "@iconify/vue";

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
    firstName: {
      type: String,
      required: true,
    },
    lastName: {
      type: String,
      required: true,
    },
    phoneNumber: {
      type: String,
      required: false,
    },
    email: {
      type: String,
      required: true,
    },
    subject: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    createdDate: {
      type: String,
      required: true,
    },
    hasRead: {
      type: Boolean,
      required: false,
    },
  },
  components: { Icon },
  methods: {
    async openContactModal() {
      let url = `Requests/GetOne?id=${this.id}&dateFormat=o`;

      try {
        let httpResponse = await axios.get(url, { isNeedJwtAuth: true });

        let contact = httpResponse.data?.value;

        console.log(contact);
        this.$store.commit("addContactInfo", {
          id: this.id,
          firstName: contact.firstName,
          lastName: contact.lastName,
          email: contact.email,
          phoneNumber: contact.phoneNumber,
          description: contact.description,
          hasRead: contact.hasRead,
          userSelectedDate: contact.userSelectedDate,
          createdDate: contact.createdDate,
          subject: contact.subject,
        });

        console.log(this.contact);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
