<template>
  <video-player
    class="video-player vjs-custom"
    :ref="videoId"
    :id="videoId"
    :options="playerOptions"
    :playsinline="true"
    controls
  >
  </video-player>
</template>

<script>
import { VideoPlayer } from "@videojs-player/vue";
import "video.js/dist/video-js.css";

export default {
  components: {
    VideoPlayer,
  },
  data() {
    return {
      playerOptions: {
        // videojs options
        muted: false,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: this.videoType,
            src: this.source,
          },
        ],
        poster: this.poster,
        preload: "none",
      },
    };
  },
  props: {
    source: {
      type: String,
      required: true,
    },
    poster: {
      type: String,
      required: false,
    },
    customClass: {
      type: String,
      required: false,
    },
    videoId: {
      type: String,
      required: true,
    },
    videoType: {
      type: String,
      required: true,
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.video-js {
  width: 100%;
  height: 100%;
}
.vjs-custom {
  .video-js:hover .vjs-big-play-button,
  .video-js .vjs-big-play-button:focus {
    background-color: rgb(37 99 235 / 0.5);
    transition: all 300ms;
  }
  .vjs-big-play-button {
    background-color: rgb(37 99 235 / 0.5);
    border-color: #e1cbef;
    cursor: pointer;
    width: 75px;
    height: 75px;
    transition: all 300ms;
    border-radius: 9999px;
    inset: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 10;
    backdrop-filter: blur(5px);

    span {
      color: #e1cbef;
      display: flex;
      transform: scale(1.5);

      &::before {
        position: relative !important;
      }
    }
  }
  .vjs-poster {
    background-size: cover;
  }
}
</style>
