import axios from "axios";
import settings from "@/settings/appSettings";

export async function getOverallPackage(id) {
  let overallPackage = null;
  let isLoading = true;

  let url = `OveralPackages?CategoryId=${id}`;

  try {
    let httpResponse = await axios.get(url);

    overallPackage = httpResponse.data.value[0];

    // Set baseUrl before header image url
    overallPackage.headerImageUrl = `${settings.backendBaseUrl}/${overallPackage.headerImageUrl}`;

    overallPackage?.overalPackageBoxes.forEach((box) => {
      let imageUrls = [];
      // Set baseUrl before image urls
      box?.imageUrls?.forEach((url) => {
        // Set baseUrl before image urls
        imageUrls.push(`${settings.backendBaseUrl}/${url}`);
      });

      box.imageUrls = imageUrls;
    });

    isLoading = false;
  } catch (error) {
    console.log(error);
    isLoading = false;
  }

  return {
    isLoading,
    overallPackage,
  };
}
