const customFunction = {
  methods: {
    capitalizeFirstLetter(string) {
      const word = string;
      const firstLetter = word.charAt(0).toUpperCase();
      const remainingLetters = word.slice(1);
      const capitalizeWord = firstLetter + remainingLetters;
      return capitalizeWord;
    },

    isBrightColor(hexCode) {
      if (hexCode.startsWith("#")) {
        hexCode = hexCode.slice(1);
      }

      const red = parseInt(hexCode.substr(0, 2), 16);
      const green = parseInt(hexCode.substr(2, 2), 16);
      const blue = parseInt(hexCode.substr(4, 2), 16);

      const brightness = (red + green + blue) / 3;

      return brightness >= 128;
    },

    toStructuredNum(num, zone = "fa") {
      if (!num?.toString()) {
        return;
      }

      return new Intl.NumberFormat(zone).format(num.toString());
    },

    toEnglishNum(num) {
      if (!num?.toString()) {
        return;
      }

      return num
        .replace(/[\u0660-\u0669]/g, function (c) {
          return c.charCodeAt(0) - 0x0660;
        })
        .replace(/[\u06f0-\u06f9]/g, function (c) {
          return c.charCodeAt(0) - 0x06f0;
        });
    },

    limitString(string, length) {
      return string.length > length
        ? string.substring(0, length) + "..."
        : string;
    },

    dateToLocaleString(date, timeZoneName) {
      var localDate = new Date(date).toLocaleString("en-US", {
        timeZone: timeZoneName,
      });

      return localDate;
    },

    dateToStructuredDate(inputDate, format) {
      const fixedDate = new Date(inputDate);
      const locale = navigator.language;

      const options = {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        hour: "2-digit",
        minute: "2-digit",
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      };

      const resultDate = new Intl.DateTimeFormat(locale, options).format(
        fixedDate
      );

      return resultDate;
    },

    distinct(array) {
      let jsonObject = array.map(JSON.stringify);
      let uniqueSet = new Set(jsonObject);
      let uniqueArray = Array.from(uniqueSet).map(JSON.parse);

      return uniqueArray;
    },

    removeByIndex(str, index) {
      return str.slice(0, index) + str.slice(index + 1);
    },
    removeItemObjectWithId(array, id) {
      const objWithIdIndex = array.findIndex((obj) => obj.id == id);

      if (objWithIdIndex > -1) {
        array.splice(objWithIdIndex, 1);
      }

      return array;
    },
    groupBy(array, filter) {
      if (array?.length == 0) {
        return [];
      }

      const groupArrayObject = array.reduce((group, currentObj) => {
        const key = currentObj[filter];

        if (!group[key]) group[key] = [];

        group[key].push(currentObj);

        return group;
      }, {});

      let finalResult = [];

      for (const [key, value] of Object.entries(groupArrayObject)) {
        finalResult.push({
          key: key,
          value: value,
        });
      }

      return finalResult;
    },
    deepEqual(obj1, obj2) {
      return JSON.stringify(obj1) === JSON.stringify(obj2);
    },
    findChangedItems(originalArray, newArray) {
      return newArray.filter(
        (newItem, index) => !this.deepEqual(newItem, originalArray[index])
      );
    },
    async convertUrlToBlob(url) {
      const response = await fetch(url);
      return await response.blob();
    },
  },
};

export default customFunction;
