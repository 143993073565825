<template>
  <div class="h-auto p-3 pb-10">
    <nav class="flex flex-col" aria-label="Breadcrumb">
      <ol class="inline-flex flex-wrap items-center">
        <li class="inline-flex items-center">
          <router-link
            to="/"
            class="inline-flex items-center sm:text-sm text-xs font-medium text-zinc-700 hover:text-zinc-950 dark:text-zinc-400 dark:hover:text-white"
          >
            <svg
              class="me-2 w-4 h-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
              ></path>
            </svg>
            Home
          </router-link>
        </li>
        <li>
          <div class="flex items-center">
            <svg
              class="w-6 h-6 text-zinc-400 rtl:rotate-180"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <router-link
              to="/categories"
              class="sm:text-sm text-xs font-medium text-zinc-700 hover:text-zinc-950 dark:text-zinc-400 dark:hover:text-white"
              >Categories List</router-link
            >
          </div>
        </li>
        <li>
          <div class="flex items-center">
            <svg
              class="w-6 h-6 text-zinc-400 rtl:rotate-180"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span
              class="sm:text-sm text-xs font-medium text-zinc-700 dark:text-zinc-400"
              >Overall Package</span
            >
          </div>
        </li>
      </ol>

      <div class="mt-5 w-full">
        <h1
          class="md:text-lg text-base text-zinc-950 font-medium dark:text-zinc-200"
        >
          Overall Package | {{ category.title }}
        </h1>
      </div>
    </nav>

    <div
      class="bg-white dark:bg-zinc-900 rounded-md p-5 w-full box-border border dark:border-zinc-700 mt-5"
    >
      <div v-show="!isLoading && overallPackage">
        <div class="informations flex flex-col gap-6 mb-5">
          <div class="flex flex-col gap-3">
            <div class="flex flex-col gap-2 mb-2">
              <div class="flex items-center justify-between gap-6">
                <h3
                  class="md:text-lg text-base text-secondary dark:text-white font-medium"
                >
                  Informations
                </h3>

                <div
                  class="gap-4 flex justify-end items-center lg:text-2xl text-xl"
                >
                  <router-link
                    type="button"
                    class="text-zinc-600 dark:text-zinc-400"
                    :to="`/packages/overall/${overallPackage?.id}/edit?category-id=${category.id}&category-title=${category.title}`"
                  >
                    <Icon icon="emojione-monotone:fountain-pen" />
                  </router-link>
                  <button
                    type="button"
                    class="text-red-500"
                    @click="
                      () =>
                        (deleteInfo = {
                          type: 'overallPackage',
                          id: overallPackage?.id,
                        })
                    "
                    data-te-target="#deleteModal"
                    data-te-toggle="modal"
                  >
                    <Icon icon="entypo:trash" />
                  </button>
                </div>
              </div>
              <hr
                class="border-none w-full h-[2px] bg-zinc-200 dark:bg-zinc-600"
              />
            </div>

            <div class="flex flex-col items-center gap-6">
              <PageHeader
                :title="overallPackage?.title"
                :description="overallPackage?.description"
                :imgUrl="overallPackage?.headerImageUrl"
              ></PageHeader>
              <PageInformation
                v-if="
                  overallPackage?.subTitle || overallPackage?.subDescription
                "
                :title="overallPackage?.subTitle"
                :description="overallPackage?.subDescription"
              ></PageInformation>

              <div
                v-if="overallPackage?.overalPackageBoxes?.length > 0"
                class="grid w-full min-[1350px]:grid-cols-3 lg:grid-cols-2 min-[950px]:grid-cols-3 sm:grid-cols-2 gap-5"
              >
                <PackageBox
                  v-for="(item, index) in overallPackage?.overalPackageBoxes"
                  :key="item.id"
                  :id="item.id"
                  :title="item.title"
                  :content="item.description"
                  :categoryId="category.id"
                  :categoryTitle="category.title"
                  :isMore="item.isMore"
                >
                </PackageBox>
              </div>

              <div
                v-if="overallPackage?.overalPackageBoxes?.length == 0"
                class="w-full h-full text-center my-10 flex justify-center items-center text-xl max-sm:text-base"
              >
                <span class="dark:text-white text-black"
                  >Overall package box is empty. Please edit your package.</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="w-full lg:max-w-7xl flex items-center justify-center text-center"
        v-show="!isLoading && !overallPackage"
      >
        <div class="space-y-5 px-4 py-10">
          <div class="w-full flex justify-center items-center">
            <Icon
              class="sm:text-[9rem] text-9xl dark:text-white text-zinc-900 font-bold"
              icon="raphael:package"
            />
          </div>
          <h2
            class="md:text-2xl sm:text-xl xs:text-lg font-medium max-w-md mx-auto dark:text-white text-zinc-900"
          >
            The desired package does not exist.
          </h2>
          <p
            class="dark:text-zinc-400 max-w-2xl mx-auto max-sm:text-sm text-zinc-900"
          >
            Your desired overallPackage package was not found! Please add a new
            package.
          </p>
          <router-link
            :to="`/packages/overall/add?category-id=${category.id}&category-title=${category.title}`"
            class="inline-flex gap-1 items-center justify-center w-max flex-nowrap bg-primary rounded-lg text-white p-3 text-sm"
          >
            <Icon class="text-base" icon="ic:baseline-plus" />
            New Package
          </router-link>
        </div>
      </div>

      <div
        class="flex justify-center items-center w-full h-full py-10"
        v-if="isLoading"
      >
        <LoadingSpinner class="text-primary dark:text-primary" />
      </div>
    </div>

    <DeleteModal
      mode="normal"
      :info="deleteInfo"
      @deleteSubmit="deleteSubmit"
    />
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";
import axios from "axios";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import DeleteModal from "@/components/DeleteModal.vue";

import PackageBox from "@/components/OverallPackage/PackageBox";
import PageHeader from "@/components/PageHeader.vue";
import PageInformation from "@/components/PageInformation.vue";

import { getOverallPackage } from "@/components/OverallPackage/modules/overallPackage";

export default {
  components: {
    Icon,
    LoadingSpinner,
    PackageBox,
    DeleteModal,
    PageHeader,
    PageInformation,
  },
  data() {
    return {
      overallPackage: null,
      isLoading: true,
      deleteInfo: null,

      category: {
        id: null,
        title: "",
      },
    };
  },

  async created() {
    let categoryId = this.$route.query["category-id"];
    let isCategoryExist = this.$route.query["is-category-exist"];

    if (!isCategoryExist || isCategoryExist == "false") {
      this.$router.push("/404");
      return;
    }

    this.category.title = this.$route.query["category-title"];
    this.category.id = categoryId;

    let { isLoading, overallPackage } = await getOverallPackage(categoryId);

    this.overallPackage = overallPackage;

    this.isLoading = false;
  },

  methods: {
    deleteSubmit(e) {
      if (e.type == "overallPackage") {
        this.deleteOverallPackage(e.id);
      }
    },
    async deleteOverallPackage(itemId) {
      this.isLoading = true;

      await axios
        .delete(`OveralPackages/${itemId}`, { isNeedJwtAuth: true })
        .then(async (response) => {
          this.overallPackage = null;
          this.$store.commit("showToast", {
            id: "myToast",
            type: "success",
            title: "Success!",
            body: "Your desired overallPackage package has been successfully deleted!",
          });

          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response?.data?.isSuccess == false) {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Error!",
              body: error.response?.data?.messages[0],
            });
          } else {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Error!",
              body: "Unfortunately, an unknown error has occurred! Please contact the website support!",
            });
          }

          this.isLoading = false;
          console.log(error);
          console.log(error.response);
        });
    },
  },
};
</script>
