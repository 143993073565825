<template>
  <div
    class="w-full flex max-sm:flex-col h-full p-1 border-2 border-zinc-300 dark:border-zinc-800 dark:text-white rounded-2xl bg-zinc-200 dark:bg-zinc-950"
  >
    <div
      :id="`carouselProjects${id}`"
      class="relative w-full h-[320px] max-xl:h-[270px] max-lg:h-[300px] max-md:h-[270px] max-sm:h-[300px] max-xs:h-[220px] max-2xs:h-[180px] rounded-xl overflow-hidden"
      data-te-carousel-init
      data-te-interval="false"
      data-te-ride="carousel"
    >
      <!--Carousel items-->
      <div
        class="relative w-full h-full after:clear-both after:block after:content-['']"
      >
        <template v-for="(fileUrl, index) in gallery" :key="index">
          <div
            v-if="index == 0"
            class="relative float-left -mr-[100%] w-full h-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
            data-te-carousel-active
            data-te-carousel-item
            style="backface-visibility: hidden"
          >
            <img
              v-if="!isVideo(fileUrl)"
              :src="fileUrl"
              alt="project image"
              class="w-full h-full rounded-xl object-cover object-center"
            />
            <template v-if="isVideo(fileUrl)">
              <VideoPlayer
                :source="fileUrl"
                :videoId="`projectPlayer${id}${index}`"
                :videoType="getVideoType(fileUrl)"
                poster="/black-poster.jpg"
              >
                >
              </VideoPlayer>
            </template>
          </div>
          <div
            v-if="index > 0"
            class="relative float-left hidden -mr-[100%] w-full h-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
            data-te-carousel-item
            style="backface-visibility: hidden"
          >
            <img
              v-if="!isVideo(fileUrl)"
              :src="fileUrl"
              alt="project image"
              class="w-full h-full rounded-xl object-cover object-center"
            />

            <template v-if="isVideo(fileUrl)">
              <VideoPlayer
                :source="fileUrl"
                :videoId="`projectPlayer${id}${index}`"
                :videoType="getVideoType(fileUrl)"
                poster="/black-poster.jpg"
              >
                >
              </VideoPlayer>
            </template>
          </div>
        </template>
      </div>

      <!--Carousel controls - prev item-->
      <div
        class="h-full absolute left-[10px] top-0 z-[1] flex items-center justify-center"
      >
        <button
          class="flex w-fit h-fit items-center justify-center px-[1px] py-[9px] text-center text-white rounded-full bg-black"
          type="button"
          :data-te-target="`#carouselProjects${id}`"
          data-te-slide="prev"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="h-6 w-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 19.5L8.25 12l7.5-7.5"
            />
          </svg>
        </button>
      </div>

      <!--Carousel controls - next item-->
      <div
        class="absolute right-[10px] top-0 z-[1] h-full flex items-center justify-center"
      >
        <button
          class="flex w-fit h-fit items-center justify-center px-[1px] py-[9px] text-center text-white rounded-full bg-black"
          type="button"
          :data-te-target="`#carouselProjects${id}`"
          data-te-slide="next"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="h-6 w-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M8.25 4.5l7.5 7.5-7.5 7.5"
            />
          </svg>
        </button>
      </div>
    </div>

    <div
      class="relative text-black dark:text-white py-6 max-md:py-4 px-9 max-md:px-6 max-sm:p-4 max-xs:px-3 w-full max-w-[450px] max-xl:max-w-[350px] max-md:max-w-[270px] max-sm:max-w-none gap-8 max-lg:gap-5 max-xs:gap-3 flex flex-col justify-center items-center"
    >
      <!-- Title -->
      <h4
        class="font-bold text-center text-2xl max-xl:text-xl max-lg:text-lg max-xs:text-base line-clamp-1"
      >
        {{ title }}
      </h4>

      <p
        class="text-xs mb-9 line-clamp-5 max-md:line-clamp-4 text-center font-light"
      >
        {{ content }}
      </p>

      <slot name="more"></slot>
    </div>
  </div>
</template>

<script>
import { Carousel, initTE } from "tw-elements";
import VideoPlayer from "@/components/VideoPlayer.vue";
import { videoPlayerPoster, videoTypeCheck } from "@/mixins/videoPlayer";
export default {
  mixins: [videoPlayerPoster, videoTypeCheck],
  props: {
    title: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: false,
    },
    content: {
      type: String,
      required: true,
    },
    gallery: {
      type: Array,
      required: true,
    },
  },
  components: {
    VideoPlayer,
  },
  mounted() {
    setTimeout(() => {
      initTE({ Carousel });
    }, 1000);

    // from videoPlayerPoster mixin
    this.addOverlay('[id*="projectPlayer"]', "project-poster-overlay");
  },
};
</script>

<style lang="scss">
.carouselProjects {
  .vjs-custom {
    @media screen and (max-width: 1024px) {
      .vjs-big-play-button {
        width: 65px;
        height: 65px;

        span {
          display: flex;
          color: #e1cbef;
          transform: scale(1.3);
        }
      }
    }
    @media screen and (max-width: 768px) {
      .vjs-big-play-button {
        width: 80px;
        height: 80px;

        span {
          display: flex;
          color: #e1cbef;
          transform: scale(1.7);
        }
      }
    }
    @media screen and (max-width: 640px) {
      .vjs-big-play-button {
        width: 65px;
        height: 65px;

        span {
          display: flex;
          color: #e1cbef;
          transform: scale(1.3);
        }
      }
    }
    @media screen and (max-width: 425px) {
      .vjs-big-play-button {
        width: 50px;
        height: 50px;

        span {
          display: flex;
          color: #e1cbef;
          transform: scale(1);
        }
      }
    }
  }

  .project-poster-overlay {
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.4);
  }
}
</style>
