import axios from "axios";

async function updateImage(imageFile, key, url, imageQuality = 85, imageFormat = "webp") {
    let bodyFormData = new FormData();

    if (!imageFile) {
        return;
    }

    bodyFormData.append(key, imageFile, imageFile.name);
    bodyFormData.append("OptimizeImage.Quality", imageQuality);
    bodyFormData.append("OptimizeImage.ImageExtensions", imageFormat);

    let options = {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        isNeedJwtAuth: true,
    };

    await axios
        .patch(url, bodyFormData, options)
        .then((response) => { })
        .catch((error) => {
            console.log(error);
        });
}

async function updateImages(imageFiles, key, url, imageQuality = 85, imageFormat = "webp") {
    let bodyFormData = new FormData();

    if (!imageFiles || imageFiles.length == 0) {
        return;
    }

    imageFiles.forEach(imageFile => {
        bodyFormData.append(key, imageFile, imageFile.name);
    });

    bodyFormData.append("OptimizeImage.Quality", imageQuality);
    bodyFormData.append("OptimizeImage.ImageExtensions", imageFormat);

    let options = {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        isNeedJwtAuth: true,
    };

    await axios
        .patch(url, bodyFormData, options)
        .then((response) => { })
        .catch((error) => {
            console.log(error);
        });
}

export { updateImage, updateImages }