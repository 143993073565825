<template>
  <div
    class="w-full h-full p-1 border-2 border-zinc-300 dark:border-zinc-800 dark:text-white rounded-2xl bg-zinc-200 dark:bg-zinc-950 font-[inter]"
  >
    <div
      class="w-full gap-3 relative bg-white dark:bg-zinc-800 p-2 pt-5 rounded-xl grid grid-cols-2 max-[1440px]:grid-cols-1 max-md:grid-cols-2 max-[530px]:grid-cols-1 "
    >
      <!-- Title -->
      <slot name="file"></slot>
    </div>

    <div class="p-1 pt-2">
      <slot name="more"></slot>
    </div>
  </div>
</template>
