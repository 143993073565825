<template>
  <div class="h-auto p-3 pb-10">
    <nav class="flex flex-col" aria-label="Breadcrumb">
      <ol class="inline-flex flex-wrap items-center">
        <li class="inline-flex items-center">
          <router-link
            to="/"
            class="inline-flex items-center sm:text-sm text-xs font-medium text-zinc-700 hover:text-zinc-950 dark:text-zinc-400 dark:hover:text-white"
          >
            <svg
              class="me-2 w-4 h-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
              ></path>
            </svg>
            Home
          </router-link>
        </li>
        <li>
          <div class="flex items-center">
            <svg
              class="w-6 h-6 text-zinc-400 rtl:rotate-180"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <router-link
              to="/categories"
              class="sm:text-sm text-xs font-medium text-zinc-700 hover:text-zinc-950 dark:text-zinc-400 dark:hover:text-white"
              >Categories List</router-link
            >
          </div>
        </li>
        <li>
          <div class="flex items-center">
            <svg
              class="w-6 h-6 text-zinc-400 rtl:rotate-180"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <router-link
              :to="`/packages/overall?category-id=${category.id}&is-category-exist=true&category-title=${category.title}`"
              class="sm:text-sm text-xs font-medium text-zinc-700 hover:text-zinc-950 dark:text-zinc-400 dark:hover:text-white"
              >Overall Package</router-link
            >
          </div>
        </li>
        <li>
          <div class="flex items-center">
            <svg
              class="w-6 h-6 text-zinc-400 rtl:rotate-180"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span
              class="sm:text-sm text-xs font-medium text-zinc-700 dark:text-zinc-400"
              >Edit Overall Package</span
            >
          </div>
        </li>
      </ol>

      <div class="mt-5 w-full">
        <h1
          class="md:text-lg text-base text-zinc-950 font-medium dark:text-zinc-200"
        >
          Edit Overall Package | {{ category.title }}
        </h1>
      </div>
    </nav>

    <div
      class="bg-white dark:bg-zinc-900 rounded-md p-5 w-full box-border border dark:border-zinc-700 mt-5"
    >
      <form
        @submit.prevent="updateOverallPackage"
        v-show="!isLoading"
        class="informations flex flex-col"
      >
        <div class="flex flex-col gap-7 mb-5">
          <div class="flex flex-col gap-2">
            <div class="flex flex-col gap-2 mb-2">
              <h3
                class="md:text-lg text-base text-secondary dark:text-white font-medium"
              >
                Header Informations
              </h3>
              <hr
                class="border-none w-full h-[2px] bg-zinc-200 dark:bg-zinc-600"
              />
            </div>

            <Input
              class="w-full required"
              type="text"
              placeholder="Title"
              v-model="overallPackage.title"
              :isRequired="true"
            ></Input>

            <Input
              type="textarea"
              textareaRows="3"
              placeholder="Description"
              v-model="overallPackage.description"
              :isRequired="true"
              class="required"
            ></Input>

            <div class="flex flex-col gap-2 w-full required">
              <h6 class="font-medium text-sm text-zinc-600 dark:text-zinc-400">
                Header Image
              </h6>
              <FileUploader
                ref="headerImageFileUploader"
                @updatePendingFiles="
                  updatePendingFiles($event, 'headerImageUrl')
                "
              ></FileUploader>
            </div>
          </div>

          <div class="flex flex-col gap-3">
            <div class="flex flex-col gap-2 mb-2">
              <h3
                class="md:text-lg text-base text-secondary dark:text-white font-medium"
              >
                Sub Header Informations
              </h3>
              <hr
                class="border-none w-full h-[2px] bg-zinc-200 dark:bg-zinc-600"
              />
            </div>

            <Input
              class="w-full"
              type="text"
              placeholder="Sub Title"
              v-model="overallPackage.subTitle"
              :isRequired="false"
            ></Input>

            <Input
              type="textarea"
              textareaRows="3"
              placeholder="Sub Description"
              v-model="overallPackage.subDescription"
              :isRequired="false"
            ></Input>
          </div>

          <div class="flex flex-col gap-2">
            <div class="flex flex-col gap-2 mb-2">
              <div class="required">
                <h6
                  class="md:text-lg text-base text-secondary dark:text-white font-medium"
                >
                  Package Boxes
                </h6>
              </div>
              <hr
                class="border-none w-full h-[2px] bg-zinc-200 dark:bg-zinc-600"
              />
            </div>

            <div
              class="grid min-[2100px]:grid-cols-4 min-[1600px]:grid-cols-3 min-[1150px]:grid-cols-3 lg:grid-cols-2 min-[950px]:grid-cols-3 min-[600px]:grid-cols-2 gap-5"
            >
              <PackageBox
                v-for="(box, index) in overallPackage.overalPackageBoxes"
                mode="editable"
              >
                <template v-slot:title>
                  <div class="flex flex-col items-center justify-center gap-5">
                    <div
                      v-if="overallPackage?.overalPackageBoxes?.length > 1"
                      class="absolute top-2 end-2"
                    >
                      <button
                        v-if="
                          box.isInitialized &&
                          overallPackage?.overalPackageBoxes.filter(
                            (box) => box.isInitialized == true
                          ).length > 1
                        "
                        type="button"
                        class="text-red-500 w-6 h-6 inline-flex items-center justify-center p-1 border border-red-500 rounded-full"
                        @click="
                          () =>
                            (deleteInfo = {
                              type: 'overallPackageBox',
                              id: box?.id,
                            })
                        "
                        data-te-target="#deleteModal"
                        data-te-toggle="modal"
                      >
                        <Icon icon="entypo:trash" />
                      </button>
                      <button
                        v-else-if="!box.isInitialized"
                        type="button"
                        class="text-red-500 w-6 h-6 inline-flex items-center justify-center p-1 border border-red-500 rounded-full"
                        @click="deleteCurrentPackageBox(box.id)"
                      >
                        <Icon icon="entypo:trash" />
                      </button>
                    </div>
                    <Input
                      :isTitleShow="false"
                      class="max-w-max required"
                      type="text"
                      placeholder="Title"
                      v-model="box.title"
                      :isRequired="true"
                      outer-input-class="!rounded-xl sm:!text-lg !text-base"
                    ></Input>
                    <div class="flex items-center gap-2">
                      <input
                        :id="`isMore${index}`"
                        v-model="box.isMore"
                        class="custom-checkbox"
                        type="checkbox"
                        @change="morePackageChange($event, box)"
                      />

                      <label
                        class="inline-block text-sm text-black dark:text-white hover:cursor-pointer"
                        :for="`isMore${index}`"
                      >
                        Type: More Package
                      </label>
                    </div>
                  </div>
                </template>
                <template v-slot:more>
                  <Input
                    :isTitleShow="false"
                    type="textarea"
                    textareaRows="6"
                    placeholder="Description"
                    v-model="box.description"
                    :isRequired="true"
                    class="required"
                    input-class="dark:!bg-zinc-900"
                    outer-input-class="!rounded-xl sm:!text-sm !text-xs dark:!border-zinc-800"
                  ></Input>
                </template>
              </PackageBox>
              <div
                class="w-full h-full p-1 rounded-2xl border-2 border-zinc-300/60 dark:border-zinc-800/60 dark:text-white/60 rounded-2xl/60 bg-zinc-200/60 dark:bg-zinc-950/60"
              >
                <div
                  class="w-full h-full relative bg-white/60 dark:bg-zinc-800/60 px-4 py-16 rounded-xl flex justify-center items-center"
                >
                  <button
                    @click="addNewPackageBox"
                    type="button"
                    class="text-white h-fit text-4xl bg-primary rounded-md hover:bg-primary/80 transition-all duration-300 focus:outline-none py-2 px-4 w-fit disabled:opacity-60 disabled:pointer-events-none"
                  >
                    <Icon icon="mdi:package-variant-closed-add" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr class="w-full border dark:border-zinc-700" />
        <div class="wrapper-button sm:text-base text-sm flex justify-end mt-5">
          <button
            v-if="!isAdding"
            :disabled="
              overallPackage.title?.length == 0 ||
              overallPackage.description?.length == 0 ||
              !overallPackage.headerImageUrl
            "
            type="submit"
            class="text-white bg-primary rounded-md hover:bg-primary/80 transition-all duration-300 focus:outline-none py-2 px-6 w-fit disabled:opacity-60 disabled:pointer-events-none"
          >
            Save
          </button>

          <button
            v-else
            disabled
            type="button"
            class="text-white bg-primary rounded-md opacity-60 cursor-default transition-all duration-300 focus:outline-none py-2 px-6 w-fit"
          >
            <svg
              role="status"
              class="inline w-4 h-4 me-1 text-white animate-spin"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="#E5E7EB"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentColor"
              />
            </svg>
            Uploading...
          </button>
        </div>
      </form>

      <div
        class="flex justify-center items-center w-full h-full py-10"
        v-if="isLoading"
      >
        <LoadingSpinner class="text-primary dark:text-primary" />
      </div>
    </div>

    <DeleteModal
      mode="sample"
      :info="deleteInfo"
      @deleteSubmit="deleteSubmit"
    />
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";
import axios from "axios";
import Input from "@/components/Input.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import FilterBox from "@/components/FilterBox.vue";
import FileUploader from "@/components/FileUploader.vue";
import DeleteModal from "@/components/DeleteModal.vue";

import PackageBox from "@/components/OverallPackage/PackageBox";

import { getOverallPackage } from "@/components/OverallPackage/modules/overallPackage";

import settings from "@/settings/appSettings";

import { updateImage, updateImages } from "@/modules/updateImage";

export default {
  components: {
    Icon,
    FileUploader,
    Input,
    PackageBox,
    FilterBox,
    DeleteModal,
    LoadingSpinner,
  },

  data() {
    return {
      overallPackage: {},
      category: {
        id: null,
        title: "",
      },
      isAdding: false,
      deleteInfo: null,
      isLoading: true,
    };
  },

  async created() {
    let categoryId = this.$route.query["category-id"];
    let categoryTitle = this.$route.query["category-title"];

    this.category.title = categoryTitle;
    this.category.id = categoryId;

    let { isLoading, overallPackage } = await getOverallPackage(categoryId);

    if (!overallPackage) {
      this.$router.push("/404");
      return;
    }

    overallPackage.overalPackageBoxes.forEach((box) => {
      box.isInitialized = true;
    });

    this.overallPackage = overallPackage;

    this.overallPackage.overalPackageBoxesInitial = JSON.parse(
      JSON.stringify(this.overallPackage.overalPackageBoxes)
    );

     // Add image to header image file uploader
     if (this.overallPackage.headerImageUrl)
      this.$refs.headerImageFileUploader.refPound.addFile(
        `${this.overallPackage.headerImageUrl}?_`
      );

    this.isLoading = false;
  },

  methods: {
    updatePendingFiles: function (files, imageType, sliderIndex = 0) {
      if (files) {
        if (imageType == "headerImageUrl") {
          this.overallPackage.headerImageUrl = files[0]?.file;
        }
      }
    },

    deleteSubmit(e) {
      if (e.type == "overallPackageBox") {
        this.deleteCurrentPackageBox(e.id);
      }
    },

    async updateOverallPackage() {
      let overallPackageInformations = {
        description: this.overallPackage.description,
        title: this.overallPackage.title,
        categoryId: this.overallPackage.categoryId,
        subTitle: this.overallPackage.subTitle,
        subDescription: this.overallPackage.subDescription,
      };

      let overallId = this.overallPackage.id;

      let overalPackageBoxes = this.overallPackage.overalPackageBoxes;

      if (!this.overallPackage.headerImageUrl) {
        this.$store.commit("showToast", {
          id: "myToast",
          type: "error",
          title: "Header Image Is Required!",
          body: "You must choice any image for header image!",
        });
        return;
      }

      this.isAdding = true;

      try {
        await axios.put(
          `OveralPackages/${overallId}`,
          overallPackageInformations,
          { isNeedJwtAuth: true }
        );

        await updateImage(
          this.overallPackage.headerImageUrl,
          "HeaderImage",
          `OveralPackages/${overallId}/HeaderImage`
        );

        if (overalPackageBoxes?.length > 0) {
          await this.addOverallPackageBox(
            "OveralPackages/Box",
            overalPackageBoxes,
            overallId
          );

          await this.updateOverallPackageBox(
            "OveralPackages/Box",
            overalPackageBoxes,
            overallId
          );
        }

        this.$store.commit("showToast", {
          id: "myToast",
          type: "success",
          title: "Success!",
          body: "Your desired overallPackage package has been successfully edited!",
        });

        this.isAdding = false;

        this.$router.go();
      } catch (error) {
        if (error.response?.data?.isSuccess == false) {
          this.$store.commit("showToast", {
            id: "myToast",
            type: "error",
            title: "Error!",
            body: error.response?.data?.messages[0],
          });
        } else {
          this.$store.commit("showToast", {
            id: "myToast",
            type: "error",
            title: "Error!",
            body: "Unfortunately, an unknown error has occurred! Please contact the website support!",
          });
        }

        this.isAdding = false;
        console.log(error);
        console.log(error.response);
      }
    },

    async addOverallPackageBox(url, boxesInformation, packageId) {
      const newBoxes = boxesInformation.filter(
        (current) => !current.isInitialized
      );

      if (!newBoxes || newBoxes.length === 0) return;

      for (const box of newBoxes) {
        let bodyFormData = new FormData();

        bodyFormData.append("Title", box.title);
        bodyFormData.append("OveralPackageId", packageId);
        bodyFormData.append("Description", box.description);
        bodyFormData.append("IsMore", box.isMore);

        let options = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          isNeedJwtAuth: true,
        };

        await axios.post(url, bodyFormData, options);
      }
    },

    async updateOverallPackageBox(url, boxesInformation, packageId) {
      const currentBoxes = boxesInformation.filter(
        (current) => current.isInitialized
      );
      const changedBoxes = this.findChangedItems(
        this.overallPackage.overalPackageBoxesInitial,
        currentBoxes
      );
      if (!changedBoxes || changedBoxes.length === 0) return;

      for (const box of changedBoxes) {
        let overallPackagePackageBox = {
          isMore: box.isMore,
          ordering: null,
          title: box.title,
          description: box.description,
          overalPackageId: packageId,
          attributes: null,
        };

        await axios.put(`${url}/${box.id}`, overallPackagePackageBox, {
          isNeedJwtAuth: true,
        });
      }
    },
    
    async deleteCurrentPackageBox(boxId) {
      await axios
        .delete(`OveralPackages/Box/${boxId}`, { isNeedJwtAuth: true })
        .then(async (response) => {
          this.$store.commit("showToast", {
            id: "myToast",
            type: "success",
            title: "Success!",
            body: "Your desired overallPackage package box has been successfully deleted!",
          });

          // mixins: custom-functions
          this.removeItemObjectWithId(
            this.overallPackage.overalPackageBoxes,
            boxId
          );
        })
        .catch((error) => {
          if (error.response?.data?.isSuccess == false) {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Error!",
              body: error.response?.data?.messages[0],
            });
          } else {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Error!",
              body: "Unfortunately, an unknown error has occurred! Please contact the website support!",
            });
          }

          console.log(error);
          console.log(error.response);
        });
    },

    addNewPackageBox() {
      let overallPackagePackageBoxId =
        this.overallPackage.overalPackageBoxes.length + 1;

      const newBox = {
        id: overallPackagePackageBoxId,
        title: "",
        description: "",
      };

      this.overallPackage.overalPackageBoxes.push(newBox);
    },

    morePackageChange(event, box) {
      // get alll inputs with checkbox type that contain isMore id
      this.overallPackage.overalPackageBoxes.forEach(
        (current) => (current.isMore = false)
      );

      const checkboxElements = Array.from(
        document.querySelectorAll("input[type='checkbox'][id*='isMore']")
      );

      if (event.target.checked) {
        box.isMore = true;

        checkboxElements
          .filter((current) => current.id !== event.target.id)
          .forEach((el) => {
            el.checked = false;
            el.setAttribute("disabled", "");
            el.parentNode.style.opacity = 0.5;
            el.parentNode.style.pointerEvents = "none";
          });
        return;
      }

      checkboxElements.forEach((el) => {
        el.removeAttribute("disabled");
        el.parentNode.style.opacity = 1;
        el.parentNode.style.pointerEvents = "auto";
      });
    },

    deleteNewPackageBox(boxId) {
      // mixins: custom-functions
      this.removeItemObjectWithId(
        this.overallPackage.overalPackageBoxes,
        boxId
      );
    },
  },
};
</script>
