<template>
  <div class="flex w-full flex-col gap-2">
    <h4
      class="font-bold text-black dark:text-zinc-100 xl:text-2xl text-xl"
    >
      {{ title }}
    </h4>
    <p
      class="text-justify text-black dark:text-zinc-200 dark:font-light lg:text-lg text-base"
    >
      {{ description }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
};
</script>
