<template>
  <!-- Modal with tw-elements -->
  <div
    class="fixed start-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
    :id="modalId"
    data-te-modal-init
    tabindex="-1"
    :aria-labelledby="modalId"
    aria-hidden="true"
    :data-te-backdrop="canClose === true ? 'true' : 'static'"
    :data-te-keyboard="!canClose"
  >
    <div
      data-te-modal-dialog-ref
      class="h-[100%] flex items-center min-[576px]:h-[calc(100%-3.5rem)] pointer-events-none relative w-auto min-[576px]:mx-auto min-[576px]:mt-7"
      :class="{
        'min-[576px]:max-w-[300px]': modalSize == 'sm',
        'min-[576px]:max-w-[500px] ': modalSize == 'md',
        'min-[576px]:max-w-[500px] min-[992px]:max-w-[800px]': modalSize == 'lg',
        'min-[576px]:max-w-[500px] min-[992px]:max-w-[800px] min-[1200px]:max-w-[1140px]':
          modalSize == 'xl',
      }"
    >
      <div
        class="shadow-xl pointer-events-auto relative flex max-h-[100%] w-full flex-col overflow-hidden rounded-lg border-none bg-clip-padding text-current outline-none bg-white dark:bg-zinc-800"
      >
        <!-- Modal header -->
        <div class="flex flex-shrink-0 items-center justify-end p-4">
          <!-- Modal close -->
          <button
            type="button"
            class="box-content w-7 h-7 border-none fill-zinc-950 dark:fill-white rounded-none focus:shadow-none focus:outline-none hover:no-underline"
            data-te-modal-dismiss
            aria-label="Close"
          >
            <svg
              class="w-full h-full"
              xmlns="http://www.w3.org/2000/svg"
              fill-rule="evenodd"
              clip-rule="evenodd"
            >
              <path
                d="M12 11.293l10.293-10.293.707.707-10.293 10.293 10.293 10.293-.707.707-10.293-10.293-10.293 10.293-.707-.707 10.293-10.293-10.293-10.293.707-.707 10.293 10.293z"
              />
            </svg>
          </button>
        </div>

        <!-- Modal body -->
        <div class="relative px-5 overflow-y-auto" data-te-modal-body-ref>
          <slot name="modalBody"></slot>
        </div>

        <!-- Modal footer -->
        <div
          v-if="hasFooter"
          class="flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-b-[6px]"
          :class="footerBorderColor"
        >
          <slot name="modalFooter"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal, initTE } from "tw-elements";

export default {
  props: {
    modalId: {
      type: String,
      required: true,
    },
    canClose: {
      type: Boolean,
      required: false,
      default: true,
    },
    hasFooter: {
      type: Boolean,
      required: false,
      default: false,
    },
    footerBorderColor: {
      type: String,
      required: false,
      default: "border-primary",
    },
    modalSize: {
      type: String,
      required: false,
      default: "md",
    },
  },
  mounted() {
    setTimeout(() => {
      initTE({ Modal });
    }, 1000);
  },
};
</script>

<style lang="scss" scoped>
/* *Scroll Bar */
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 4px;
}
</style>
